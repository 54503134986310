import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

// Redux imports
import { Provider } from "react-redux";
import { store, history } from "@redux/store";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Route, Routes } from "react-router-dom";
import NoInternetConnection from "@screens/NoInternetConnection/NoInternetConnection";
import DownloadingMap from "@screens/DownloadingMap/DownloadingMap";
import DownloadFailed from "@screens/DownloadFailed/DownloadFailed";
import NavigationScreen from "@screens/Navigation/Navigation";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="map" element={null} />
            <Route path="navigation" element={<NavigationScreen />} />
          </Route>
          <Route
            path="/no-internet-connection"
            element={<NoInternetConnection />}
          />
          <Route path="/downloading-map" element={<DownloadingMap />} />
          <Route path="/download-failed" element={<DownloadFailed />} />
        </Routes>
      </Router>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

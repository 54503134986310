import LivingMap from "@livingmap/core-mapping";
import { isLat, isLon } from "@livingmap/core-mapping/dist/utils";

import { Coordinate, PLUGIN_IDS } from "@components/Map/plugins/types";
import type UserLocationService from "@components/Map/plugins/location/user-location-service";
import APILocationProvider from "@components/Map/plugins/location/api-location-provider";
import LMUserLocation from "@components/Map/plugins/routing/LMUserLocation";
import {
  getFloorById,
  getFloorByName,
  getQuery,
  isLocationInExtent,
} from "@utils";
import type {
  FloorControl,
  UserLocationControl,
} from "@components/Map/plugins";
import LocationError from "./location-error";
import NavigatorLocationProvider from "@components/Map/plugins/location/navigator-location-provider";
import { setDialogNotifications } from "@redux/slices/uiSlice";
import { store } from "@redux/store";

export interface LocationOptions {
  floorId?: number;
  floorName?: string;
  accuracy?: number;
}

export enum BarometerValue {
  UNKNOWN = "Unknown",
  LEVEL = "Level",
  ASCENDING = "Ascending",
  DESCENDING = "Descending",
}

export class Location {
  private readonly floorControl: FloorControl | null = null;

  private readonly LMMap: LivingMap;
  private readonly userLocationControl: UserLocationControl | null = null;
  private readonly locationService: UserLocationService | null = null;
  protected ignoreActiveFloorUpdates = false;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
    this.userLocationControl = this.LMMap.getPluginById<UserLocationControl>(
      PLUGIN_IDS.USER_LOCATION
    );
    this.floorControl = this.LMMap.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR
    );

    this.locationService = this.userLocationControl.getUserLocationService();

    if (getQuery("disablejsgeolocation") === "true") {
      this.locationService.registerLocationProvider(new APILocationProvider());
    } else {
      this.locationService.registerLocationProvider(
        new NavigatorLocationProvider()
      );
    }
  }

  /**
   * Ignore active floor updates
   *
   * @returns void
   */
  public enableIgnoreActiveFloorUpdates() {
    this.ignoreActiveFloorUpdates = true;
  }

  /**
   * Listen for active floor updates
   *
   * @returns void
   */
  public disableIgnoreActiveFloorUpdates() {
    this.ignoreActiveFloorUpdates = false;
  }

  /**
   * Sets a user location
   *
   * @param newUserlocation array of latitude and longitude which represents the location of the user
   * @param options {@link LocationOptions}
   *
   * @returns void
   */
  public setUserLocation(
    newUserlocation: mapboxgl.LngLatLike,
    options: LocationOptions = {}
  ) {
    if (this.ignoreActiveFloorUpdates) return;
    if (!this.locationService) throw new Error("Location service not found");

    const locationProvider = this.locationService.getLocationProvider();

    if (!(locationProvider instanceof APILocationProvider)) {
      throw new Error(
        "API locations not allowed. See the API documentation to enable this feature."
      );
    }

    const anyLocation = newUserlocation as any;
    const isValidLocation = !Array.isArray(anyLocation)
      ? isLon(anyLocation.lng) && isLat(anyLocation.lat)
      : isLon(anyLocation[0]) && isLat(anyLocation[1]);

    if (!isValidLocation) {
      throw new LocationError(
        "invalid location, This coordinate isn't  Longitude, Latitude"
      );
    }

    const locationAsCoord: Coordinate = {
      x: Array.isArray(anyLocation) ? anyLocation[0] : anyLocation.lng,
      y: Array.isArray(anyLocation) ? anyLocation[1] : anyLocation.lat,
      floor: options.floorId ? getFloorById(options.floorId) : null,
    };
    const isInBounds = isLocationInExtent(locationAsCoord);

    if (!isInBounds) {
      throw new LocationError("User Position out of bounds");
    }

    let floor = options.floorId
      ? getFloorById(options.floorId)
      : options.floorName
      ? getFloorByName(options.floorName)
      : null;

    const lmUserLocationOptions = {
      accuracy: options.accuracy,
      floor,
    };

    const lmLocation = new LMUserLocation(
      newUserlocation,
      lmUserLocationOptions
    );
    if (this.locationService) {
      if (
        this.locationService.getLocationProvider() instanceof
        APILocationProvider
      ) {
        this.locationService.watch();
      }

      this.locationService.setAPIUserLocation(lmLocation);
    }

    if (this.floorControl) {
      const currentFloor = this.floorControl.getActiveFloor();

      if (options.floorId != null) {
        floor = getFloorById(options.floorId);

        if (floor && currentFloor !== floor) {
          this.floorControl.setActiveFloor(floor);
        }
      } else if (options.floorName != null) {
        floor = getFloorByName(options.floorName);

        if (floor && currentFloor !== floor) {
          this.floorControl.setActiveFloor(floor);
        }
      }
    }
  }

  /**
   * Set the heading for the user
   *
   * @param newHeading
   * @returns void
   */
  public setUserHeading(newHeading: number) {
    if (this.ignoreActiveFloorUpdates) return;
    if (!this.locationService) return;
    const locationProvider = this.locationService.getLocationProvider();
    if (locationProvider instanceof APILocationProvider) {
      locationProvider.setUserHeading(newHeading);
    }
  }

  /**
   * Toggle if the user is walking or not
   *
   * @param isWalking
   */
  public setUserIsWalking(isWalking: boolean) {
    if (this.userLocationControl) {
      this.userLocationControl.setUserIsWalking(isWalking);
    }
  }

  /**
   * Define if a floor change alert should be displayed, based on a barometer status that gets passed in
   *
   * @param status
   */
  public setBarometerValue(status: BarometerValue) {
    if (status === BarometerValue.LEVEL) {
      store.dispatch(setDialogNotifications({ floorChangingAlert: false }));
    }
    if (
      status === BarometerValue.ASCENDING ||
      status === BarometerValue.DESCENDING
    ) {
      store.dispatch(setDialogNotifications({ floorChangingAlert: true }));
    }
  }

  /**
   * Get the active floor ID for the map
   *
   * @returns floorId
   */
  public getDisplayedFloor() {
    const config = this.floorControl!.getActiveFloor();
    return config!.id;
  }

  /**
   * Set the center of the map by passing in coordinates to be centered on.
   *
   * @param newCentre
   */
  public setCenter(newCentre: mapboxgl.LngLatLike) {
    this.LMMap.setMapCentre(newCentre);
  }

  /**
   * Get the center coordinates of the map
   *
   * @returns center of the map
   */
  public getCenter() {
    return this.LMMap.getMapCentre();
  }
}

import type LivingMap from "@livingmap/core-mapping";
import type { MapMouseEvent } from "mapbox-gl";

import { Logger } from "@utils";
import { PLUGIN_IDS, SourceIds } from "../../types";
import InteractionControl from "../interaction-control";
import { UserMapAction } from "./user-map-action";

/**
 * Class representing a handler of a User interaction with the map canvas.
 * This class implements the Living Map handler of a map click.
 * @implements {UserMapAction<MouseEvent>}
 */
class UserClickAction implements UserMapAction<MouseEvent> {
  private LMMap: LivingMap;
  private interactionControl!: InteractionControl;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
    this.handle = this.handle.bind(this);
  }

  /**
   * Given a Mapbox MouseMove event this handler will deal with the response according to the
   * LivingMap business rules around mousemovents.
   * @param  {MapMouseEvent} clickEvent
   * @returns void
   */
  public handle(clickEvent: MapMouseEvent): void {
    const features = this.LMMap.queryRenderedFeatures(clickEvent.point);

    const clusterFeatures = features.filter((feature) => {
      return feature.isClusterable();
    });

    if (clusterFeatures.length > 0) {
      this.handleClusterZoom(clusterFeatures[0]);
      return;
    }

    this.interactionControl = this.LMMap.getPluginById<InteractionControl>(
      PLUGIN_IDS.INTERACTION
    );
    const lmFeatureGeoJSON = features.find((feature) =>
      feature.getHasAttributes()
    );
    if (!lmFeatureGeoJSON) {
      this.interactionControl.deselectFeatures();
      return;
    }

    this.interactionControl.selectFeature(lmFeatureGeoJSON);
  }

  private handleClusterZoom(clusterFeature: any): void {
    const mapInstance = this.LMMap.getMapboxMap()!;
    const clusterSource = mapInstance.getSource(
      SourceIds.CLUSTER_SOURCE_ID
    ) as mapboxgl.GeoJSONSource;
    clusterSource.getClusterExpansionZoom(
      clusterFeature.getMapboxFeature().properties.cluster_id,
      (err, zoom) => {
        if (err) {
          Logger.error(err);
          return;
        }
        mapInstance.easeTo({
          center: clusterFeature.getCentroid(),
          zoom,
        });
      }
    );
  }
}

export default UserClickAction;

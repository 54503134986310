import MobileConnectionError from "./mobile-connection-error";
import { Logger } from "@utils";
import { CustomEventAPICallbackFormat } from "@api/custom-api-callback-format";

export const IOS_LIVINGMAP_CUSTOM_URL_SCHEME = "livingmap://app";

declare global {
  var map: any;
  function fetch(input: RequestInfo, init?: RequestInit): any;
  interface Window {
    Android:
      | {
          fireCustomEvent: (id: string, options: string) => void;
          setRouteActive: (toggle: boolean) => void;
          setRouteHeading: (heading: number) => void;
          onMapReady: () => void;
          destinationReached: () => void;
        }
      | null
      | undefined;
    webkit: Record<string, unknown> | null | undefined;
    api: any;
    ol: any;
    progress: number;
  }
}

export default class Mobile {
  public static isAndroidWebview = () =>
    typeof window.Android !== "undefined" && window.Android !== null;
  public static getGlobalAndroid = () => window.Android;

  /**
   * Checks if the frontend is rendered inside a webview or not.
   * note: Chrome on iOS is also rendered in a webview.
   * @public
   * @returns {boolean}
   */
  public static isIOSWebview = (): boolean =>
    typeof window.webkit !== "undefined" && window.webkit !== null;

  /**
   * Method will check if the webkit mapHandler is available.
   * his maphandler is a JS bridge between our iOS SDKS and our frontend code.
   * @public
   * @returns {boolean}
   */
  public static isIOSLivingMapWebView = (): boolean => {
    const isWebView = Mobile.isIOSWebview();
    if (!isWebView) return false;

    const webkitInstance = Mobile.getGlobalWebkit()!;
    const msgHandler = (webkitInstance as any).messageHandlers;
    if (!msgHandler) return false;

    const maph = msgHandler.mapHandler;
    return Boolean(maph);
  };
  public static getGlobalWebkit = () => window.webkit;

  /**
   * On newer ios version we are using a custom url scheme in order to handle offline cache of map assets.
   * This checks if the incoming request uses the custom url scheme or not.
   * @public
   * @returns {boolean}
   */
  public static isIOSCustomURLScheme = (): boolean => {
    return window.location.href.indexOf(IOS_LIVINGMAP_CUSTOM_URL_SCHEME) > -1;
  };

  public static sharedSendMessageEmitter(
    type: string,
    payload: CustomEventAPICallbackFormat | Record<string, unknown>
  ): void {
    Logger.devLog(
      `Attempting to send message "${type}" with payload: ${JSON.stringify(
        payload
      )} to any upstream SDK.`
    );
    Mobile.sendMessageToAndroid(type, JSON.stringify(payload));
    Mobile.sendMessageToIOS({
      eventName: "fireCustomEvent",
      parameters: {
        eventId: type,
        eventParameters: [
          {
            ...payload,
          },
        ],
      },
    });
  }

  public static sendMessageToAndroid(id: string, options: string): void {
    if (Mobile.isAndroidWebview()) {
      try {
        Mobile.getGlobalAndroid()!.fireCustomEvent(id, options);
      } catch {
        throw new MobileConnectionError("Event not sent to Android webview.");
      }
    }
  }

  public static sendMessageToIOS(message: Record<string, unknown>): void {
    if (Mobile.isIOSLivingMapWebView()) {
      const webkitInstance = Mobile.getGlobalWebkit()!;
      const maph = (webkitInstance as any).messageHandlers.mapHandler;
      maph.postMessage(JSON.stringify(message));
    }
  }
}

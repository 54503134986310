import { setMapDownloadProgress } from "@redux/slices/offlineSlice";
import { store } from "@redux/store";
import { ApiAction, ApiEventTypes, ExtractActionPayload } from "../types";

type APIdata = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.SET_MAP_DOWNLOAD_PROGRESS
>;

/**
 * Updates the download progress of the map
 *
 * @param percentage
 * @returns
 */
export const handleSetMapDownloadProgress = (percentage: APIdata) => {
  store.dispatch(setMapDownloadProgress(percentage));
};

import classNames from "classnames";

import styles from "./DecisionModal.module.css";

interface Props {
  title: string;
  option1Text: string;
  option2Text: string;
  onOption1Click: () => void;
  onOption2Click: () => void;
}

const DecisionModal: React.FC<Props> = ({
  title,
  option1Text,
  option2Text,
  onOption1Click,
  onOption2Click,
}) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <button
        className={classNames(styles.button, styles.buttonPrimary)}
        onClick={onOption1Click}
      >
        <span>{option1Text}</span>
      </button>
      <span className={styles.separator}>- or -</span>
      <button
        className={classNames(styles.button, styles.buttonSecondary)}
        onClick={onOption2Click}
      >
        <span>{option2Text}</span>
      </button>
    </div>
  );
};

export default DecisionModal;

import type LMUserLocation from "../routing/LMUserLocation";

export default class APILocationProvider implements Geolocation {
  private watches: { [s: number]: PositionCallback };
  private watchIDSeq = 0;
  private heading: number | null;
  private userPosition: LMUserLocation | null = null;

  constructor() {
    this.heading = null;
    this.watches = {};
  }

  public setUserLocation(newLocation: LMUserLocation): void {
    if (this.heading !== null) {
      newLocation.setHeading(this.heading);
      this.userPosition = newLocation;
    } else {
      this.userPosition = newLocation;
    }

    Object.keys(this.watches).forEach((key: any) => {
      if (!key) return;

      const successCallback = this.watches[key];

      if (successCallback && this.userPosition)
        successCallback(this.userPosition.getAsPosition());
    });
  }

  public setUserHeading(newHeading: number): void {
    this.heading = newHeading;

    if (this.userPosition) {
      this.userPosition.setHeading(this.heading);

      Object.keys(this.watches).forEach((key: any) => {
        if (!key) return;

        const successCallback = this.watches[key];

        if (successCallback && this.userPosition)
          successCallback(this.userPosition.getAsPosition());
      });
    }
  }

  public watchPosition = (successCallback: PositionCallback) => {
    const watchID = this.getNewWatchID();
    this.watches[watchID] = successCallback;
    return watchID;
  };

  public clearWatch = (watchId: number) => {
    delete this.watches[watchId];
    return;
  };

  public getCurrentPosition = (
    successCallback: PositionCallback,
    errorCallback: PositionErrorCallback
  ) => {
    if (this.userPosition) {
      successCallback(this.userPosition.getAsPosition());
    } else {
      const error = {
        message: "No API location known",
      } as GeolocationPositionError;
      errorCallback(error);
    }
  };

  private getNewWatchID(): number {
    const newID = this.watchIDSeq;
    this.watchIDSeq += 1;
    return newID;
  }
}

import { FC, useCallback, useEffect, useRef, useState } from "react";

import { useOutletContext } from "react-router-dom";
import type LivingMap from "@livingmap/core-mapping";

import Mobile from "@mobile";
import modalManager from "@api/modals/modal-manager";
import { LOCATION_UPDATE_EVENT } from "@components/Map/events";
import { useAppSelector } from "@redux/hooks";
import { useConfigEndpointQuery } from "@redux/services/sdk";
import Countdown from "@components/Countdown/Countdown";
import TopBar from "@components/TopBar/TopBar";
import { EventTypes, PLUGIN_IDS } from "@components/Map/plugins/types";
import {
  FloorControl,
  GeofenceControl,
  RoutingControl,
  UserLocationControl,
} from "@components/Map/plugins";
import DialogNotifications from "@components/DialogNotifications/DialogNotifications";
import Notifications from "@components/Notifications/Notifications";
import { useMapHandlers } from "./utils/useMapHandlers";
import SnapManager from "@components/Map/plugins/routing/snap-manager";
import styles from "./Navigation.module.css";
import CTABanner from "@components/CTABanner/CTABanner";

export enum ComponentMode {
  OVERVIEW = "overview",
  INSPECTION = "inspection",
  NAVIGATION = "navigation",
}

const INITIAL_COUNTDOWN_TIME = 9000; // 9 Seconds

const isMobile = Mobile.isAndroidWebview() || Mobile.isIOSLivingMapWebView();

const NavigationScreen: FC = () => {
  const { data } = useConfigEndpointQuery();
  const { map } = useOutletContext<{ map: LivingMap }>();

  const { routingDestinationData, routingOverviewData } = useAppSelector(
    (state) => state.routing
  );
  const { statusBar, dialogNotifications, notifications, ctaBanner } =
    useAppSelector((state) => state.ui);

  const [countdownTimeInSeconds, setCountdownTimeInSeconds] = useState(
    INITIAL_COUNTDOWN_TIME / 1000
  );
  const [initialDistance, setInitialDistance] = useState(0);
  const [routeStatus, setRouteStatus] = useState({
    distance: 0,
    time: 0,
  });

  const countdownInterval = useRef<ReturnType<typeof setTimeout>>();

  const userLocationControl = map!.getPluginById<UserLocationControl>(
    PLUGIN_IDS.USER_LOCATION
  );
  const routingControl = map!.getPluginById<RoutingControl>(PLUGIN_IDS.ROUTING);
  const geofenceControl = map!.getPluginById<GeofenceControl>(
    PLUGIN_IDS.GEOFENCE
  );
  const floorControl = map!.getPluginById<FloorControl>(PLUGIN_IDS.FLOOR);

  const [mode, setMode] = useState(ComponentMode.OVERVIEW);

  const {
    handleDetectionUserIsActive,
    handleStartRouteClick,
    refreshOverviewData,
    handleGeofenceEntrance,
    handleGeofenceExit,
  } = useMapHandlers(
    data,
    map,
    countdownInterval,
    userLocationControl,
    routingControl,
    geofenceControl,
    floorControl,
    mode,
    setMode
  );

  const handleLocationUpdate = () => {
    modalManager.hideDeterminingPositionModal();
  };

  useEffect(() => {
    if (isMobile && userLocationControl) {
      modalManager.showDeterminingPositionModal();
      map!.once(LOCATION_UPDATE_EVENT, handleLocationUpdate);
    }

    const mapboxMap = map.getMapboxMap();
    mapboxMap.on("drag", handleDetectionUserIsActive);
    mapboxMap.on("mousedown", handleDetectionUserIsActive);
    mapboxMap.on("mouseup", handleDetectionUserIsActive);
    mapboxMap.on("touchstart", handleDetectionUserIsActive);
    mapboxMap.on("zoomstart", handleDetectionUserIsActive);

    map.on(EventTypes.GEOFENCE_ENTRANCE, handleGeofenceEntrance);
    map.on(EventTypes.GEOFENCE_EXIT, handleGeofenceExit);

    return () => {
      mapboxMap.off("drag", handleDetectionUserIsActive);
      mapboxMap.off("mousedown", handleDetectionUserIsActive);
      mapboxMap.off("mouseup", handleDetectionUserIsActive);
      mapboxMap.off("touchstart", handleDetectionUserIsActive);
      mapboxMap.off("zoomstart", handleDetectionUserIsActive);

      map.removeListener(EventTypes.GEOFENCE_ENTRANCE, handleGeofenceEntrance);
      map.removeListener(EventTypes.GEOFENCE_EXIT, handleGeofenceExit);
    };
  }, [
    map,
    userLocationControl,
    handleDetectionUserIsActive,
    handleGeofenceEntrance,
    handleGeofenceExit,
  ]);

  useEffect(() => {
    countdownInterval.current = setInterval(() => {
      setCountdownTimeInSeconds(countdownTimeInSeconds - 1);
    }, 1000);

    if (countdownTimeInSeconds === 1) {
      clearInterval(countdownInterval.current);
      handleStartRouteClick();
      return;
    }

    return () => {
      clearInterval(countdownInterval.current!);
    };
  }, [countdownTimeInSeconds, handleStartRouteClick]);

  const resolveNewTimeAndDistance = useCallback(() => {
    const progressDescriptor =
      SnapManager.getInstance().getLatestProgressDescriptor();

    if (!progressDescriptor || !initialDistance) {
      setRouteStatus({
        time: routingOverviewData?.totalTime || 0,
        distance: routingOverviewData?.totalLength || 0,
      });

      return;
    }

    const proportionUncompletedRoute = 1 - progressDescriptor.percentage / 100;

    setRouteStatus({
      time: progressDescriptor.timeLeft,
      distance: initialDistance * proportionUncompletedRoute,
    });
  }, [initialDistance, routingOverviewData]);

  useEffect(() => {
    refreshOverviewData();
    resolveNewTimeAndDistance();

    if (!initialDistance) {
      setInitialDistance(routingOverviewData?.totalLength || 0);
    }
  }, [
    routingOverviewData,
    refreshOverviewData,
    initialDistance,
    resolveNewTimeAndDistance,
  ]);

  useEffect(() => {
    SnapManager.getInstance().on(
      EventTypes.ROUTE_PROGRESS_UPDATE,
      resolveNewTimeAndDistance
    );

    return () => {
      SnapManager.getInstance().removeListener(
        EventTypes.ROUTE_PROGRESS_UPDATE,
        resolveNewTimeAndDistance
      );
    };
  }, [resolveNewTimeAndDistance]);

  const renderButtonWithCountdown = () => {
    if (isOverviewMode) {
      return (
        <Countdown
          buttonText="Starting Navigation Mode..."
          counter={countdownTimeInSeconds}
          onClick={handleStartRouteClick}
        />
      );
    } else if (isInspectionMode) {
      return (
        <Countdown
          buttonText="Start Navigation Mode"
          onClick={handleStartRouteClick}
        />
      );
    }
  };

  const handleOnCTAClick = () => {
    if (!ctaBanner.imageUrl || !ctaBanner.title || !ctaBanner.icon) return;

    modalManager.showImageModal(
      ctaBanner.imageUrl,
      ctaBanner.title,
      ctaBanner.icon
    );
  };
  const isOverviewMode = mode === ComponentMode.OVERVIEW;
  const isInspectionMode = mode === ComponentMode.INSPECTION;

  return (
    <div className={styles.container}>
      <CTABanner
        title={ctaBanner.title}
        icon={ctaBanner.icon}
        onClick={handleOnCTAClick}
        minimized={ctaBanner.isMinimized}
      />

      <Notifications notifications={notifications} />

      {routingDestinationData && (
        <TopBar
          destinationArea={routingDestinationData.destinationAreaName}
          destinationName={routingDestinationData.destinationName}
          distance={routeStatus.distance}
          time={routeStatus.time}
          statusBarStyle={statusBar.style}
          statusBarText={statusBar.text}
        />
      )}

      <div className={styles.dialogNotificationWrapper}>
        <DialogNotifications {...dialogNotifications} />
      </div>

      <div className={styles.countdownButton}>
        {renderButtonWithCountdown()}
      </div>
    </div>
  );
};

export default NavigationScreen;

import styles from "./ImageModal.module.css";

interface Props {
  url: string;
  altText: string;
}

const ImageModal: React.FC<Props> = ({ url, altText }) => {
  return <img src={url} alt={altText} className={styles.container} />;
};

export default ImageModal;

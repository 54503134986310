import type LMUserLocation from "./LMUserLocation";
import type { RouteLocation } from "../controls/routing-control";
import { RequestRouteOptions } from "../types";
import { Logger } from "@utils";
import { store } from "@redux/store";

interface RerouteConfig {
  /**
   * Configuration value that represents how many concecuative times a user lcoation can be oustide the defined route boundary
   * before it is elligible for a re-route.
   */
  outOfBoundsCount: number;

  /**
   * Configuration value that represents how big the route boundary is. distance is in metres.
   */
  boundaryDistanceInMetres: number;

  /**
   * requestRoute call used for making the actuall network request.
   */
  requestReroute(
    from: RouteLocation,
    to: RouteLocation,
    options: RequestRouteOptions
  ): Promise<Response>;

  /**
   * lifecycle callback usefull when an action needs to happen when re-routing starts
   */
  onStartReroute(from: RouteLocation, to: RouteLocation | null): void;

  /**
   * lifecycle callback for a _succesfull_ reroute request. Note that it will still need parsing and displaying.
   */
  onFinishReRoute(json?: any): void;
}

/**
 * The Rerouter class will decide when a re-route is necesary for the user whilst navigating.
 * This class will also deal with any fallout during slow network requests etc.
 *
 * @class
 */
class Rerouter {
  private config: RerouteConfig;
  private currentOutOfBoundsCount: number;
  private isrerouting: boolean;

  constructor(config: RerouteConfig) {
    this.config = config;
    this.currentOutOfBoundsCount = 0;
    this.isrerouting = false;
  }

  /**
   * Records the latest user location, and distance from route and based on that it will decide if a re-route is warrented.
   *
   * @param  {LMUserLocation} location
   * @param  {number} withDistance
   * @returns {void}
   */
  public recordLocation(location: LMUserLocation, withDistance: number): void {
    if (this.isLocationOutOfRouteBounds(withDistance)) {
      this.currentOutOfBoundsCount++;
      Logger.devLog(
        `Routing: out-of-bounds ${this.currentOutOfBoundsCount}/${this.config.outOfBoundsCount}`
      );
    } else {
      // we reset the counter to account for user going back on the route
      if (this.currentOutOfBoundsCount !== 0) {
        this.config.onFinishReRoute();
      }

      this.currentOutOfBoundsCount = 0;
    }

    if (this.shouldReroute()) {
      this.reroute(location);
    }
  }

  /**
   * Determines if a location is outside of the defined bounds.
   * The bounds are created through a buffer on the line, broadly inline with this document : {@link https://postgis.net/docs/ST_Buffer.html}
   * @private
   * @param distanceInMetres {number}
   * @returns {boolean}
   */
  private isLocationOutOfRouteBounds(distanceInMetres: number): boolean {
    return this.config.boundaryDistanceInMetres < distanceInMetres;
  }

  /**
   * Determines if a re-route would be warrented based on the passed in configuration object.
   * @private
   * @returns {boolean}
   */
  private shouldReroute(): boolean {
    return this.config.outOfBoundsCount <= this.currentOutOfBoundsCount;
  }

  /**
   * reroute() will handle the logic around where are we routing from and to,
   * as well as deal with making the request, and dealing with the response.
   *
   * Calling this function when there is an active re-routing request does not do anything.
   *
   * @private
   * @param  {LMUserLocation} fromLocation
   * @returns {void}
   */
  private reroute(fromLocation: LMUserLocation): void {
    // we want to check if we are already re-routing, otherwise it will visually look odd.
    if (this.isrerouting) return;
    const { routing } = store.getState();

    const from: RouteLocation = { coordinate: fromLocation.getAsCoordinate() };
    const to: RouteLocation | null = routing.to;

    this.isrerouting = true;
    this.config.onStartReroute(from, to);

    if (!to)
      throw new Error(
        "Something when horrible wrong. No routing destination found."
      );

    const options = routing.options;

    this.config
      .requestReroute(from, to, options!)
      .then((response) => {
        // only reset the counter on a succesfull re route. otherwise keep attempting.
        this.currentOutOfBoundsCount = 0;
        this.isrerouting = false;

        this.config.onFinishReRoute(response);
      })
      .catch(() => {
        // we'll reset the instance to try it again.
        this.isrerouting = false;
      });
  }
}

export default Rerouter;

import styles from "./FloorChangingAlert.module.css";

const FloorChangingAlert = () => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>Changing floor...</p>
    </div>
  );
};

export default FloorChangingAlert;

import CompletionProgressPoint from "./completion-progress-point";
import type { RouteCompletionConfig } from "../types";

export default class RouteCompletionManager {
  private routeCompletionList: CompletionProgressPoint[];

  constructor() {
    this.routeCompletionList = [];
  }

  public isCompletionListPopulated(): boolean {
    return this.routeCompletionList.length > 0;
  }

  public addCompletionProgressPoint(
    routeCompletionConfig: RouteCompletionConfig
  ): void {
    const routingCompletion = new CompletionProgressPoint(
      routeCompletionConfig
    );
    this.routeCompletionList.push(routingCompletion);
  }

  public handleUserProgress(userCompletion: number): void {
    this.routeCompletionList.forEach((routeCompletionProgress) => {
      routeCompletionProgress.checkUserProgression(userCompletion);
    });
  }
}

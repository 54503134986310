import LivingMap, {
  GeometryFeatureLocator,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";
import { InteractionControl } from "../../";
import { getMapGenericConfig } from "@utils";
import { EventTypes, PLUGIN_IDS } from "../../types";

export interface SelectFeatureOptions {
  silent?: boolean;
  force?: boolean;
}

/**
 * Class representing a visualisation style of Geometry Map features.
 * This class controls the logic around selecting features.
 */
class MapGeometrySelector {
  private LMMap: LivingMap;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
  }

  /**
   * Call this function when you want to select a feature.
   *
   * Logic flow:
   * 1. Its a different feature than the one currently
   *   ---> Clear the old feature and select the new one as expected
   * 2. Its the same feature as the currently highlighted one
   *   ---> Clear the feature and return.
   *
   * Note: if a feature has a linked geometries, this geometry will be passed to the {@link GeometryHighlighter}
   *
   * @param  {LMFeature} feature
   * @param  {SelectFeatureOptions} opts
   * @returns boolean
   */
  public select(feature: LMFeature, opts: SelectFeatureOptions): boolean {
    const featureStateDelegate = this.LMMap.getFeatureStateDelegate();
    const selectedFeature = featureStateDelegate.getFeatureForState(
      StateType.SELECTED
    );

    if (selectedFeature) {
      this.unselect(false);
      const isTheSameGeometry = selectedFeature.getId() === feature.getId();
      if (isTheSameGeometry && !opts.force) return false;
    }

    featureStateDelegate.setFeatureState(feature, { selected: true });

    const interactionControl = this.LMMap.getPluginById<InteractionControl>(
      PLUGIN_IDS.INTERACTION
    );
    if (feature.isSelectable()) {
      interactionControl.updateSelectedFeatureSource(feature);
    }

    if (!opts.silent) {
      this.LMMap.emit(EventTypes.FEATURE_CLICKED, { feature });
    }

    // features can have a link between different features.
    // When selecting we want to make sure we highlight ANY linked geometry.
    if (feature.isAreaHighlight()) {
      this.handleGeometryLinkHighlight(feature, true);
    }
    return true;
  }

  /**
   * deselects all features.
   * NOTE: only 1 feature can ever be selected. so this will unhighlighted that feature.
   * @param  {boolean=false} silent
   * @returns boolean
   */
  public unselect(silent = false): boolean {
    const featureStateDelegate = this.LMMap.getFeatureStateDelegate();

    const selectedFeature = featureStateDelegate.getFeatureForState(
      StateType.SELECTED
    );
    if (!selectedFeature) return false;

    featureStateDelegate.setFeatureState(selectedFeature, { selected: false });
    const interactionControl = this.LMMap.getPluginById<InteractionControl>(
      PLUGIN_IDS.INTERACTION
    );
    interactionControl.clearSelectedFeatureSource();

    // features can have a link between different features.
    // When selecting we want to make sure we highlight ANY linked geometry.
    if (selectedFeature.isAreaHighlight()) {
      this.handleGeometryLinkHighlight(selectedFeature, false);
    }

    if (!silent) this.LMMap.emit(EventTypes.FEATURE_DESELECTED);
    return true;
  }

  // NOTE: Given a user would select 2 different geometry with links, we could hypothetically be in a situation
  // where the second one gets resolved first before the first one. However, the way our UI is structured
  // makes this very difficult to achieve. Due to this we opted to leave this as a known bug.
  private async handleGeometryLinkHighlight(
    feature: LMFeature,
    highlight: boolean
  ): Promise<void> {
    const locator = new GeometryFeatureLocator(
      this.LMMap,
      getMapGenericConfig()!.type
    );

    const linkedGeometry = await locator.fromLMIdasync(feature.getLmId()!);

    if (linkedGeometry === null) return;

    const interactionControl = this.LMMap.getPluginById<InteractionControl>(
      PLUGIN_IDS.INTERACTION
    );

    linkedGeometry.forEach((feature) => {
      if (feature.getGeomTypeProperty() === "POLYGON") {
        if (highlight) interactionControl.highlightFeature(feature);
        else interactionControl.dehighlightFeatures();
      }
    });
  }
}

export default MapGeometrySelector;

import LivingMap, {
  FilterKing,
  GlobalFilters,
  LayerDelegate,
  LivingMapPlugin,
  LMFeature,
} from "@livingmap/core-mapping";
import {
  equals,
  get,
  has,
  not,
} from "@livingmap/core-mapping/dist/filter/expressions";
import {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} from "@livingmap/core-mapping/dist/utils";
import type { Feature, FeatureCollection, Point } from "geojson";
import mapboxgl from "mapbox-gl";
import tagManagerControl from "@decorators/tag-manager-control";
import Mobile from "@mobile";
import type LMUserLocation from "../routing/LMUserLocation";
import Rerouter from "../routing/rerouter";
import SnapManager from "../routing/snap-manager";
import type { FloorControl, GeofenceControl } from "../";
import type { ConfigResponse } from "@redux/services/types";
import renderRouteLabelPopup from "../routing/route-label-popup-content";
import {
  AutoRetryFetchInstance,
  defaultRetryOnStrategy,
  getUrl,
  getAttemptRerouteCount,
  getDefaultFloor,
  URLTypes,
  stringifyRouteLocation,
  setupRoutingLayers,
  formatRoutingPostData,
  reShadeHEXColor,
  Logger,
  getFloorById,
  removeViaPoint,
} from "@utils";

import {
  PLUGIN_IDS,
  EventTypes,
  RequestRouteOptions,
  RouteMode,
  RoutingOptions,
  RoutingDataAndMetaData,
  Coordinate,
} from "../types";
import { store } from "@redux/store";
import {
  clearRequestData,
  setFromRouteLocation,
  setOptions,
  setRequestData,
  setRoutingDestinationData,
  setRoutingOverviewData,
  setToRouteLocation,
} from "@redux/slices/routingSlice";
import modalManager from "@api/modals/modal-manager";
import { createRoot } from "react-dom/client";

const EMPTY_DATA_SOURCE: any = {
  type: "FeatureCollection",
  features: [],
};

export class RouteLocation {
  public static FromLMFeature(feature: LMFeature) {
    const id = feature.getId();
    return new RouteLocation({ featureId: id });
  }

  coordinate?: Coordinate;
  featureId?: number;
  pid?: number;
  type?: string;
  name?: string;

  constructor(input: {
    coordinate?: Coordinate;
    featureId?: number;
    pid?: number;
    type?: string;
    name?: string;
  }) {
    this.coordinate = input.coordinate;
    this.featureId = input.featureId;
    this.pid = input.pid;
    this.type = input.type;
    this.name = input.name;
  }
}

class RoutingPlugin extends LivingMapPlugin {
  private config: ConfigResponse;
  private routingMode: RouteMode = RouteMode.NONE;
  private geofenceControl: GeofenceControl | null = null;
  private floorControl: FloorControl | null = null;
  private stepFreeRouting = false;
  private routeLabelPopups: mapboxgl.Popup[] = [];
  private rerouteHelper: Rerouter | undefined;
  private snapManagerInstance: SnapManager;

  private layerDelegate: LayerDelegate;
  private filterInstance: FilterKing;

  public constructor(id: string, LMMap: LivingMap, config: ConfigResponse) {
    super(id, LMMap);
    this.config = config;
    this.layerDelegate = LMMap.getLayerDelegate();
    this.filterInstance = LMMap.getFilterKing();
    this.geofenceControl = LMMap.getPluginById<GeofenceControl>(
      PLUGIN_IDS.GEOFENCE
    );

    SnapManager.createInstance(config);
    this.snapManagerInstance = SnapManager.getInstance();
  }

  public activate(): void {
    const mapInstance = this.LMMap.getMapboxMap();
    setupRoutingLayers(mapInstance, this.layerDelegate);

    this.floorControl = this.LMMap.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR
    );
    this.LMMap.on(EventTypes.FLOOR_CHANGED, this.handleFloorChange);
    this.LMMap.on(
      EventTypes.LOCATION_UPDATE_EVENT,
      this.handleUserLocationUpdate
    );

    if (this.config.routing.visualisation_type === "MULTI_FLOOR_COLOR_DIFF") {
      this.filterInstance.updateLocalFilter(
        RoutingOptions.ROUTING_OTHER_FLOOR_LAYER_ID,
        {
          globalExclusions: [GlobalFilters.FLOOR],
        }
      );

      this.filterInstance.updateLocalFilter(
        RoutingOptions.ROUTING_CURRENT_FLOOR_LAYER_ID,
        {
          globalExclusions: [GlobalFilters.FLOOR],
        }
      );
    }
  }

  public deactivate(): void {
    this.LMMap.removeListener(
      EventTypes.LOCATION_UPDATE_EVENT,
      this.handleUserLocationUpdate
    );
    this.LMMap.removeListener(EventTypes.FLOOR_CHANGED, this.handleFloorChange);
  }

  public refresh(): void {
    const mapInstance = this.LMMap.getMapboxMap();
    setupRoutingLayers(mapInstance, this.layerDelegate);
  }

  public setVisualisationIgnoreFloors(should: boolean): void {
    if (should && this.floorControl && this.floorControl.getActiveFloor()) {
      const currentFloorId = this.floorControl.getActiveFloor()!.id;

      this.filterInstance.updateLocalFilter(RoutingOptions.LAYER_END_ID, {
        filter: [has("floor_id")] as any,
      });
      this.filterInstance.updateLocalFilter(RoutingOptions.LAYER_START_ID, {
        filter: [has("floor_id")] as any,
      });

      this.filterInstance.updateLocalFilter(
        RoutingOptions.ROUTING_OTHER_FLOOR_LAYER_ID,
        {
          filter: [not(equals(get("floor_id"), currentFloorId))] as any,
          globalExclusions: [GlobalFilters.FLOOR],
        }
      );
    } else {
      this.filterInstance.removeLocalFilterForFilterId(
        RoutingOptions.LAYER_END_ID
      );
      this.filterInstance.removeLocalFilterForFilterId(
        RoutingOptions.LAYER_START_ID
      );
      this.filterInstance.removeLocalFilterForFilterId(
        RoutingOptions.ROUTING_OTHER_FLOOR_LAYER_ID
      );
    }
  }

  public setStepFreeRouting(isStepFree: boolean) {
    this.stepFreeRouting = isStepFree;
  }

  public setUserOnRouteFlag(onRoute: boolean): void {
    if (Mobile.isAndroidWebview()) {
      Mobile.getGlobalAndroid()!.setRouteActive(onRoute);
    } else if (Mobile.isIOSLivingMapWebView()) {
      Mobile.sendMessageToIOS({
        eventName: "setRouteActive",
        parameters: {
          routeActive: onRoute,
        },
      });
    }
  }

  // This funciton is to clean the names into a url safe format
  private cleanNames(value: string): string {
    return value.replace(" ", "-").toLowerCase();
  }

  private getFilteredRoutesFullUrl(
    origin: string,
    destination: string
  ): string {
    const baseUrl = getUrl(URLTypes.FILTERED);
    return `${baseUrl}/${this.cleanNames(origin)}/${this.cleanNames(
      destination
    )}.json`;
  }

  /**
   * request a new route for on the map.
   * @param from
   * @param to
   * @param options
   */
  public requestRoute(
    from: RouteLocation,
    to: RouteLocation,
    options: RequestRouteOptions
  ): void {
    this.requestRouteInternal(from, to, options)
      .then((json) => {
        this.handleRoutingResponse(json, options);
        if (options.routeLabels) {
          this.showRouteLabelPopups();
        }
      })
      .catch((err) => this.handleRoutingError(err, options));
  }

  /**
   * request a new route for the map using location names
   * @param from
   * @param to
   */
  public requestFilteredRoute(
    from: string,
    to: string,
    options: RequestRouteOptions
  ): void {
    this.styleRouteForDifferentFloors();
    const url = this.getFilteredRoutesFullUrl(from, to);
    fetch(url)
      .then((resp: any) => resp.json())
      .then((json: any) => {
        this.handleRoutingResponse(json, options);
        this.showRouteLabelPopups();
      })
      .catch((err: Error) => this.handleRoutingError(err, options));
  }

  /**
   * internal route handler
   * @private
   * @param from
   * @param to
   * @param options
   * @returns {Promise<Response>}
   */
  private requestRouteInternal(
    from: RouteLocation,
    to: RouteLocation,
    options: RequestRouteOptions
  ): Promise<Response> {
    this.rerouteHelper = new Rerouter({
      outOfBoundsCount: getAttemptRerouteCount(),
      boundaryDistanceInMetres: this.config.routing.snap_boundary,
      onStartReroute: this.handleRerouteStart.bind(this),
      onFinishReRoute: this.handleRerouteFinish.bind(this),
      requestReroute: this.requestRouteInternal.bind(this),
    });

    this.setRoutingMode(RouteMode.ROUTE);

    store.dispatch(
      setRoutingDestinationData({
        destinationName: options.destinationName ? options.destinationName : "",
        destinationExpiryTime: options.destinationExpiryTime
          ? options.destinationExpiryTime
          : "",
        destinationAreaName: options.destinationAreaName
          ? options.destinationAreaName
          : "",
      })
    );

    if (!to) {
      throw new Error("New route location is not defined");
    }

    store.dispatch(setFromRouteLocation(from));
    store.dispatch(setToRouteLocation(to));

    return AutoRetryFetchInstance.fetch(
      getUrl(URLTypes.LIVE),
      "routing_request",
      {
        method: "post",
        body: this.prepareRoutingRequest(from, to, options),
        headers: { "Content-Type": "application/json" },
        retryOn: defaultRetryOnStrategy,
      }
    ).then((resp: any) => resp.json());
  }

  /**
   * parses & massages the routing response from our routing service.
   * @param  {any} json
   * @param  {RequestRouteOptions} options
   */
  private handleRoutingResponse(json: any, options: RequestRouteOptions) {
    const shouldPanToRoute =
      typeof options.shouldMapPanToRoute === "boolean"
        ? options.shouldMapPanToRoute
        : true;
    if (json.error && options.callback) return options.callback(json);
    else if (options.multimodal && options.callback)
      options.callback({ data: json, error: null });
    else {
      const {
        route,
        overview,
        dynamicGeofences,
        fromCoordinates,
        toCoordinates,
        routeLabels,
      } = this.newRoutingFormatFragmenter(json);

      store.dispatch(setRequestData(route));

      if (overview) {
        store.dispatch(setRoutingOverviewData({ ...overview }));
      }

      if (dynamicGeofences && this.geofenceControl) {
        this.geofenceControl.addDynamicGeofenceAreas(dynamicGeofences, true);
      }

      if (routeLabels) this.createRouteLabelPopups(routeLabels);
      if (fromCoordinates != null && toCoordinates != null) {
        const bounds = this.parseAndDisplayRoute(route, fromCoordinates);
        if (shouldPanToRoute)
          this.handleMapDisplay({ from: fromCoordinates }, bounds);
      }
      if (options.callback)
        options.callback({
          data: json,
          error: null,
        });
    }
  }

  private newRoutingFormatFragmenter(
    routingBackendResponse: any
  ): RoutingDataAndMetaData {
    const route = routingBackendResponse.segments[0];
    if (!route) {
      // The route is not as expected so failing graciously
      return {
        route: routingBackendResponse,
        overview: routingBackendResponse.routeMetadata[0],
        dynamicGeofences: undefined,
        fromCoordinates: null,
        toCoordinates: null,
        routeLabels: null,
      };
    }

    const origin = route.routeGeoJson[0];
    const originCoordinates: Coordinate = {
      x: parseFloat(origin.geometry.coordinates[0][0]),
      y: parseFloat(origin.geometry.coordinates[0][1]),
      floor: origin.properties.floorId,
    };
    const destination = route.routeGeoJson[route.routeGeoJson.length - 1];
    const destinationCoordinates: Coordinate = {
      x: parseFloat(destination.geometry.coordinates[1][0]),
      y: parseFloat(destination.geometry.coordinates[1][1]),
      floor: destination.properties.floorId,
    };
    const overviewData = routingBackendResponse.routeMetadata[0];
    const routeCollection = {
      type: "FeatureCollection",
      features: route.routeGeoJson,
    };

    // successful route generation response
    return {
      route: routeCollection,
      overview: overviewData,
      dynamicGeofences: route.dynamicGeofences,
      fromCoordinates: originCoordinates,
      toCoordinates: destinationCoordinates,
      routeLabels: route.routeLabels,
    };
  }

  private handleRoutingError(err: any, options: any) {
    Promise.reject(err);
    Logger.error(err);
    // RoutingCacheManager.setRequestData(null);
    if (err && options.callback)
      options.callback({
        data: null,
        error: `Invalid routing request, ${err}`,
      });
  }

  public clear(): void {
    const start = this.layerDelegate.getSourceProxy(
      `${RoutingOptions.LAYER_START_ID}-source`
    )!;
    const middle = this.layerDelegate.getSourceProxy(
      `${RoutingOptions.LAYER_MIDDLE_ID}-source`
    )!;
    const end = this.layerDelegate.getSourceProxy(
      `${RoutingOptions.LAYER_END_ID}-source`
    )!;

    if (start) start.setData(EMPTY_DATA_SOURCE);
    if (middle) middle.setData(EMPTY_DATA_SOURCE);
    if (end) end.setData(EMPTY_DATA_SOURCE);
    this.resetRouteLabelPopups();

    this.setUserOnRouteFlag(false);

    this.setRoutingMode(RouteMode.NONE);
    store.dispatch(clearRequestData());
  }

  private checkRouteLocationFloor(routeLocation: RouteLocation): RouteLocation {
    if (!routeLocation.coordinate) return routeLocation;

    const routeLocationCopy = {
      ...routeLocation,
      coordinate: {
        ...routeLocation.coordinate,
        floor: routeLocation.coordinate.floor
          ? routeLocation.coordinate.floor
          : this.floorControl
          ? this.floorControl.getActiveFloor()
          : getDefaultFloor(),
      },
    };

    return routeLocationCopy;
  }

  private prepareRoutingRequest(
    from: RouteLocation,
    to: RouteLocation,
    options: RequestRouteOptions | null
  ): string {
    let requestOptions: any = {};

    if (options !== null) {
      requestOptions = { ...options };
      delete requestOptions.destinationName;
      delete requestOptions.destinationAreaName;
      delete requestOptions.destinationExpiryTime;

      if (this.config.routing.speed) {
        requestOptions.speed = this.config.routing.speed;
      }
      // Backend requires this to be a string
      if (this.config.routing.multimodal) {
        requestOptions.multimodal = "true";
      } else {
        delete requestOptions.multimodal;
      }
      if (this.stepFreeRouting) {
        requestOptions.routeModifier = "step_free";
      }
    }

    if (options) {
      delete options.callback;
      store.dispatch(setOptions(options));
    }
    return formatRoutingPostData(
      this.checkRouteLocationFloor(to),
      this.checkRouteLocationFloor(from),
      requestOptions
    );
  }

  /**
   * @private
   * Handles the fact that a re-route is about to occur.
   */
  private handleRerouteStart(from: RouteLocation, to: RouteLocation | null) {
    this.setRoutingMode(RouteMode.ROUTE);

    if (!to) {
      throw new Error("New re-routing location is not defined");
    }

    const newLocation = stringifyRouteLocation(to);

    if (Mobile.isAndroidWebview() || Mobile.isIOSLivingMapWebView()) {
      tagManagerControl.sendEventToDataLayer(
        "Waypoints",
        "Rerouted to Destination",
        newLocation
      );

      modalManager.showDeterminingPositionModal();
    }
  }

  /**
   * Handles when a re-route has finished. This means the request is succesfull, and the data has come back.
   * @param jsonresponse when response is ommited it means the user has gotten back on track without help.
   */
  private handleRerouteFinish(jsonresponse?: any) {
    const state = store.getState();
    const { routing } = state;

    // only handle routing response when we actually have one.
    if (jsonresponse) {
      const options = routing.options || {};

      // re-routes shall not trigger a new map pan behaviou
      this.handleRoutingResponse(jsonresponse, {
        ...options,
        shouldMapPanToRoute: false,
      });
    }

    if (Mobile.isAndroidWebview() || Mobile.isIOSLivingMapWebView()) {
      modalManager.hideDeterminingPositionModal();
    }
  }

  /**
   * Handles a new user location recieved. A user location has an impact on the existing route in the sense that a
   * re-route might be warrented if the user strays too far off the course.
   *
   * NOTE: this will only be enabled if the config option `rerouteEnabled` exists.
   *
   * @private
   * @param newLocation latest user location pusblished
   * @returns {void}
   */
  private handleUserLocationUpdate = (newLocation: LMUserLocation): void => {
    if (!this.config.routing.reroute_enabled) return;
    if (this.routingMode === RouteMode.NONE) return;

    const distanceToRoute =
      this.snapManagerInstance.getLatestDistanceToRoute() || 0;
    if (this.rerouteHelper) {
      this.rerouteHelper.recordLocation(newLocation, distanceToRoute);
    }
  };

  private handleFloorChange = (): void => {
    this.styleRouteForDifferentFloors();
  };

  private setRoutingMode(newMode: RouteMode): void {
    this.routingMode = newMode;
  }

  /**
   * @param newMode whether the app is currently either requesting a route OR is displaying one already
   */
  public getRoutingMode(): RouteMode {
    return this.routingMode;
  }

  public styleRouteForDifferentFloors(): void {
    // sort by floor id so that mapbox doesn't complain about applying the paint stops
    const floors = [...this.config.floors].sort((a, b) => a.id - b.id);
    const currentFloor =
      this.floorControl && this.floorControl.getActiveFloor()
        ? this.floorControl.getActiveFloor()!.id
        : 0;

    const styleValue: { property: string; stops: any[] } = {
      property: "floor_id",
      stops: [],
    };
    floors.forEach((floor) => {
      if (!floor) return;
      if (floor.id === currentFloor) {
        styleValue.stops.push([floor!.id, RoutingOptions.BASE_ROUTE_COLOUR]);
      } else {
        styleValue.stops.push([
          floor!.id,
          reShadeHEXColor(-0.4, RoutingOptions.BASE_ROUTE_COLOUR),
        ]);
      }
    });

    this.layerDelegate.setPaintProperty(
      RoutingOptions.ROUTING_OTHER_FLOOR_LAYER_ID,
      "line-color",
      styleValue
    );
    this.layerDelegate.setPaintProperty(
      RoutingOptions.ROUTING_CURRENT_FLOOR_LAYER_ID,
      "line-color",
      styleValue
    );
  }

  public parseAndDisplayRoute(
    geoJsonData: any,
    from: Coordinate
  ): mapboxgl.LngLatBoundsLike {
    // start source
    const startSource = this.layerDelegate.getSourceProxy(
      `${RoutingOptions.LAYER_START_ID}-source`
    )!;
    const startPointGeometry = createGeoJSONGeometryPoint([from.x, from.y]);
    let startFeature;
    if (from.floor !== null) {
      startFeature = createGeoJSONFeature(
        { type: "routing-start", floor_id: from.floor },
        startPointGeometry
      );
    } else {
      startFeature = createGeoJSONFeature(
        { type: "routing-start" },
        startPointGeometry
      );
    }
    const startFeatureCollection = createGeoJSONFeatureCollection([
      startFeature,
    ]);
    startSource.setData(startFeatureCollection);

    // middle source
    const middleSource = this.layerDelegate.getSourceProxy(
      `${RoutingOptions.LAYER_MIDDLE_ID}-source`
    )!;

    // set bounds
    const bounds = new mapboxgl.LngLatBounds();
    const startCoordinates = (startFeature.geometry as Point).coordinates;
    const startLgnLat = new mapboxgl.LngLat(
      startCoordinates[0],
      startCoordinates[1]
    );
    bounds.extend(startLgnLat);
    const mappedGeoJsonFeatures = geoJsonData.features.map((feature: any) => {
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: feature.geometry.coordinates.map((lngLatPair: any) => {
            bounds.extend(lngLatPair);
            return lngLatPair;
          }),
        },
        properties: {
          ...feature.properties,
          floor_id: feature.properties.floorId,
        },
      };
    });

    middleSource.setData({ ...geoJsonData, features: mappedGeoJsonFeatures });

    const endLngLat = new mapboxgl.LngLat(
      startCoordinates[0],
      startCoordinates[1]
    );
    bounds.extend(endLngLat);

    const exportBounds: mapboxgl.LngLatBoundsLike = [
      bounds.getSouthWest(),
      bounds.getNorthEast(),
    ];
    return exportBounds;
  }

  public handleMapDisplay(
    input: { from: Coordinate },
    bounds: mapboxgl.LngLatBoundsLike
  ): void {
    const floorControl: FloorControl = this.LMMap.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR
    );

    // will set the floor to START point of the route.
    if (floorControl) {
      const activeFloorId = floorControl.getActiveFloor()!.id;
      const fromFloor = input.from.floor;
      if (fromFloor && fromFloor.id !== activeFloorId) {
        floorControl.setActiveFloor(getFloorById(fromFloor as any));
      }
    }

    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.resize();
    const bearing = mapInstance.getBearing();

    const padding = { top: 20, right: 20, bottom: 20, left: 20 };

    mapInstance.fitBounds(bounds, {
      bearing,
      padding,
    });

    this.LMMap.emit(EventTypes.ROUTE_DISPLAYED);
  }

  private createRouteLabelPopups(labelObjArr: FeatureCollection): void {
    this.resetRouteLabelPopups();
    if (!labelObjArr) return;

    const popups = labelObjArr.features.map(
      (labelObj) => this.createRouteLabelPopup(labelObj)!
    );
    this.routeLabelPopups = popups;
  }

  private createRouteLabelPopup(labelObj: Feature): mapboxgl.Popup | undefined {
    if (labelObj.geometry.type !== "Point") {
      return;
    }
    const lngLat = {
      lng: labelObj.geometry.coordinates[0],
      lat: labelObj.geometry.coordinates[1],
    };

    const routeLabelOnClick = () => {
      const floor = getFloorById(labelObj.properties!.floorId);
      if (this.floorControl && floor) this.floorControl.setActiveFloor(floor);
      if (lngLat)
        this.LMMap.getMapboxMap()!.easeTo({ center: lngLat, zoom: 18 });
    };

    const routeLabelPopup = renderRouteLabelPopup(
      labelObj.properties!.label,
      routeLabelOnClick
    );

    const placeholder = document.createElement("div");
    placeholder.className = "route-label-container";
    const element = createRoot(placeholder);
    element.render(routeLabelPopup);

    return new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      anchor: "bottom",
    })
      .setLngLat(lngLat)
      .setDOMContent(placeholder);
  }

  public showRouteLabelPopups(): void {
    this.routeLabelPopups.forEach((popup) => {
      if (!popup.isOpen()) popup.addTo(this.LMMap.getMapboxMap()!);
    });
  }

  public hideRouteLabelPopups(): void {
    this.routeLabelPopups.forEach((popup) => {
      if (popup.isOpen()) popup.remove();
    });
  }

  private resetRouteLabelPopups(): void {
    this.hideRouteLabelPopups();
    this.routeLabelPopups = [];
  }

  public removeViaPoint(viaPoint: RouteLocation): void {
    removeViaPoint(viaPoint);
    return;
  }
}

export default RoutingPlugin;

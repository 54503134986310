import LMUserLocation from "../routing/LMUserLocation";

const ANIMATION_LOCATION_TIME_IN_MS = 300;
const ANIMATION_HEADING_TIME_IN_MS = 250;

const MAX_HEADING_RADIUS = 360;

export default class UserAnimationManager {
  private callback: (location: LMUserLocation) => void;

  private headingDifference: number | null = 0;
  private locationDifference: number[] = [0, 0];

  private startTimeLocationAnimation: number | null = null;
  private startTimeHeadingAnimation: number | null = null;

  private newPosition: LMUserLocation | null;
  private oldPosition: LMUserLocation | null;

  private newHeading: number | null;
  private oldHeading: number | null;

  private animationUserLocation: LMUserLocation | null;

  private isAnimation = false;

  constructor(callback: (location: LMUserLocation) => void) {
    this.animationUserLocation = null;

    this.oldPosition = null;
    this.newPosition = null;

    this.oldHeading = null;
    this.newHeading = null;

    this.callback = callback;
  }

  public start(newPos: LMUserLocation, oldPos?: LMUserLocation): void {
    if (!oldPos && !this.animationUserLocation) return;

    this.oldPosition = this.animationUserLocation || oldPos!;
    this.newPosition = newPos;

    this.oldHeading = this.oldPosition.getHeading() || 0;
    this.newHeading = this.newPosition.getHeading();

    if (this.oldHeading === null || this.newHeading === null) {
      this.headingDifference = null;
    } else {
      const distanceForClockwise =
        Math.abs(MAX_HEADING_RADIUS - this.oldHeading + this.newHeading!) %
        MAX_HEADING_RADIUS;

      const distanceForCounterClockwise =
        Math.abs(this.oldHeading + (MAX_HEADING_RADIUS - this.newHeading!)) %
        MAX_HEADING_RADIUS;

      if (distanceForClockwise <= distanceForCounterClockwise) {
        this.headingDifference = distanceForClockwise;
      } else {
        this.headingDifference = -distanceForCounterClockwise;
      }
    }

    const oldLocation = this.oldPosition.getAsLngLatLike() as number[];
    const newLocation = this.newPosition.getAsLngLatLike() as number[];

    const now = performance.now();

    this.locationDifference = [
      oldLocation[0] - newLocation[0],
      oldLocation[1] - newLocation[1],
    ];

    this.startTimeHeadingAnimation = now;
    this.startTimeLocationAnimation = now;

    if (!this.isAnimation) {
      this.isAnimation = true;
      this.animate(now);
    }
  }

  private animate = (time: number): void => {
    if (!this.oldPosition || !this.newPosition) return;
    if (!this.startTimeHeadingAnimation || !this.startTimeLocationAnimation)
      return;

    const newHeading = this.getHeadingDataForCurrentTime(time);
    const newLocation = this.getLocationDataForCurrentTime(time);
    const newAccuracy = this.newPosition.getAccuracy();

    const locationOptions = {
      accuracy: newAccuracy || 0,
      floor: this.newPosition.getFloor(),
      heading:
        newHeading !== null
          ? newHeading
          : (this.animationUserLocation &&
              this.animationUserLocation.getHeading()) ||
            0,
    };

    if (locationOptions.heading < 0) {
      locationOptions.heading = locationOptions.heading + MAX_HEADING_RADIUS;
    } else if (locationOptions.heading > MAX_HEADING_RADIUS) {
      locationOptions.heading = locationOptions.heading % MAX_HEADING_RADIUS;
    }

    const location =
      newLocation ||
      this.newPosition.getAsLngLatLike() ||
      this.oldPosition.getAsLngLatLike();

    const animateLMUserLocation = new LMUserLocation(location, locationOptions);
    this.animationUserLocation = animateLMUserLocation;
    this.callback(animateLMUserLocation);

    const finishHeadingTime =
      this.startTimeHeadingAnimation + ANIMATION_HEADING_TIME_IN_MS;
    const finishLocationTime =
      this.startTimeLocationAnimation + ANIMATION_LOCATION_TIME_IN_MS;

    if (time < finishHeadingTime || time < finishLocationTime) {
      window.requestAnimationFrame(this.animate);
    } else {
      this.isAnimation = false;
    }
  };

  private getHeadingDataForCurrentTime(currentTime: number): number | null {
    if (!this.startTimeHeadingAnimation) return null;

    const timeUsedFromTotalTime = currentTime - this.startTimeHeadingAnimation;
    const percentageComplete =
      timeUsedFromTotalTime / ANIMATION_HEADING_TIME_IN_MS > 1
        ? 1
        : timeUsedFromTotalTime / ANIMATION_HEADING_TIME_IN_MS;

    if (!this.headingDifference) return null;
    const movementForNextRender = this.headingDifference * percentageComplete;

    if (this.oldHeading === null) return null;
    return this.oldHeading + movementForNextRender;
  }

  private getLocationDataForCurrentTime(
    currentTime: number
  ): mapboxgl.LngLatLike | null {
    if (!this.startTimeLocationAnimation) return null;

    const timeUsedFromTotalTime = currentTime - this.startTimeLocationAnimation;
    const percentageComplete =
      timeUsedFromTotalTime / ANIMATION_LOCATION_TIME_IN_MS > 1
        ? 1
        : timeUsedFromTotalTime / ANIMATION_LOCATION_TIME_IN_MS;

    if (!this.locationDifference) return null;
    const movementForNextRender = this.locationDifference.map(
      (difference) => difference * percentageComplete
    );

    if (this.oldPosition === null) return null;
    const oldLocation = this.oldPosition.getAsLngLatLike() as number[];

    return [
      oldLocation[0] - movementForNextRender[0],
      oldLocation[1] - movementForNextRender[1],
    ];
  }
}

const formatDate = (date: Date) => {
  return date.toISOString().slice(11, 19);
};

const loggerFunc =
  (type: string) =>
  (message?: any, ...optionalParams: any[]) => {
    const timeStamp = formatDate(new Date());
    const anyConsole = console as any;
    anyConsole[type](`[${timeStamp}] `, message, ...optionalParams);
  };

const Logger = {
  info: loggerFunc("info"),
  debug: loggerFunc("debug"),
  warn: loggerFunc("warn"),
  log: loggerFunc("log"),
  error: loggerFunc("error"),
  devLog(...args: any[]) {
    if (
      process.env.REACT_APP_ENVIRONMENT !== "prod" &&
      process.env.REACT_APP_ENVIRONMENT !== "proof"
    ) {
      loggerFunc("log")(...args);
    }
  },
};

export default Logger;

import type LivingMap from "@livingmap/core-mapping";
import type { MapMouseEvent } from "mapbox-gl";

import { throttled } from "@utils";
import GeometryHover from "../geometry-visualisation/geometry-hover";
import { UserMapAction } from "./user-map-action";

/**
 * Class representing a handler of a User interaction with the map canvas.
 * This class implements the Living Map handler of a map mouse move.
 * @implements {UserMapAction<MouseEvent>}
 */
class UserMouseMoveAction implements UserMapAction<MouseEvent> {
  private LMMap: LivingMap;
  private geometryHoverer: GeometryHover;
  public throttledHandle: (...args: any[]) => any;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
    this.handle = this.handle.bind(this);
    this.geometryHoverer = new GeometryHover(this.LMMap);
    this.throttledHandle = throttled(
      this.handleLatestEventAfterDelay.bind(this),
      200
    );
  }
  /**
   * Given a Mapbox MouseMove event this handler will deal with the response according to the
   * LivingMap business rules around mouse movements.
   * @param  {MapMouseEvent} mouseMoveEvent
   * @returns void
   */
  public handle(mouseMoveEvent: MapMouseEvent): void {
    this.throttledHandle(mouseMoveEvent);
  }

  private handleLatestEventAfterDelay(mouseMoveEvent: MapMouseEvent): void {
    const features = this.LMMap.queryRenderedFeatures(mouseMoveEvent.point);

    const clusterFeatures = features.filter((feature) => {
      return feature.isClusterable();
    });

    if (clusterFeatures.length > 0) {
      this.setMouseToPointerStyle();
      return;
    }

    const lmFeatureGeoJSON = features.find((feature) =>
      feature.getHasAttributes()
    );
    if (lmFeatureGeoJSON) {
      const isHover = this.geometryHoverer.hover(lmFeatureGeoJSON);
      if (isHover) this.setMouseToPointerStyle();
      return;
    }

    // no relevant geometries found. Resetting back to default.
    this.geometryHoverer.unhover();
    this.setMouseToDefaultStyle();
  }

  private setMouseToPointerStyle(): void {
    const mapInstance = this.LMMap.getMapboxMap()!;
    mapInstance.getCanvas().style.cursor = "pointer";
  }

  private setMouseToDefaultStyle(): void {
    const mapInstance = this.LMMap.getMapboxMap()!;
    mapInstance.getCanvas().style.cursor = "";
  }
}

export default UserMouseMoveAction;

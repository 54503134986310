import type LivingMap from "@livingmap/core-mapping";
import { Feature, inside, point, Polygon } from "@turf/turf";

import tagManagerControl from "@decorators/tag-manager-control";
import type LMUserLocation from "../routing/LMUserLocation";
import { EventTypes } from "../types";
import { Logger } from "@utils";

export interface GeofenceAreaOptions {
  activationCount: number;
  name: string;
  id?: number;
  callback: (direction: boolean, LMMap: LivingMap) => void;
  eventType: string;
  interaction: string;
}

export default class GeofenceArea {
  private LMMap: LivingMap;
  private geoJSONFeature: any;
  private currentCount = 1;
  private activationCount: number;
  private areaTriggered: boolean;
  private config: GeofenceAreaOptions;
  private callback: (direction: boolean, LMMap: LivingMap) => void;

  constructor(
    geoJSONFeature: any,
    LMMap: LivingMap,
    options: GeofenceAreaOptions
  ) {
    this.config = options;
    this.callback = options.callback;
    this.geoJSONFeature = geoJSONFeature;
    this.activationCount = options.activationCount;
    this.areaTriggered = false;
    this.LMMap = LMMap;
  }

  public activateGeofenceIfUserIsInArea(userLocation: LMUserLocation): void {
    if (this.isUserInArea(userLocation)) {
      this.checkGeofenceActivation();
    } else {
      this.checkGeofenceDeactivation();
    }
  }

  private checkGeofenceActivation(): void {
    if (this.currentCount === this.activationCount && !this.areaTriggered) {
      this.triggerGeofencefunction(true);
      Logger.log(
        `Geofence inside CALLBACK, ${this.config.name} with ID: ${this.config.id}`
      );
      return;
    } else if (this.currentCount < this.activationCount) {
      this.currentCount++;
    }
  }

  private checkGeofenceDeactivation(): void {
    if (this.areaTriggered) {
      if (this.currentCount === 1) {
        this.triggerGeofencefunction(false);
        Logger.log(
          `Geofence outside CALLBACK, ${this.config.name} with ID: ${this.config.id}`
        );
      } else {
        this.currentCount--;
      }
    } else {
      this.currentCount = 1;
    }
  }

  private triggerGeofencefunction(direction: boolean): void {
    const eventAction = direction ? "Geofence Entered" : "Geofence Exited";
    tagManagerControl.sendEventToDataLayer(
      "Waypoint",
      eventAction,
      this.config.name
    );

    this.callback(direction, this.LMMap);
    this.areaTriggered = direction;
    if (direction) {
      this.LMMap.emit(EventTypes.GEOFENCE_ENTRANCE, { config: this.config });
    } else {
      this.LMMap.emit(EventTypes.GEOFENCE_EXIT, { config: this.config });
    }
  }

  private isUserInArea(userLocation: LMUserLocation): boolean {
    if (
      userLocation.getFloorId() &&
      userLocation.getFloorId() !== this.geoJSONFeature.properties.floor_id
    ) {
      return false;
    }
    return this.isLocationInside(
      userLocation.getAsLngLatLike(),
      this.geoJSONFeature.geometry
    );
  }

  private isLocationInside(
    userLocationPoint: mapboxgl.LngLatLike,
    feature: Feature<Polygon>
  ): boolean {
    const locationPoint = point(userLocationPoint as number[]);
    const isInsideBounds = inside(locationPoint, feature);
    return isInsideBounds;
  }
}

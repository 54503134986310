import { FC, ReactNode } from "react";
import styles from "./Button.module.css";

interface Props {
  onClick: () => void;
  children?: ReactNode;
}

const Button: FC<Props> = ({ onClick, children }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;

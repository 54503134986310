import { useState } from "react";

import { getUrl, URLTypes } from "@utils";

interface Props {
  className?: string;
}

const ProjectLogo: React.FC<Props> = ({ className }) => {
  const [displayLogo, setDisplayLogo] = useState(true);

  return displayLogo ? (
    <img
      src={getUrl(URLTypes.PROJECT_LOGO)}
      alt="Project logo"
      className={className}
      onError={() => setDisplayLogo(false)}
      width={280}
    />
  ) : null;
};

export default ProjectLogo;

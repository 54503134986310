/**
 * Throttle a function to only run every {wait} milliseconds. Even if called plently.
 * For the difference between debounce and throttled see:
 * https://codeburst.io/throttling-and-debouncing-in-javascript-646d076d0a44
 * @param {any} func
 * @param {number} wait
 */
export const throttled = (func: (...args: any[]) => any, wait: number) => {
  let lastCall = 0;

  return (...args: any[]) => {
    const now = new Date().getTime();

    if (now - lastCall < wait) return;

    lastCall = now;

    return func(...args);
  };
};

declare global {
  interface Window {
    AbortController: AbortController;
  }
}

class AbortableFetch {
  private readonly abortControllerMap: Map<string, AbortController>;

  constructor() {
    this.abortControllerMap = new Map<string, AbortController>();
  }

  /**
   * calling fetch will prepare make a fetch request that you can subsequently abort,
   * if the reuqest is not needed anymore.
   *
   * @remarks
   * the underlying technology is not yet supported across all browsers.
   * When this is the case using this fetch method will not differ from calling the native fetch method.
   * @param  {string} url a URL
   * @param  {string} id
   * @param  {RequestInit} opts?
   * @returns Promise
   */
  public fetch(
    url: string,
    id: string,
    opts: RequestInit = {}
  ): Promise<Response> {
    if (!window.AbortController) return fetch(url, opts);

    const abortController = new AbortController();
    this.abortControllerMap.set(id, abortController);
    return fetch(url, { ...opts, signal: abortController.signal });
  }

  /**
   * will attempt to abort the request if it is still outstanding.
   *
   * @remarks
   * please note: the underlying technology is not yet supported across all browsers.
   * When this is the case using the .cancel() method will do nothing.
   * @param  {string} id
   * @returns void
   */
  public cancel(id: string): void {
    const abortEmitter = this.abortControllerMap.get(id);
    if (!abortEmitter) return;
    abortEmitter.abort();
    this.abortControllerMap.delete(id);
  }
}

export default new AbortableFetch();

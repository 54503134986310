import { ExitFloorChangeOptions as Props } from "@redux/slices/uiSlice";
import styles from "./ExitFloorChangeAlert.module.css";

const ExitFloorChangeAlert: React.FC<Props> = ({ destinationFloor }) => {
  return (
    <div className={styles.container}>
      <p className={styles.description}>
        You are now
        <br />
        on floor
      </p>
      <p className={styles.destination}>{destinationFloor}</p>
    </div>
  );
};

export default ExitFloorChangeAlert;

import type LivingMap from "@livingmap/core-mapping";

import { MarkerIconOptions, MarkerLabelOptions } from "./marker-image-creator";
import type { MarkerControl } from "@components/Map/plugins";
import { PLUGIN_IDS } from "@components/Map/plugins/types";

export class Markers {
  private readonly LMMap: LivingMap;
  private readonly markerController: MarkerControl;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
    this.markerController = this.LMMap.getPluginById<MarkerControl>(
      PLUGIN_IDS.MARKER
    );
  }

  /**
   * Create marker creates a marker on the map for a given lonlat.
   * This function allows to add additional options like color and a label to the icon.
   * @param  lonLat   a mapboxGL latlong object
   * @param  opts     an object with icon and label properties that should
   *                  adhere to the MarkerIconOptions and MarkerLabelOptions interfaceses.
   * @param  markerId Optional markerID, otherwise generated as UUID
   * @return          the given ID to the generated marker.
   */
  public create(
    lonLat: mapboxgl.LngLat,
    opts?: { icon?: MarkerIconOptions; label?: MarkerLabelOptions },
    markerId?: string
  ) {
    const iconOpts = opts?.icon || { color: "red", type: "", scale: 1 };
    const labelOpts = opts?.label || { text: "" };

    return this.markerController.addMarker(
      lonLat,
      iconOpts,
      labelOpts,
      markerId
    );
  }

  /**
   * Clears all markers from the map
   */
  public clearAll() {
    this.markerController.removeAllMarkers();
  }
}

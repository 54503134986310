import { LivingMapPlugin } from "@livingmap/core-mapping";
import { isLat, isLon } from "@livingmap/core-mapping/dist/utils";
import mapboxgl from "mapbox-gl";
import { v4 as uuidV4 } from "uuid";

import MarkerError from "@api/markers/marker-error";
import Marker from "@api/markers/marker-factory";
import {
  createMarkerElement,
  generateMarkerIcon,
  MarkerIconOptions,
  MarkerLabelOptions,
} from "@api/markers/marker-image-creator";

export default class MarkerControl extends LivingMapPlugin {
  private markers: { [k: string]: Marker } = {};

  public activate(): void {
    this.markers = {};
  }

  public deactivate(): void {
    this.removeAllMarkers();
  }

  private addToMarkerList(marker: Marker): void {
    this.markers[marker.getUUID()] = marker;
  }

  public getMarkerList(): { [k: string]: Marker } {
    return this.markers;
  }

  private getMarkerById(id: string): Marker | undefined {
    const markers = this.getMarkerList();
    return markers[id];
  }

  public addMarker(
    lnglat: mapboxgl.LngLat,
    iconOpts: MarkerIconOptions,
    labelOpts: MarkerLabelOptions,
    uuid?: string
  ) {
    if (isLon(lnglat.lng) && isLat(lnglat.lat)) {
      if (uuid) {
        const isIdAlreadyInUse = Boolean(this.getMarkerById(uuid));
        if (isIdAlreadyInUse) {
          throw new MarkerError(
            "Marker already exists with the given id, therefore can't be created."
          );
        }
      }

      const markerID = uuid || uuidV4();
      const generatedMarkerData = generateMarkerIcon(iconOpts);
      const DisplayElement = createMarkerElement(
        generatedMarkerData,
        labelOpts
      );

      const marker = new mapboxgl.Marker(DisplayElement, {
        anchor: generatedMarkerData.anchor,
      })
        .setLngLat(lnglat)
        .addTo(this.LMMap.getMapboxMap());

      const storedMarker = new Marker(markerID, marker, DisplayElement);
      this.addToMarkerList(storedMarker);

      return markerID;
    } else {
      throw new MarkerError("Invalid Lat/Long data, no marker placed.");
    }
  }

  public removeAllMarkers() {
    const markers = this.getMarkerList();
    Object.keys(markers).forEach((uuid) => {
      markers[uuid].destroy();
    });
    this.markers = {};
  }
}

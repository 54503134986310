import { AlertType, APIRouteLocation, ScreenIds, DebugInfo } from "./";
import { RouteLocation } from "@components/Map/plugins/controls/routing-control";
import {
  ApiNotificationParams,
  NotificationIcons,
} from "@redux/slices/uiSlice";
import { CustomEventAPICallbackFormat } from "../custom-api-callback-format";
import { BarometerValue, LocationOptions } from "../location/location";
import {
  MarkerIconOptions,
  MarkerLabelOptions,
} from "../markers/marker-image-creator";
import { AccessibilityStyles } from "../utility/utility";

export enum ApiEventTypes {
  /**
   * Shows modal of which content is populated with a supplied image url
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SHOW_IMAGE_MODAL", data)
   *```
   * ### Parameters
   * * **data**: { **imageUrl**: _string_, **title**: _string_, **icon**: _string_ }
   *   * **imageUrl**: _string_
   *   * **title**: _string_
   *   * **icon**: {@link NotificationIcons}
   *
   * @returns null
   */
  SHOW_IMAGE_MODAL = "SHOW_IMAGE_MODAL",
  /**
   * Shows modal informing the user the system is determining their position
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SHOW_DETERMINING_POSITION_MODAL")
   *```
   *
   * @returns null
   */
  SHOW_DETERMINING_POSITION_MODAL = "SHOW_DETERMINING_POSITION_MODAL",

  /**
   * Get generic debug information relating to this instance
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("GET_DEBUG_INFO")
   *```
   *
   * @returns {} {@link DebugInfo}
   */
  GET_DEBUG_INFO = "GET_DEBUG_INFO",

  /**
   * Loads a new screen to the end user without refreshing the page
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("NAVIGATE_TO_SCREEN", screenId)
   *```
   * ### Parameters
   * * **screenId**: _{@link ScreenIds}_
   *
   * @returns null
   */
  NAVIGATE_TO_SCREEN = "NAVIGATE_TO_SCREEN",

  /**
   * Clears the current route
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("CLEAR_ROUTE")
   *```
   *
   * @returns eventId
   */
  CLEAR_ROUTE = "CLEAR_ROUTE",

  /**
   * Updates the download progress of the map
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_MAP_DOWNLOAD_PROGRESS", percentage)
   *```
   * ### Parameters
   * - **percentage**: _number_
   *
   * @returns eventId
   */
  SET_MAP_DOWNLOAD_PROGRESS = "SET_MAP_DOWNLOAD_PROGRESS",

  /**
   * @deprecated
   * <br>
   * <br>
   *
   * Displays a modal to select your arrival/departure gates and render a route
   * <br>
   * <br>
   *
   * Fired using:
   * <br>
   * <br>
   *
   * ```typescript
   * window.api.fireEvent("SHOW_GATE_SELECTION", data)
   *```
   *
   * ### Parameters
   * * **data**: { **gates**?: [{ [name: _string_]: _string_ }] }
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **gates**?: _[{ [name: string]: string }]_
   *
   * @returns eventId
   */
  SHOW_GATE_SELECTION = "SHOW_GATE_SELECTION",

  /**
   * Displays a modal with your defined title, option 1 and option 2 choice.
   * Once a user makes a selection, it returns either "DECISION_MODAL_RESPONSE_OPTION_1" or "DECISION_MODAL_RESPONSE_OPTION_2"
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SHOW_DECISION_MODAL", data)
   *```
   *
   * ### Parameters
   * * **data**: { **title**: _string_, **option1Text**: _string_, **option2Text**: _string_ }
   *   * **title**: _string_
   *   * **option1Text**: _string_
   *   * **option2Text**: _string_
   *
   * @returns eventId
   */
  SHOW_DECISION_MODAL = "SHOW_DECISION_MODAL",

  /**
   * Updates the status bar with the defined style/text
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("STATUS_BAR_UPDATE", data)
   *```
   *
   * ### Parameters
   * * **data**: { **statusBarStyle**?: _{@link AlertType}_; **statusBarText**?: _string_ }
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **statusBarStyle**?: _{@link AlertType}_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **statusBarText**?: _string_
   *
   * @returns eventId
   */
  STATUS_BAR_UPDATE = "STATUS_BAR_UPDATE",

  /**
   * Displays a confirmation modal with your defined header, body and variable text
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SHOW_BIG_CONFIRMATION_ALERT", data)
   *```
   *
   * ### Parameters
   * * **data**: { **header**: _string_, **body**: _string_, **variable**: _string_ }
   *   * **header**: _string_
   *   * **body**: _string_
   *   * **variable**: _string_
   *
   * @returns eventId
   */
  SHOW_BIG_CONFIRMATION_ALERT = "SHOW_BIG_CONFIRMATION_ALERT",

  /**
   * Displays a modal with a custom message and a loading spinner
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SHOW_BIG_LOADING_ALERT", data)
   *```
   *
   * ### Parameters
   * * **data**: { **message**: _string_ }
   *   * **message**: _string_
   *
   * @returns eventId
   */
  SHOW_BIG_LOADING_ALERT = "SHOW_BIG_LOADING_ALERT",

  /**
   * Displays a modal with an optional custom header message, an optional "retry" button and an "exit" button.
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SHOW_FATAL_ERROR_ALERT", data)
   *```
   *
   * ### Parameters
   * * **data**: { **headerText**?: _string_, **retryCallback**?: _(payload: {@link CustomEventAPICallbackFormat}) => void_, **exitCallback**: _(payload: {@link CustomEventAPICallbackFormat}) => void_ }
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **headerText**?: _string_ &mdash; Optionally display custom header text
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **retryCallback**?: _(payload: {@link CustomEventAPICallbackFormat}) => void_ &mdash; Provide an optional callback, which will render a "retry" button on the modal to trigger the provided callback
   *   * **exitCallback**: _(payload: {@link CustomEventAPICallbackFormat}) => void_ &mdash; Provide a callback, which will be triggered when the exit button on the modal is clicked
   *
   * @returns eventId
   */
  SHOW_FATAL_ERROR_ALERT = "SHOW_FATAL_ERROR_ALERT",

  /**
   * Requests and renders a route based on various options that can be passed in
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("REQUEST_ROUTE_JOURNEY", data)
   *```
   *
   * ### Parameters
   * * **data**: { **destination**: _{@link APIRouteLocation}_, **origin**: _{@link APIRouteLocation}_, **options**?: { **via**?: _{@link RouteLocation}_, **destinationAreaName**?: _string_, **destinationName**?: _string_, **routeLabels**?: _boolean_ }, **callback**?: (payload: {@link CustomEventAPICallbackFormat}) => void }
   *   * **destination**: _{@link APIRouteLocation}_
   *   * **origin**: _{@link APIRouteLocation}_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **options**?: { **via**?: _{@link RouteLocation}_, **destinationAreaName**?: _string_, **destinationName**?: _string_, **routeLabels**?: _boolean_ }
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **via**?: _{@link RouteLocation}_
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **destinationAreaName**?:_string_
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **destinationName**?: _string_
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **routeLabels**?: _boolean_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **callback**?: (payload: {@link CustomEventAPICallbackFormat}) => void
   *
   * @returns eventId
   */
  REQUEST_ROUTE_JOURNEY = "REQUEST_ROUTE_JOURNEY",

  /**
   * @deprecated
   * <br>
   * <br>
   *
   * (Offline) Requests and renders a route based on various options that can be passed in
   * <br>
   * <br>
   *
   * Fired using:
   * <br>
   * <br>
   *
   * ```typescript
   * window.api.fireEvent("REQUEST_FILTERED_ROUTE_JOURNEY", data)
   *```
   *
   * ### Parameters
   * * **data**: { **destination**: _string_, **origin**: _string_, **options**?: { **via**?: _{@link RouteLocation}_, **destinationAreaName**?: _string_, **destinationName**?: _string_ }, **callback**?: _(payload: {@link CustomEventAPICallbackFormat}) => void_ }
   *   * **destination**: _string_
   *   * **origin**: _string_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **options**?: { **via**?: _{@link RouteLocation}_, **destinationAreaName**?: _string_, **destinationName**?: _string_ }
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **via**?: _{@link RouteLocation}_
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **destinationAreaName**?:_string_
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **destinationName**?: _string_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **callback**?: _(payload: {@link CustomEventAPICallbackFormat}) => void_
   *
   * @returns eventId
   */
  REQUEST_FILTERED_ROUTE_JOURNEY = "REQUEST_FILTERED_ROUTE_JOURNEY",

  /**
   * Returns the remaining time (km) and distance (min) for the active route
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("GET_ROUTE_STATUS")
   *```
   *
   * @returns {} { **remaining_distance**: _number_ | _null_; **remaining_time**: _number_ | _null_ }
   * * **remaining_distance**: _number_ | _null_
   * * **remaining_time**: _number_ | _null_
   */
  GET_ROUTE_STATUS = "GET_ROUTE_STATUS",

  /**
   * Set a user location
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_USER_LOCATION", data)
   *```
   *
   * ### Parameters
   * * **data**: { **newUserlocation**: _{@link LngLatLike}_, **options**?: { _{@link LocationOptions}_ } }
   *   * **newUserlocation**: _{@link LngLatLike}_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **options**?: { _{@link LocationOptions}_ }
   *
   */
  SET_USER_LOCATION = "SET_USER_LOCATION",

  /**
   * Set the heading for the user
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_USER_HEADING", newHeading)
   *```
   *
   * ### Parameters
   * * **newHeading**: _number_
   *
   */
  SET_USER_HEADING = "SET_USER_HEADING",

  /**
   * Get the active floor ID for the map
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("GET_DISPLAYED_FLOOR")
   *```
   *
   */
  GET_DISPLAYED_FLOOR = "GET_DISPLAYED_FLOOR",

  /**
   * Set the center of the map by passing in coordinates to be centered on.
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_MAP_CENTER", coordinates)
   *```
   *
   * ### Parameters
   * * **coordinates**: {@link LngLatLike}
   *
   */
  SET_MAP_CENTER = "SET_MAP_CENTER",

  /**
   * Get the center coordinates of the map
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("GET_MAP_CENTER")
   *```
   *
   * @returns {} {@link LngLatLike}
   */
  GET_MAP_CENTER = "GET_MAP_CENTER",

  /**
   * Toggle if the user is walking or not
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_USER_IS_WALKING", isWalking)
   *```
   *
   * ### Parameters
   * * **isWalking**: _boolean_
   *
   * @returns null
   */
  SET_USER_IS_WALKING = "SET_USER_IS_WALKING",

  /**
   * Define if a floor change alert should be displayed, based on a barometer status that gets passed in
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_BAROMETER_VALUE", status)
   *```
   *
   * ### Parameters
   * * **status**: _{@link BarometerValue}_
   *
   * @returns null
   */
  SET_BAROMETER_VALUE = "SET_BAROMETER_VALUE",

  /**
   * Creates a marker on the map for the given coordinates.
   * This function allows to add additional options like color and a label to the icon.
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("CREATE_MARKER", data)
   *```
   *
   * ### Parameters
   * * **data**: { **coordinates**: _{@link LngLat}_, **options**?: { **icon**?: _{@link MarkerIconOptions}_, **label**?: _{@link MarkerLabelOptions}_ }, **markerId**?: _string_ }
   *   * **coordinates**: _{@link LngLat}_
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **options**?: { **icon**?: _{@link MarkerIconOptions}_, **label**?: _{@link MarkerLabelOptions}_ }
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **icon**?: _{@link MarkerIconOptions}_
   *     * <span class="tsd-flag ts-flagOptional">Optional</span> **label**?: _{@link MarkerLabelOptions}_ }
   *   * <span class="tsd-flag ts-flagOptional">Optional</span> **markerId**?: _string_ &mdash; If no markerId is provided, a UUID will be generated
   *
   * @returns the ID assigned to the marker
   */
  CREATE_MARKER = "CREATE_MARKER",

  /**
   * Clears all markers from the map
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("CLEAR_ALL_MARKERS")
   *```
   *
   * @returns null
   */
  CLEAR_ALL_MARKERS = "CLEAR_ALL_MARKERS",

  /**
   * Sets the style of the map to one of the {@link AccessibilityStyles}
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_MAP_STYLE", styleType)
   *```
   *
   * ### Parameters
   * * **styleType**: _{@link AccessibilityStyles}_
   *
   * @returns null
   */
  SET_MAP_STYLE = "SET_MAP_STYLE",

  /**
   * Toggle whether a route should include steps or not
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_STEP_FREE_ROUTING", isStepFree)
   *```
   *
   * ### Parameters
   *  * **isStepFree**: _boolean_
   *
   * @returns null
   */
  SET_STEP_FREE_ROUTING = "SET_STEP_FREE_ROUTING",

  /**
   * Add a notification
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("ADD_NOTIFICATION", data)
   *```
   *
   * ### Parameters
   *  * **data**: _{@link ApiNotificationParams}_
   *
   * @returns the ID of the notification created
   */
  ADD_NOTIFICATION = "ADD_NOTIFICATION",

  /**
   * Remove a notification
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("REMOVE_NOTIFICATION", id)
   *```
   *
   * ### Parameters
   *  * **id**: _string_ &mdash; ID of the notification to remove
   *
   * @returns null
   */
  REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",

  /**
   * Enable feature select functionality
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("ENABLE_INTERACTIVE_MARKERS")
   *```
   *
   * @returns null
   */
  ENABLE_INTERACTIVE_MARKERS = "ENABLE_INTERACTIVE_MARKERS",

  /**
   * Disable feature select functionality
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("DISABLE_INTERACTIVE_MARKERS")
   *```
   *
   * @returns null
   */
  DISABLE_INTERACTIVE_MARKERS = "DISABLE_INTERACTIVE_MARKERS",

  /**
   * Sets a flag to ignore an active floor change
   *
   * Fired using:
   *
   * ```typescript
   * window.api.fireEvent("SET_IGNORE_ACTIVE_FLOOR_CHANGE", data)
   *```
   *
   * ### Parameters
   * **data**: _boolean_ &mdash; Whether it is true or false
   *
   * @returns null
   */
  SET_IGNORE_ACTIVE_FLOOR_CHANGE = "SET_IGNORE_ACTIVE_FLOOR_CHANGE",
}

/**
 * @internal
 */
export type ApiAction =
  | {
      type: ApiEventTypes.SET_MAP_DOWNLOAD_PROGRESS;
      payload: number;
    }
  | {
      type: ApiEventTypes.CLEAR_ROUTE;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.SHOW_GATE_SELECTION;
      payload: {
        gates?: [{ [name: string]: string }];
      };
    }
  | {
      type: ApiEventTypes.STATUS_BAR_UPDATE;
      payload: {
        statusBarText?: string;
        statusBarStyle?: AlertType;
      };
    }
  | {
      type: ApiEventTypes.SHOW_BIG_CONFIRMATION_ALERT;
      payload: {
        header: string;
        body: string;
        variable: string;
      };
    }
  | {
      type: ApiEventTypes.SHOW_FATAL_ERROR_ALERT;
      payload: {
        headerText?: string;
        retryCallback?: (payload: CustomEventAPICallbackFormat) => void;
        exitCallback: (payload: CustomEventAPICallbackFormat) => void;
      };
    }
  | {
      type: ApiEventTypes.SHOW_BIG_LOADING_ALERT;
      payload: {
        message: string;
      };
    }
  | {
      type: ApiEventTypes.REQUEST_ROUTE_JOURNEY;
      payload: {
        destination: APIRouteLocation;
        origin: APIRouteLocation;
        options?: {
          via?: RouteLocation[];
          destinationAreaName?: string;
          destinationName?: string;
          routeLabels?: boolean;
        };
        callback?: (payload: CustomEventAPICallbackFormat) => void;
      };
    }
  | {
      type: ApiEventTypes.REQUEST_FILTERED_ROUTE_JOURNEY;
      payload: {
        destination: string;
        origin: string;
        options?: {
          via?: RouteLocation[];
          destinationAreaName?: string;
          destinationName?: string;
        };
        callback?: (payload: CustomEventAPICallbackFormat) => void;
      };
    }
  | {
      type: ApiEventTypes.SHOW_DECISION_MODAL;
      payload: {
        title: string;
        option1Text: string;
        option2Text: string;
      };
    }
  | {
      type: ApiEventTypes.GET_ROUTE_STATUS;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.SET_USER_LOCATION;
      payload: {
        newUserLocation: mapboxgl.LngLatLike;
        options?: LocationOptions;
      };
    }
  | {
      type: ApiEventTypes.SET_USER_HEADING;
      payload: number;
    }
  | {
      type: ApiEventTypes.GET_DISPLAYED_FLOOR;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.SET_MAP_CENTER;
      payload: mapboxgl.LngLatLike;
    }
  | {
      type: ApiEventTypes.GET_MAP_CENTER;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.SET_USER_IS_WALKING;
      payload: boolean;
    }
  | {
      type: ApiEventTypes.SET_BAROMETER_VALUE;
      payload: BarometerValue;
    }
  | {
      type: ApiEventTypes.CREATE_MARKER;
      payload: {
        coordinates: mapboxgl.LngLat;
        options?: {
          icon?: MarkerIconOptions;
          label?: MarkerLabelOptions;
        };
        markerId?: string;
      };
    }
  | {
      type: ApiEventTypes.CLEAR_ALL_MARKERS;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.SET_MAP_STYLE;
      payload: AccessibilityStyles;
    }
  | {
      type: ApiEventTypes.SET_STEP_FREE_ROUTING;
      payload: boolean;
    }
  | {
      type: ApiEventTypes.ADD_NOTIFICATION;
      payload: ApiNotificationParams;
    }
  | {
      type: ApiEventTypes.REMOVE_NOTIFICATION;
      payload: string;
    }
  | {
      type: ApiEventTypes.ENABLE_INTERACTIVE_MARKERS;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.DISABLE_INTERACTIVE_MARKERS;
      payload: unknown;
    }
  | {
      type: ApiEventTypes.NAVIGATE_TO_SCREEN;
      payload: ScreenIds;
    }
  | {
      type: ApiEventTypes.GET_DEBUG_INFO;
      payload: DebugInfo; // This payload is not required, but is only added here so it correctly links in the docs generator
    }
  | {
      type: ApiEventTypes.SET_IGNORE_ACTIVE_FLOOR_CHANGE;
      payload: boolean;
    }
  | {
      type: ApiEventTypes.SHOW_IMAGE_MODAL;
      payload: {
        imageUrl: string;
        title: string;
        icon: NotificationIcons;
      };
    };

/**
 * @internal
 */
export type ExtractActionPayload<A extends ApiAction, T> = A extends {
  type: T;
  payload: A["payload"];
}
  ? A["payload"]
  : never;

import TagManager from "react-gtm-module";

import { Logger } from "@utils";

class TagManagerControl {
  private tagManagerId: string[] | null = null; // TODO: get values from DB config
  private googleAnalyticsTrackingCodes: string[] | null = null; // TODO: get values from DB config

  public sendEventToDataLayer(
    categoryName: string,
    eventAction: string,
    eventLabel: string
  ): void {
    const projectHasNoGAsetup =
      !this.tagManagerId || !this.googleAnalyticsTrackingCodes;
    const envDoesNotAllowForGA = !process.env.REACT_APP_IS_ANALYTICS_ENABLED;

    if (envDoesNotAllowForGA || projectHasNoGAsetup) {
      Logger.devLog(
        `GA_EVENT: ['${categoryName}', '${eventAction}', '${eventLabel}']`
      );
      return;
    }

    if (!this.tagManagerId) return;

    for (const tagManagerId of this.tagManagerId) {
      const tagManagerArgs = {
        gtmId: tagManagerId,
        events: { event: "Custom Event" },
        dataLayer: {
          categoryName,
          eventLabel,
          eventAction,
          googleAnalyticsTrackingCodes: this.googleAnalyticsTrackingCodes,
        },
      };
      TagManager.initialize(tagManagerArgs);
    }
  }
}

export default new TagManagerControl();

import type { LayerDelegate } from "@livingmap/core-mapping";
import { RoutingOptions } from "../components/Map/plugins/types";

const DEFAULT_SOURCE = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [],
  },
} as mapboxgl.GeoJSONSourceRaw;

const DEFAULT_MIDDLE_CURRENT_STYLING = {
  "line-color": RoutingOptions.BASE_ROUTE_COLOUR,
  "line-width": 5,
};

const DEFAULT_MIDDLE_OTHER_STYLING = {
  "line-color": RoutingOptions.BASE_ROUTE_COLOUR,
  "line-width": 5,
};

const DEFAULT_START_STYLING = {
  "circle-radius": 7,
  "circle-color": "green",
};

const DEFAULT_END_STYLING = {};

const DEFAULT_CURRENT_LAYER = {
  id: RoutingOptions.ROUTING_CURRENT_FLOOR_LAYER_ID,
  type: "line",
  source: RoutingOptions.ROUTE_SOURCE_ID,
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: DEFAULT_MIDDLE_CURRENT_STYLING,
} as mapboxgl.AnyLayer;

const DEFAULT_OTHER_LAYER = {
  id: RoutingOptions.ROUTING_OTHER_FLOOR_LAYER_ID,
  type: "line",
  source: RoutingOptions.ROUTE_SOURCE_ID,
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: DEFAULT_MIDDLE_OTHER_STYLING,
} as mapboxgl.AnyLayer;

const DEFAULT_ROUTE_END_LAYER = {
  id: RoutingOptions.LAYER_END_ID,
  type: "symbol",
  source: RoutingOptions.ROUTE_END_SOURCE_ID,
  layout: {
    "icon-image": "routing-finish-line",
  },
  paint: DEFAULT_END_STYLING,
} as mapboxgl.AnyLayer;

const DEFAULT_ROUTE_START_LAYER = {
  id: RoutingOptions.LAYER_START_ID,
  type: "circle",
  source: RoutingOptions.ROUTE_START_SOURCE_ID,
  layout: {},
  paint: DEFAULT_START_STYLING,
} as mapboxgl.AnyLayer;

const USER_LOCATION_LAYER_ID = "user-location-layer";

export const setupRoutingLayers = (
  mapInstance: mapboxgl.Map,
  layerDelegate: LayerDelegate
) => {
  const isUserLocationLayer = mapInstance.getLayer(USER_LOCATION_LAYER_ID);

  const routingMiddleSource = mapInstance.getSource(
    RoutingOptions.ROUTE_SOURCE_ID
  );
  const routingStartSource = mapInstance.getSource(
    RoutingOptions.ROUTE_START_SOURCE_ID
  );
  const routingEndSource = mapInstance.getSource(
    RoutingOptions.ROUTE_END_SOURCE_ID
  );

  const routingStartLayer = mapInstance.getLayer(RoutingOptions.LAYER_START_ID);
  const routingEndLayer = mapInstance.getLayer(RoutingOptions.LAYER_END_ID);

  const routingCurrentLayer = mapInstance.getLayer(
    RoutingOptions.ROUTING_CURRENT_FLOOR_LAYER_ID
  );
  const routingOtherLayer = mapInstance.getLayer(
    RoutingOptions.ROUTING_OTHER_FLOOR_LAYER_ID
  );

  if (!routingMiddleSource) {
    layerDelegate.addSource(RoutingOptions.ROUTE_SOURCE_ID, {
      ...DEFAULT_SOURCE,
    });
  }

  if (!routingOtherLayer) {
    layerDelegate.addLayer(
      DEFAULT_OTHER_LAYER,
      isUserLocationLayer ? USER_LOCATION_LAYER_ID : undefined
    );
  }

  if (!routingCurrentLayer) {
    layerDelegate.addLayer(
      DEFAULT_CURRENT_LAYER,
      isUserLocationLayer ? USER_LOCATION_LAYER_ID : undefined
    );
  }

  if (!routingStartSource) {
    layerDelegate.addSource(RoutingOptions.ROUTE_START_SOURCE_ID, {
      ...DEFAULT_SOURCE,
    });
  }

  if (!routingStartLayer) {
    layerDelegate.addLayer(
      DEFAULT_ROUTE_START_LAYER,
      isUserLocationLayer ? USER_LOCATION_LAYER_ID : undefined
    );
  }

  if (!routingEndSource) {
    layerDelegate.addSource(RoutingOptions.ROUTE_END_SOURCE_ID, {
      ...DEFAULT_SOURCE,
    });
  }

  if (!routingEndLayer) {
    layerDelegate.addLayer(
      DEFAULT_ROUTE_END_LAYER,
      isUserLocationLayer ? USER_LOCATION_LAYER_ID : undefined
    );
  }
};

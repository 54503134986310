import type { ChangeEvent } from "react";
import { DropdownMenu } from "@livingmap/core-ui";
import classNames from "classnames";

import styles from "./GateSelectionModal.module.css";

type GateOption = {
  id: string;
  text: string;
};

interface Props {
  arrivalOptions: GateOption[];
  destinationOptions: GateOption[];
  onOriginChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onDestinationChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onShowRouteClick: () => void;
  onShowMapClick: () => void;
}

const GateSelectionModal: React.FC<Props> = ({
  arrivalOptions,
  destinationOptions,
  onOriginChange,
  onDestinationChange,
  onShowRouteClick,
  onShowMapClick,
}) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>
        To view a route,
        <br />
        select your arrival and departure gates:
      </h2>
      <div className={styles.dropdownContainer}>
        <DropdownMenu
          id="origin"
          placeholder="Arrival Gates"
          options={arrivalOptions}
          onChange={onOriginChange}
        />
        <DropdownMenu
          id="destination"
          placeholder="Departure Gates"
          options={destinationOptions}
          onChange={onDestinationChange}
        />
      </div>
      <button
        className={classNames(styles.button, styles.buttonPrimary)}
        onClick={onShowRouteClick}
      >
        <span>Show route</span>
      </button>
      <span className={styles.separator}>- or -</span>
      <button
        className={classNames(styles.button, styles.buttonSecondary)}
        onClick={onShowMapClick}
      >
        <span>View airport map</span>
      </button>
    </div>
  );
};

export default GateSelectionModal;

import EventEmitter from "eventemitter3";

import APILocationProvider from "./api-location-provider";
import LMUserLocation from "../routing/LMUserLocation";
import { isLocationInExtent } from "@utils";
import { EventTypes } from "../types";

/* tslint:disable max-line-length  */
const PERMISSION_DENIED_MESSAGE =
  "Sorry, we can’t show your location without your permission, please update your location sharing preferences.";
const POSITION_UNAVAILABLE_MESSAGE =
  "Sorry, there was a problem, please refresh and try again.";
const POSITION_TIMEOUT_MESSAGE =
  "Sorry, there was a problem, please refresh and try again.";
// export added for unit test.
/* tslint:disable max-line-length  */
export const POSITION_OUT_OF_BOUNDS_MESSAGE =
  "It has not been possible to show your location, because it is beyond the edges of this map.";

export default class UserLocationService extends EventEmitter {
  private locationProvider: Geolocation | null;
  private watchId: number | null;
  private locationInterval: any;
  private positionOptions: PositionOptions;

  public lastResponse: GeolocationPositionError | GeolocationPosition | null =
    null;

  constructor() {
    super();
    this.locationProvider = null;
    this.watchId = null;
    this.locationInterval = null;
    this.positionOptions = {
      enableHighAccuracy: true,
      maximumAge: Infinity,
    };
  }

  public watch = () => {
    if (this.watchId !== null) return;
    this.watchId = this.locationProvider!.watchPosition(
      this.geoLocationSuccessHandler,
      this.geoLocationErrorHandler,
      this.positionOptions
    );
  };

  public stop = () => {
    if (this.watchId) {
      this.locationProvider!.clearWatch(this.watchId);
    }
    this.removeInterval();
  };

  private geoLocationSuccessHandler = (position: GeolocationPosition) => {
    this.lastResponse = position;

    const location = new LMUserLocation(position);
    const inExtent = isLocationInExtent(location.getAsCoordinate());
    if (inExtent) {
      this.emit(EventTypes.SERVICE_LOCATION_UPDATE, location, null);
    } else {
      this.geoLocationErrorHandler({ code: 4 } as GeolocationPositionError);
    }
  };

  private getErrorMessageForErrorType = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return PERMISSION_DENIED_MESSAGE;

      case error.POSITION_UNAVAILABLE:
        return POSITION_UNAVAILABLE_MESSAGE;

      case error.TIMEOUT:
        return POSITION_TIMEOUT_MESSAGE;

      case 4: // Living Map out of bounds.
        return POSITION_OUT_OF_BOUNDS_MESSAGE;
    }
  };

  private geoLocationErrorHandler = (error: GeolocationPositionError) => {
    this.lastResponse = error;
    const message = this.getErrorMessageForErrorType(error);
    this.emit(EventTypes.SERVICE_LOCATION_UPDATE, null, {
      ...error,
      message,
    } as GeolocationPositionError);
  };

  private removeInterval = () => {
    if (this.locationInterval) {
      clearInterval(this.locationInterval);
      this.locationInterval = null;
    }
  };

  // If providing your own locationProvider it needs to conform to the HTML5 GeoLocation
  // interface - that is implements clearWatch(), getCurrentPosition() and watchPosition()
  // and returns Position via a callback.
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Geolocation
  //      https://developer.mozilla.org/en-US/docs/Web/Position
  // nb. setting a provider clears any interval & watch - the caller must restart it.
  public registerLocationProvider = (
    provider: APILocationProvider | Geolocation
  ) => {
    if (this.locationProvider) this.stop();
    this.locationProvider = provider;
  };

  public getLocationProvider = (): APILocationProvider | Geolocation | null => {
    return this.locationProvider;
  };

  public setAPIUserLocation(userLocation: LMUserLocation): void {
    const locationProvider = this.getLocationProvider();
    if (locationProvider instanceof APILocationProvider) {
      locationProvider.setUserLocation(userLocation);
    }
  }
}

import { bboxPolygon, inside, point } from "@turf/turf";

import { Coordinate } from "@components/Map/plugins/types";
import { vectorApi } from "@redux/services/sdk";
import { store } from "@redux/store";

const IOS_LIVINGMAP_CUSTOM_URL_SCHEME = "livingmap://app";
const ROUTING_URL = `https://routing2.${process.env.REACT_APP_ENVIRONMENT}.livingmap.com/route`;
const FILTERED_ROUTES_URL = `https://vector.${process.env.REACT_APP_ENVIRONMENT}.livingmap.com/data/${process.env.REACT_APP_PROJECT_NAME}/filtered-routes`;
const GEOFENCE_URL = `https://vector.${process.env.REACT_APP_ENVIRONMENT}.livingmap.com/data/${process.env.REACT_APP_PROJECT_NAME}/geofences.geojson`;
const STYLES_URL = `https://vector.${process.env.REACT_APP_ENVIRONMENT}.livingmap.com/styles/${process.env.REACT_APP_PROJECT_NAME}`;
const PROJECT_LOGO_URL = `https://static.${process.env.REACT_APP_ENVIRONMENT}.livingmap.com/${process.env.REACT_APP_PROJECT_NAME}/splashscreen-banner.svg`;

export enum URLTypes {
  LIVE,
  FILTERED,
  GEOFENCE,
  STYLES,
  PROJECT_LOGO,
}

export enum MapFeatures {
  SEARCH = "search",
  DIRECTIONS = "directions",
  ROUTING = "routing",
  USER_LOCATION = "user_location",
  LIVE_REPORTING_MODE = "live_reporting",
  GEOFENCES = "geofences",
}

export const getUrl = (urlType: URLTypes) => {
  let finalUrl = "";

  switch (urlType) {
    case URLTypes.LIVE:
      finalUrl = ROUTING_URL;
      break;
    case URLTypes.FILTERED:
      finalUrl = FILTERED_ROUTES_URL;
      break;
    case URLTypes.GEOFENCE:
      finalUrl = GEOFENCE_URL;
      break;
    case URLTypes.STYLES:
      finalUrl = STYLES_URL;
      break;
    case URLTypes.PROJECT_LOGO:
      finalUrl = PROJECT_LOGO_URL;
      break;
    default:
      finalUrl = "";
  }

  // Done for offline support on iOS front.
  if (window.location.href.indexOf(IOS_LIVINGMAP_CUSTOM_URL_SCHEME) > -1) {
    finalUrl = finalUrl.replace(
      "https://",
      `${IOS_LIVINGMAP_CUSTOM_URL_SCHEME}/`
    );
    finalUrl = finalUrl.replace(
      "http://",
      `${IOS_LIVINGMAP_CUSTOM_URL_SCHEME}/`
    );
  }

  return finalUrl;
};

export const doesMapHaveFeatureEnabled = (feature: MapFeatures) => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const mapFeature = storeSelector(store.getState()).data?.mapFeature;

  return !!mapFeature?.[feature];
};

export const getMapGenericConfig = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const genericConfig = storeSelector(store.getState()).data?.generic;

  return genericConfig || null;
};

export const getFullStylesUrl = (styleName: string) => {
  return `${getUrl(URLTypes.STYLES)}/${styleName}.json`;
};

export const getAttemptRerouteCount = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const routingConfig = storeSelector(store.getState()).data?.routing;

  if (
    routingConfig &&
    routingConfig.attempt_reroute_count !== null &&
    routingConfig.attempt_reroute_count !== undefined
  ) {
    return routingConfig.attempt_reroute_count;
  }

  return 1;
};

export const getFloorConfig = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const floors = storeSelector(store.getState()).data?.floors;

  return floors || null;
};

export const getGroundFloor = () => {
  const floors = getFloorConfig();
  return floors!.filter((floor) => floor.floor === "0.0")[0];
};

export const getDefaultFloor = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const floors = storeSelector(store.getState()).data?.floors;

  return floors?.find((floor) => floor.default) || null;
};

export const getFloorById = (id: number) => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const floors = storeSelector(store.getState()).data?.floors;

  const found = floors?.find((floor) => floor.id === id);

  if (!found) return null;

  return found;
};

export const getFloorByName = (name: string) => {
  const floors = getFloorConfig();

  const matchedFloor = floors?.find((floor) => floor.name === name);

  if (!matchedFloor) return null;

  return matchedFloor;
};

export const getUIConfig = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const ui = storeSelector(store.getState()).data?.ui;

  return ui || null;
};

export const getMapBranding = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const branding = storeSelector(store.getState()).data?.branding;

  const colours = {
    primary: "#EBB428",
    secondary: "#003250",
    background: "#194661",
  };

  return {
    ...(branding || colours),
    font: getUIConfig()?.font,
  };
};

export const getRouteLocations = () => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();
  const routes = storeSelector(store.getState()).data?.routes;

  return routes || null;
};

export const isLocationInExtent = (location: Coordinate) => {
  const storeSelector = vectorApi.endpoints.configEndpoint.select();

  const extent = storeSelector(store.getState()).data?.areas[0].view.extent;
  const locationPoint = point([location.x, location.y]);
  const bbox = bboxPolygon(extent!);
  const isInsideBounds = inside(locationPoint, bbox);

  return isInsideBounds;
};

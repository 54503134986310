import AbortableFetchInstance from "./abortable-fetch";
import { Logger } from "@utils";

type FetchDelayFunc = ((callback: () => void) => void) | null;
type RetryOnFunc = (
  count: number,
  error: any,
  response: Response | null
) => [boolean, FetchDelayFunc];

const LIVINGMAP_RETRY_COUNT_LIMIT = 3;

/**
 * The default Living Map fetch retry strategy
 *
 * @param  {number} count the amount of times we tried to make the request.
 * @param  {any} error any error from the .catch()
 * @param  {Response} response the response object from the previous request made
 */
export const defaultRetryOnStrategy: RetryOnFunc = (count, error, response) => {
  if (error !== null) return [false, null];

  if (count >= LIVINGMAP_RETRY_COUNT_LIMIT) return [false, null];

  const isRetryAbleStatusCode = [429, 503].indexOf(response!.status) !== -1;
  const retryAfterHeader = response!.headers.get("Retry-After");

  // we will only retry if both the status code is wonky AND there is a retry after header present.
  if (!isRetryAbleStatusCode || retryAfterHeader === null) return [false, null];

  const retryAfterInMs = parseInt(retryAfterHeader, 10) * 1000;
  if (isNaN(retryAfterInMs)) {
    Logger.warn(
      "The Living Map retry strategy only supports integer based `Retry-After` headers"
    );
  }

  return [true, (callback) => setTimeout(() => callback(), retryAfterInMs)];
};

interface AutoRetryFetchInit extends RequestInit {
  /**
   * The retryOn function determines whether to retry the previous request or not.
   * The return function supports adding in an optional delay function. This delay function will be called
   * first, and once the delay is clompleted, will proceed to retry the request.
   */
  retryOn: RetryOnFunc;
}

interface InternalFetchOpts {
  attempt: number;
  resolve: (value: Response | PromiseLike<Response>) => void;
  reject: (reason?: any) => void;
  retry: (interOpts: InternalFetchOpts, delayFunc: FetchDelayFunc) => void;
}

class AutoRetryFetch {
  private wrappedFetcher: typeof AbortableFetchInstance;

  constructor(wrappedFetcher: typeof AbortableFetchInstance) {
    this.wrappedFetcher = wrappedFetcher;
  }

  public fetch(
    url: string,
    id: string,
    opts: AutoRetryFetchInit
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      const retryFunc = (
        interOpts: InternalFetchOpts,
        delayFunc: FetchDelayFunc
      ) => {
        interOpts.attempt += 1;
        if (!delayFunc) this.internalFetch(url, id, opts, interOpts);
        else {
          delayFunc(() => this.internalFetch(url, id, opts, interOpts));
        }
      };

      this.internalFetch(url, id, opts, {
        attempt: 1,
        resolve,
        reject,
        retry: retryFunc,
      });
    });
  }

  private internalFetch(
    url: string,
    id: string,
    opts: AutoRetryFetchInit,
    internalOpts: InternalFetchOpts
  ): void {
    this.wrappedFetcher
      .fetch(url, id, opts)
      .then((response) => {
        const [shouldRetry, delayFunc] = opts.retryOn(
          internalOpts.attempt,
          null,
          response
        );
        if (shouldRetry) internalOpts.retry(internalOpts, delayFunc);
        else if (response.ok) internalOpts.resolve(response);
        else
          internalOpts.reject(
            new Error(
              "Fetch retry limit reached! No successful payload retrieved"
            )
          );
      })
      .catch((error) => {
        const [shouldRetry, withDelayinMS] = opts.retryOn(
          internalOpts.attempt,
          error,
          null
        );
        if (shouldRetry) internalOpts.retry(internalOpts, withDelayinMS);
        else internalOpts.reject(error);
      });
  }

  public cancel(id: string): void {
    this.wrappedFetcher.cancel(id);
  }
}

export default new AutoRetryFetch(AbortableFetchInstance);

import React from "react";
import styles from "./Countdown.module.css";

interface Props {
  buttonText: string;
  counter?: number;
  onClick: () => void;
}

const Countdown: React.FC<Props> = ({ buttonText, counter, onClick }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <span>{buttonText}</span>
      {counter && <span className={styles.dial}>{counter}</span>}
    </button>
  );
};

export default Countdown;

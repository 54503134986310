import type LivingMap from "@livingmap/core-mapping";
import { bearing, distance } from "@turf/turf";
import EventEmitter from "eventemitter3";

import { nearestPointOnLine } from "@utils";
import Mobile from "@mobile";
import LMUserLocation from "./LMUserLocation";
import RoutingCompletionManager from "./route-completion-manager";
import type { ConfigResponse, Floor } from "@redux/services/types";
import { EventTypes, PLUGIN_IDS, RouteProgressDescriptor } from "../types";
import type { FloorControl } from "../";
import { store } from "@redux/store";
import { getHighestRouteSequenceNumber } from "@redux/slices/routingSlice";

interface PointOnRouteDescriptor {
  location: LMUserLocation;
  distance: number;
  index: number;
  multiIndex: number;
}

class SnapManager extends EventEmitter {
  private static instance: SnapManager | undefined;
  private mapConfig: ConfigResponse;
  private routingCompletionManager: RoutingCompletionManager;
  private latestDistanceToRoute: number | null;
  private latestProgessDescriptor: RouteProgressDescriptor | null;

  private floorControl: FloorControl | null = null;

  private HEADING_MAX_DIFFERENCE_SCALING_FACTOR = 2.0 / 3.0;
  private headingMaxDifferenceFromRoute = (
    distanceFromClosestPointOnRoute: number
  ) =>
    Math.atan(distanceFromClosestPointOnRoute) *
    this.HEADING_MAX_DIFFERENCE_SCALING_FACTOR *
    (180 / Math.PI);

  private constructor(mapConfig: ConfigResponse) {
    super();
    this.mapConfig = mapConfig;
    this.routingCompletionManager = new RoutingCompletionManager();
    this.latestDistanceToRoute = null;
    this.latestProgessDescriptor = null;
  }

  public static createInstance(mapConfig: ConfigResponse) {
    this.instance = new SnapManager(mapConfig);
  }

  public static getInstance() {
    if (!this.instance) {
      throw new Error(
        `${this.name} class has not been instantiated. Try calling "createInstance" first.`
      );
    }

    return this.instance;
  }

  public getLatestProgressDescriptor() {
    return this.latestProgessDescriptor;
  }

  public getLMMapInstanceForFloorControl(LMMap: LivingMap): void {
    this.floorControl = LMMap.getPluginById<FloorControl>(PLUGIN_IDS.FLOOR);
  }

  public handleSnapToRoute(newLocation: LMUserLocation): LMUserLocation {
    const { routing } = store.getState();
    if (!routing.route.multiline) {
      this.latestDistanceToRoute = null;
      return newLocation;
    }

    /**
     * Calculate and mutate Lat and Long of user Location
     */
    const closestPointOnRoutes = this.getClosestPointsOnRoutes(newLocation);
    if (!closestPointOnRoutes) {
      // no closest point could be found. There is a route, but we are not on the same Z level as where the route is.
      this.latestDistanceToRoute = Infinity;
      return newLocation;
    }

    const routingConfig = this.mapConfig.routing;
    const boundaryDistance = routingConfig.snap_boundary;
    this.latestDistanceToRoute = closestPointOnRoutes.distance;

    if (
      this.latestDistanceToRoute === undefined ||
      this.latestDistanceToRoute > boundaryDistance
    ) {
      // we've encoutered a instance where we are outside the snap boundary
      // and we should let the re-route mechansim kick in. returning the same, non-snapped input position.
      return newLocation;
    }

    const mutatedUserLocation = closestPointOnRoutes.location;
    mutatedUserLocation.setIsFromSnapManager(true);

    /**
     * Calculate route progression
     */
    this.latestProgessDescriptor =
      this.getRouteProgressInformation(closestPointOnRoutes);
    if (this.latestProgessDescriptor) {
      this.routingCompletionManager.handleUserProgress(
        this.latestProgessDescriptor.percentage
      );
      this.emit(EventTypes.ROUTE_PROGRESS_UPDATE, this.latestProgessDescriptor);
    }

    if (!routingConfig.set_heading_from_route) return mutatedUserLocation;

    /**
     * Calculate and mutate heading based on route geometries ONLY when config option is set.
     */
    const surroundingMergedRouteVertices =
      this.getSurroundingVerticesOnRoute(newLocation);
    const { previous, next } = surroundingMergedRouteVertices;

    let heading = bearing(previous, next);
    if (heading < 0) heading += 360;

    mutatedUserLocation.setHeading(heading);

    const headingValue = (heading * Math.PI) / 180;
    if (Mobile.isAndroidWebview()) {
      Mobile.getGlobalAndroid()!.setRouteHeading(headingValue);
    } else if (Mobile.isIOSLivingMapWebView()) {
      Mobile.sendMessageToIOS({
        eventName: "setRouteHeading",
        parameters: {
          routeHeading: headingValue,
        },
      });
    }

    return mutatedUserLocation;
  }

  private getRouteProgressInformation(
    snappedResult: any
  ): RouteProgressDescriptor | null {
    if (!snappedResult) return null;

    // Get redux state
    const state = store.getState();
    const { routing } = state;

    const { location, index, multiIndex } = snappedResult;
    const floor = this.resolveFloorConfig(location);
    if (floor === null) throw new Error("User floor is not set");

    const totalRouteSequences = getHighestRouteSequenceNumber(state);
    if (!totalRouteSequences) return null;

    const routeGeomtries = routing.route.parsed;
    const geomForFloor = routeGeomtries?.find(
      (g: any) => g.floorId === floor.id
    );

    if (!geomForFloor) throw new Error("No geometrys for floor");

    const routeNodeForIndex = geomForFloor.data[multiIndex][index] as any;
    const currentSequence = routeNodeForIndex.properties.sequence;

    return {
      percentage: (currentSequence / totalRouteSequences) * 100,
      timeLeft: routeNodeForIndex.properties.costTime,
    };
  }

  /**
   * This will return the previous and next route node on the route given your (snapped) user location.
   *
   * @param  {LMUserLocation} userLocation
   * @returns number
   */
  private getSurroundingVerticesOnRoute(userLocation: LMUserLocation): {
    previous: number[];
    next: number[];
  } {
    const floor = this.resolveFloorConfig(userLocation);
    if (floor === null) throw new Error("User floor is not set");

    // Get redux state
    const state = store.getState();
    const { routing } = state;

    const route = routing.route.multiline;
    const routeForFloor = route.find(
      (geoms: any) => geoms.properties.floor_id === floor!.id
    );

    const closestPoint = this.getClosestPointsOnRoutes(userLocation);

    if (!closestPoint || closestPoint.index === undefined) {
      throw new Error(
        "Something has gone wrong with finding the closest vertex"
      );
    }

    const previousRouteVertexIndex = closestPoint.index;
    const nextRouteVertexIndex = previousRouteVertexIndex + 1;

    const routeCoords =
      routeForFloor.geometry.coordinates[closestPoint.multiIndex];

    // when we are smack bang at the EBD of the route node, we calculate the heading based on
    // (end - 2) ---towards--> (end - 1)
    if (nextRouteVertexIndex === routeCoords.length) {
      return {
        previous: routeCoords[routeCoords.length - 2],
        next: routeCoords[routeCoords.length - 1],
      };
    }

    const previousRouteVertex = routeCoords[previousRouteVertexIndex];
    const nextRouteVertex = routeCoords[nextRouteVertexIndex];

    // when we are smack bang at the START of the route node, we calculate the heading based on
    // (start) ---towards--> (start + 1)
    if (previousRouteVertexIndex === 0) {
      return {
        previous: routeCoords[previousRouteVertexIndex],
        next: nextRouteVertex,
      };
    }

    const headingAlongRoute = bearing(previousRouteVertex, nextRouteVertex);
    const userHeading = bearing(
      userLocation.getAsLngLatLike() as number[],
      nextRouteVertex
    );
    const headingDifference = Math.abs(headingAlongRoute - userHeading);

    const distanceFromPoint = distance(
      userLocation.getAsLngLatLike() as number[],
      closestPoint.location.getAsLngLatLike() as number[],
      { units: "meters" }
    );

    // If we are in fact looking backwards, we reverse the previous and next segment.
    const isHeadingDifferenceSubstantial =
      headingDifference > this.headingMaxDifferenceFromRoute(distanceFromPoint);
    if (
      isHeadingDifferenceSubstantial &&
      nextRouteVertex + 1 < routeCoords.length
    ) {
      return {
        previous: nextRouteVertex,
        next: routeCoords[nextRouteVertexIndex + 1],
      };
    }

    // because its valid multiline geometries, they can techincally be the same (line exapmple == AB -> BC)
    // here coord B is duplicated.
    // when this is not the case, were good just return, when however this is the case we would grab coord `C`.
    if (
      nextRouteVertex[0] !== previousRouteVertex[0] ||
      nextRouteVertex[1] !== previousRouteVertex[1]
    ) {
      return {
        previous: previousRouteVertex,
        next: nextRouteVertex,
      };
    }

    return {
      previous: previousRouteVertex,
      next: routeCoords[nextRouteVertexIndex + 1],
    };
  }

  private getClosestPointsOnRoutes(
    userLocation: LMUserLocation
  ): PointOnRouteDescriptor | null {
    const floor = this.resolveFloorConfig(userLocation);
    if (floor === null) throw new Error("User floor is not set");

    // Get redux state
    const state = store.getState();
    const { routing } = state;

    const route = routing.route.multiline;
    const routeForFloor = route.find(
      (geoms: any) => geoms.properties.floor_id === floor!.id
    );
    if (!routeForFloor) {
      // at this point, the route does not cover this floor so no closest point can be deduced.
      return null;
    }

    const userLngLat = userLocation.getAsLngLatLike() as number[];

    const closestPoint = nearestPointOnLine(routeForFloor, userLngLat);
    if (!closestPoint.geometry) {
      throw new Error("Error finding closest point on route geometry");
    }

    const closestPointAsUserLocation = new LMUserLocation(
      closestPoint.geometry.coordinates,
      {
        accuracy: userLocation.getAccuracy() || 0,
        heading: userLocation.getHeading() || 0,
        floor: userLocation.getFloor() || null,
      }
    );

    return {
      location: closestPointAsUserLocation,
      distance: closestPoint.properties.dist! * 1000,
      index: closestPoint.properties.index!,
      multiIndex: closestPoint.properties.multiIndex!,
    };
  }

  public getLatestDistanceToRoute(): number | null {
    return this.latestDistanceToRoute;
  }

  private resolveFloorConfig(location: LMUserLocation): Floor | null {
    return location.getFloor()
      ? location.getFloor()
      : this.floorControl
      ? this.floorControl.getActiveFloor()
      : null;
  }
}

export default SnapManager;

import type { RouteLocation } from "../components/Map/plugins/controls/routing-control";

export const stringifyRouteLocation = (location: RouteLocation): string => {
  const { coordinate } = location;

  // Currently we only want to send data for coordinates
  if (coordinate) {
    return `${coordinate.x}, ${coordinate.y}, ${coordinate.floor}`;
  }

  // otherwise pass through an empty string so the event still fires
  return "";
};

import { v4 as uuidV4 } from "uuid";

import {
  addNotification,
  ApiNotificationParams,
  removeNotification,
  setInteractiveMarkers,
  setStatusBar,
} from "@redux/slices/uiSlice";
import { store } from "@redux/store";
import { ApiAction, ApiEventTypes, ExtractActionPayload } from "../types";

type StatusBarData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.STATUS_BAR_UPDATE
>;

/**
 * Updates the status bar with the defined style/text
 *
 * @param data
 * @param eventId
 * @returns eventId
 */
export const statusBarUpdate = (data: StatusBarData, eventId: string) => {
  store.dispatch(
    setStatusBar({
      style: data.statusBarStyle || null,
      text: data.statusBarText || null,
    })
  );

  return eventId;
};

/**
 * Add a notification
 *
 * @param data
 * @returns the ID of the notification
 */
export const handleAddNotification = (data: ApiNotificationParams) => {
  const id = uuidV4();

  const notificationPayload = { ...data, id };

  store.dispatch(addNotification(notificationPayload));

  return id;
};

/**
 * Remove a notification
 *
 * @param id
 */
export const handleRemoveNotification = (id: string) => {
  store.dispatch(removeNotification(id));
};

export const enableInteractiveMarkers = () => {
  store.dispatch(setInteractiveMarkers(true));
};

export const disableInteractiveMarkers = () => {
  store.dispatch(setInteractiveMarkers(false));
};

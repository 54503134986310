import type LMUserLocation from "../routing/LMUserLocation";

export default class UserLocationHistory {
  private history: LMUserLocation[] = [];

  public addEntry(entry: LMUserLocation): void {
    this.history.push(entry);
  }

  public getLastUserLocation(): LMUserLocation {
    return this.history[this.history.length - 1];
  }

  public getFullUserLocationHistory(): LMUserLocation[] {
    return this.history;
  }
}

import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
} from "@livingmap/core-mapping";
import {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} from "@livingmap/core-mapping/dist/utils";
import circle from "@turf/circle";
import type { Feature, FeatureCollection, Geometry } from "geojson";
import mapboxgl, { SymbolLayout } from "mapbox-gl";

import type { GeofenceControl, RoutingControl } from "../";
import {
  Logger,
  getDefaultFloor,
  drawWalkingForDefaultConfigCircle,
} from "@utils";
import type LMUserLocation from "../routing/LMUserLocation";
import SnapManager from "../routing/snap-manager";
import { DirectionHandler } from "../routing/direction-handler";
import UserLocationService from "../location/user-location-service";
import UserAnimationManager from "../location/user-animation";
import UserLocationHistory from "../location/user-location-history";
import { PLUGIN_IDS, EventTypes } from "../types";

const EMPTY_DATA_SOURCE: FeatureCollection<Geometry> =
  createGeoJSONFeatureCollection([]);

const USER_LOCATION_BLUE_DOT_DIRECTION = "you-are-here-heading";
export const USER_LOCATION_LAYER_ID = "user-location-layer";
const USER_LOCATION_SOURCE_ID = `${USER_LOCATION_LAYER_ID}-source`;
const USER_LOCATION_ACCURACY_ID = `${USER_LOCATION_LAYER_ID}-accuracy`;

export default class UserLocationPlugin extends LivingMapPlugin {
  private isWalking = false;
  private lastKnownPosition: LMUserLocation | null = null;

  private userLocationService: UserLocationService;
  private followUser: {
    should: boolean;
    customFunction?: (pos?: any) => void;
  } = { should: false };

  private animationManager: UserAnimationManager;
  private geofenceControl?: GeofenceControl;
  private routingControl: RoutingControl | null = null;

  protected layerDelegate: LayerDelegate;
  private history: UserLocationHistory;

  constructor(id: string, LMMap: LivingMap, geofencePlugin?: GeofenceControl) {
    super(id, LMMap);
    this.geofenceControl = geofencePlugin;
    this.userLocationService = new UserLocationService();
    this.animationManager = new UserAnimationManager(
      this.handleAnimationLocation
    );
    this.history = new UserLocationHistory();
    this.layerDelegate = this.LMMap.getLayerDelegate();
    this.getUserLocationService = this.getUserLocationService.bind(this);
  }

  public activate(): void {
    this.createUserLocationLayer();
    this.createAccuracyCircleLayer();

    this.routingControl = this.LMMap.getPluginById<RoutingControl>(
      PLUGIN_IDS.ROUTING
    );
    this.userLocationService.on(
      EventTypes.SERVICE_LOCATION_UPDATE,
      this.handleLocationServiceUpdate
    );
  }

  public deactivate = () => {
    this.removeUserLocationLayer();
    this.removeAccuracyCircleLayer();
    this.userLocationService.removeListener(
      EventTypes.SERVICE_LOCATION_UPDATE,
      this.handleLocationServiceUpdate
    );
  };

  public setUserLocationLayerVisibility(visible: boolean): void {
    const value = visible ? "visible" : "none";
    this.layerDelegate.setLayoutProperty(
      USER_LOCATION_LAYER_ID,
      "visibility",
      value
    );
  }

  public createWalkingCirclesForCurrentLocation(): void {
    if (!this.lastKnownPosition) {
      Logger.info("No user location Known, so no walking circles created");
      return;
    }

    const mapInstance = this.LMMap.getMapboxMap();
    const bearing = mapInstance.getBearing();
    const location: any = this.lastKnownPosition.getAsLngLatLike();
    const lngLat = new mapboxgl.LngLat(location[0], location[1]);
    const floorId =
      this.lastKnownPosition.getFloorId() || getDefaultFloor()!.id;

    drawWalkingForDefaultConfigCircle(
      this.layerDelegate,
      lngLat,
      bearing,
      floorId
    );
  }

  public getFullUserLocationHistory(): LMUserLocation[] {
    return this.history.getFullUserLocationHistory();
  }

  private handleLocationServiceUpdate = (
    newLocation: LMUserLocation,
    error: Error
  ) => {
    if (error) {
      Logger.error(error);
      this.updateAccuracyCircleData(EMPTY_DATA_SOURCE);
      this.updateLocationData(EMPTY_DATA_SOURCE);
      return;
    }
    this.history.addEntry(newLocation);

    const headingFromSDK = newLocation.getHeading();

    if (this.routingControl) {
      const snappedLocation =
        SnapManager.getInstance().handleSnapToRoute(newLocation);
      if (!snappedLocation.isTheSamePositionAs(newLocation)) {
        newLocation.setIsFromSnapManager(true);
      }

      if (snappedLocation.getIsFromSnapManager() === true) {
        this.routingControl.setUserOnRouteFlag(true);
      } else {
        this.routingControl.setUserOnRouteFlag(false);
      }

      const showHeadingFromSDK =
        !this.isWalking &&
        headingFromSDK != null &&
        DirectionHandler.doesSDKHeadingCrossDifferenceThreshold(
          snappedLocation.getHeading()!,
          headingFromSDK
        );

      if (showHeadingFromSDK) {
        snappedLocation.setHeading(headingFromSDK!);
      }

      this.setLocation(snappedLocation);
      this.adjustMapViewportToUserLocation(snappedLocation);

      if (this.geofenceControl) {
        this.geofenceControl.handleUserLocationForDynamicGeofence(
          snappedLocation
        );
      }
    } else {
      this.setLocation(newLocation);
    }

    if (this.geofenceControl) {
      this.geofenceControl.handleUserLocationForGeofences(newLocation);
    }

    // Move user location storage to seperate store.
    this.LMMap.emit(EventTypes.LOCATION_UPDATE_EVENT, newLocation);
  };

  private adjustMapViewportToUserLocation(location: LMUserLocation): void {
    if (!this.followUser.should) return;

    if (this.followUser.customFunction) {
      this.followUser.customFunction(this.lastKnownPosition);
    } else {
      const heading = location.getHeading();
      const mapInstance = this.LMMap.getMapboxMap();
      mapInstance.easeTo({
        center: location.getAsLngLatLike(),
        offset: [0, 20],
        zoom: 20,
        padding: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        },
        bearing:
          typeof heading === "number" ? heading : mapInstance.getBearing(),
      } as any);
    }
  }

  private setLocation(newLocation: LMUserLocation): void {
    if (!this.lastKnownPosition) {
      this.handleAnimationLocation(newLocation);
    } else {
      this.animationManager.start(newLocation, this.lastKnownPosition);
    }
    this.lastKnownPosition = newLocation;
  }

  private handleAnimationLocation = (lmUser: LMUserLocation) => {
    this.updateLocationSource(lmUser);

    // update accuracy circle with the user location
    if (lmUser.getAccuracy()) {
      this.updateAccuracySource(lmUser);
    } else {
      this.updateAccuracyCircleData(EMPTY_DATA_SOURCE);
    }
  };

  private updateLocationSource(location: LMUserLocation): void {
    const userLocationGeometry = createGeoJSONGeometryPoint(
      location.getAsLngLatLike() as number[]
    );
    const userLocationFeature = createGeoJSONFeature(
      {
        heading: location.getHeading() || 0,
        accuracy: location.getAccuracy() || 0,
        floor_id: location.getFloorId() || undefined,
      },
      userLocationGeometry
    );

    const userLocationFeatureCollection = createGeoJSONFeatureCollection([
      userLocationFeature,
    ]);
    this.updateLocationData(userLocationFeatureCollection);
  }

  private updateAccuracySource(location: LMUserLocation): void {
    const locationArray = location.getAsLngLatLike() as number[];
    const accuracy = location.getAccuracy();

    const circleConfig = {
      steps: 250,
      units: "kilometers",
      properties: { floor_id: location.getFloorId() || undefined },
    } as Record<string, unknown>;
    const feature = circle(
      locationArray,
      accuracy! / 1000,
      circleConfig
    ) as Feature<Geometry>;
    const accuracyCircleFeatureCollection = createGeoJSONFeatureCollection([
      feature,
    ]);
    this.updateAccuracyCircleData(accuracyCircleFeatureCollection);
  }

  private updateAccuracyCircleData(newData: FeatureCollection<Geometry>): void {
    const sourceProxy = this.layerDelegate.getSourceProxy(
      USER_LOCATION_ACCURACY_ID
    );
    if (sourceProxy) {
      sourceProxy.setData(newData);
    }
  }

  private updateLocationData(newData: FeatureCollection<Geometry>): void {
    const sourceProxy = this.layerDelegate.getSourceProxy(
      USER_LOCATION_SOURCE_ID
    );
    if (sourceProxy) {
      sourceProxy.setData(newData);
    }
  }

  private getScreenModeLayout(): SymbolLayout {
    return {
      "icon-image": USER_LOCATION_BLUE_DOT_DIRECTION,
      "icon-offset": [0, 0],
      "icon-allow-overlap": true,
      "icon-rotate": ["get", "heading"],
      "icon-rotation-alignment": "map",
    };
  }

  public createUserLocationLayer(): void {
    const userLocationLayerDoesNotExist = this.layerNotDefined(
      USER_LOCATION_LAYER_ID
    );
    if (userLocationLayerDoesNotExist) {
      this.layerDelegate.addSource(USER_LOCATION_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    }

    const doesLayerNotExist = this.layerNotDefined(USER_LOCATION_LAYER_ID);
    if (doesLayerNotExist) {
      const layerLayout = this.getScreenModeLayout();

      this.layerDelegate.addLayer({
        id: USER_LOCATION_LAYER_ID,
        type: "symbol",
        source: USER_LOCATION_SOURCE_ID,
        layout: layerLayout,
      });
    }
  }

  private removeUserLocationLayer() {
    this.layerDelegate.removeLayer(USER_LOCATION_LAYER_ID);
    this.layerDelegate.removeSource(USER_LOCATION_SOURCE_ID);
  }

  public updateUserLocationIcon(): void {
    const layerLayout = this.getScreenModeLayout();
    this.layerDelegate.setLayoutProperties(USER_LOCATION_LAYER_ID, layerLayout);
  }

  public createAccuracyCircleLayer(): void {
    this.layerDelegate.addSource(USER_LOCATION_ACCURACY_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    // if we need a wider than 1px width circle we'll need another layer:
    // https://github.com/mapbox/mapbox-gl-js/issues/3018
    const id = `${USER_LOCATION_LAYER_ID}-accuracy`;
    if (this.layerNotDefined(id)) {
      this.layerDelegate.addLayer(
        {
          id,
          type: "fill",
          source: USER_LOCATION_ACCURACY_ID,
          layout: {},
          paint: {
            "fill-color": "rgb(80,183,255)",
            "fill-opacity": 0.33,
            "fill-outline-color": "rgb(80,183,255)",
          },
        },
        USER_LOCATION_LAYER_ID
      );
    }
  }

  private removeAccuracyCircleLayer() {
    this.layerDelegate.removeLayer(`${USER_LOCATION_LAYER_ID}-accuracy`);
    this.layerDelegate.removeSource(USER_LOCATION_ACCURACY_ID);
  }

  public layerNotDefined = (layerId: string) => {
    const layer = this.LMMap.getLayerDelegate().getLayer(layerId);
    return !layer;
  };

  // Returns a Position
  public getUserLocation = () => {
    return this.lastKnownPosition;
  };

  public getUserLocationService(): UserLocationService {
    return this.userLocationService;
  }

  public centerOnUserLocation(options: any, useHeading: boolean): void {
    if (!this.lastKnownPosition) return;

    const formattedOptions = {
      center: this.lastKnownPosition.getAsLngLatLike(),
      ...options,
    };

    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.easeTo(formattedOptions);
  }

  public setFollowingUser(
    toggle: boolean,
    optFollowUserFunction?: (pos?: any) => void
  ): void {
    this.followUser = {
      should: toggle,
      customFunction: optFollowUserFunction,
    };

    const doesLayerNOTExist = this.layerNotDefined(USER_LOCATION_LAYER_ID);
    if (doesLayerNOTExist) {
      this.layerDelegate.addLayer({
        id: USER_LOCATION_LAYER_ID,
        type: "symbol",
        source: USER_LOCATION_SOURCE_ID,
        layout: {
          "icon-image": USER_LOCATION_BLUE_DOT_DIRECTION,
          "icon-rotate": ["get", "heading"],
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          "icon-offset": [0, 0],
        },
      });
    }
  }

  public setUserIsWalking(isWalking: boolean): void {
    this.isWalking = isWalking;
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AlertType } from "@api/types";
import { FloorDirection } from "@components/Map/plugins/controls/floor-control";

export enum NotificationTypes {
  INFO = "INFO",
  DEBUG = "DEBUG",
  WARNING = "WARNING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum NotificationIcons {
  FLIGHT_INFORMATION = "FLIGHT_INFORMATION",
  NO_INTERNET = "NO_INTERNET",
  PASS = "PASS",
  WARNING = "WARNING",
}

export type ApiNotificationParams = {
  type: NotificationTypes;
  text: string;
  icon: NotificationIcons;
  colour?: string;
  textColour?: string;
  id: string;
};

type StatusBarData = {
  style: AlertType | null;
  text: string | null;
};

export interface FloorDirectionOptions {
  direction: FloorDirection;
  destinationFloor: string;
}

export interface ExitFloorChangeOptions {
  destinationFloor: string;
}

export type DialogNotificationOptions = {
  floorChangingAlert: boolean;
  routeCompletedAlert: boolean;
  floorDirectionAlert: FloorDirectionOptions | null;
  exitFloorChangeAlert: ExitFloorChangeOptions | null;
};

interface CTABannerOptions {
  title: string | null;
  icon: NotificationIcons | null;
  imageUrl: string | null;
  isMinimized: boolean;
}

type InitialState = {
  mapControls: {
    interactiveMarkers: boolean;
  };
  statusBar: StatusBarData;
  notifications: ApiNotificationParams[];
  modals: string[];
  dialogNotifications: DialogNotificationOptions;
  ctaBanner: CTABannerOptions;
};

const initialState: InitialState = {
  mapControls: {
    interactiveMarkers: true,
  },
  statusBar: {
    style: null,
    text: null,
  },
  notifications: [],
  modals: [],
  dialogNotifications: {
    routeCompletedAlert: false,
    floorChangingAlert: false,
    floorDirectionAlert: null,
    exitFloorChangeAlert: null,
  },
  ctaBanner: {
    title: null,
    icon: null,
    imageUrl: null,
    isMinimized: false,
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setInteractiveMarkers: (state, action: PayloadAction<boolean>) => {
      state.mapControls.interactiveMarkers = action.payload;
    },
    setStatusBar: (state, action: PayloadAction<StatusBarData>) => {
      // To ensure these values are never null once they've been initially populated
      state.statusBar.style = action.payload.style || state.statusBar.style;
      state.statusBar.text = action.payload.text || state.statusBar.text;
    },
    addNotification: (state, action: PayloadAction<ApiNotificationParams>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    addModal: (state, action: PayloadAction<string>) => {
      if (state.modals.includes(action.payload)) return;

      state.modals.push(action.payload);
    },
    removeModal: (state, action: PayloadAction<string>) => {
      state.modals = state.modals.filter((modal) => modal !== action.payload);
    },
    setDialogNotifications: (
      state,
      action: PayloadAction<Partial<DialogNotificationOptions>>
    ) => {
      state.dialogNotifications = {
        ...state.dialogNotifications,
        ...action.payload,
      };
    },
    setCTABanner: (state, action: PayloadAction<Partial<CTABannerOptions>>) => {
      state.ctaBanner = {
        ...state.ctaBanner,
        ...action.payload,
      };
    },
  },
});

export const {
  setInteractiveMarkers,
  setStatusBar,
  addNotification,
  removeNotification,
  addModal,
  removeModal,
  setDialogNotifications,
  setCTABanner,
} = uiSlice.actions;

export default uiSlice.reducer;

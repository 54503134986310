import type LivingMap from "@livingmap/core-mapping";
import { v4 as uuidV4 } from "uuid";
import GitInfo from "react-git-info/macro";

import packageJSON from "../../package.json";
import { Logger } from "@utils";
import Mobile from "@mobile";
import { CustomEventAPICallbackFormat } from "./custom-api-callback-format";
import {
  statusBarUpdate,
  handleSetMapDownloadProgress,
  handleAddNotification,
  handleRemoveNotification,
  enableInteractiveMarkers,
  disableInteractiveMarkers,
  Markers,
  Location,
  Utility,
  Routing,
  modalManager,
} from "./";
import { BarometerValue } from "./location/location";

import {
  ExtractActionPayload,
  ApiAction,
  AlertType,
  ApiEventTypes,
  APIInterface,
  ScreenIds,
  DebugInfo,
} from "./types";
import { RouteStatusData } from "@components/Map/plugins/types";
import { NotificationIcons, NotificationTypes } from "@redux/slices/uiSlice";
import { push } from "redux-first-history";
import { store } from "@redux/store";

const TAG = "API:";

export default class CustomEventsAPI implements APIInterface {
  private readonly location: Location;
  private readonly markers: Markers;
  private readonly utility: Utility;
  private readonly routing: Routing;

  constructor(LMMap: LivingMap) {
    this.location = new Location(LMMap);
    this.markers = new Markers(LMMap);
    this.utility = new Utility(LMMap);
    this.routing = new Routing(LMMap);
  }

  public getInstance() {
    return {
      fireEvent: this.handleFiredEvent.bind(this),
      getCallbackFunction: this.getCallbackFunction.bind(this),
    };
  }

  /**
   * Translates an Event String Id into a callback function. Allows for two-way communcation.
   * Currently only support the LivingMap Android JavascriptInterface & LivingMap iOS WKWebview Javascript Bridge
   * @return {function} Actual callback function.
   * @example
   *     getCallbackFunctionForUpstreamId('EVENT_ID_TO_CAPTURE')
   */
  private getCallbackFunction =
    (upstreamSDKCAllbackId: string) =>
    (payload: CustomEventAPICallbackFormat) => {
      Mobile.sharedSendMessageEmitter(upstreamSDKCAllbackId, payload);
    };

  private handleFiredEvent(
    type: ApiEventTypes,
    args: any,
    callback?: (res: CustomEventAPICallbackFormat) => void
  ):
    | RouteStatusData
    | mapboxgl.LngLatLike
    | DebugInfo
    | string
    | number
    | null {
    const eventId = uuidV4();

    switch (type) {
      case ApiEventTypes.SHOW_IMAGE_MODAL: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SHOW_IMAGE_MODAL
        > = args;

        const requiredProperties = ["imageUrl", "title", "icon"];

        if (!data?.title || !data?.imageUrl || !data?.icon) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            ${requiredProperties.reduce((final, current) => {
              if (!data || !(current in data)) {
                return `${final} "${current}"\n`;
              }

              return final;
            }, "\n")}
          `);

          return null;
        }

        modalManager.showImageModal(data.imageUrl, data.title, data.icon);
        return null;
      }

      case ApiEventTypes.GET_DEBUG_INFO: {
        const { commit, branch } = GitInfo();

        return {
          version: packageJSON.version,
          commitHash: commit.hash,
          branch: branch as string,
          environment: process.env.REACT_APP_ENVIRONMENT as string,
        };
      }

      case ApiEventTypes.NAVIGATE_TO_SCREEN: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.NAVIGATE_TO_SCREEN
        > = args;

        const screenIdValues = Object.values(ScreenIds);

        if (!data || !screenIdValues.includes(data)) {
          Logger.warn(`${TAG} Incorrect usage. Valid options are:
          ${screenIdValues.reduce(
            (final, current) => `${final} "${current}"\n`,
            "\n"
          )}
        `);
          return null;
        }

        store.dispatch(push(data));

        return null;
      }

      case ApiEventTypes.CLEAR_ROUTE: {
        return this.routing.clearRoute(eventId);
      }

      case ApiEventTypes.SET_MAP_DOWNLOAD_PROGRESS: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_MAP_DOWNLOAD_PROGRESS
        > = args;

        if (!data || typeof data !== "number") {
          Logger.warn(`${TAG} Incorrect usage.`);
          return null;
        }

        handleSetMapDownloadProgress(data);

        return null;
      }

      case ApiEventTypes.SHOW_GATE_SELECTION: {
        Logger.warn(
          `${TAG} "${ApiEventTypes.SHOW_GATE_SELECTION}" is currently deprecated.`
        );

        return null;
      }

      case ApiEventTypes.SHOW_DECISION_MODAL: {
        const requiredProperties = ["title", "option1Text", "option2Text"];

        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SHOW_DECISION_MODAL
        > = args;

        if (!data?.title || !data?.option1Text || !data?.option2Text) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            ${requiredProperties.reduce((final, current) => {
              if (!data || !(current in data)) {
                return `${final} "${current}"\n`;
              }

              return final;
            }, "\n")}
          `);

          return null;
        }

        return modalManager.showDecisionModal(data, eventId);
      }

      case ApiEventTypes.STATUS_BAR_UPDATE: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.STATUS_BAR_UPDATE
        > = args;

        if (!data?.statusBarStyle && !data?.statusBarText) {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide either "statusBarStyle" or "statusBarText"`
          );

          return null;
        }

        const alertTypeValues = Object.values(AlertType);

        if (
          data?.statusBarStyle &&
          alertTypeValues.indexOf(data.statusBarStyle) === -1
        ) {
          Logger.warn(
            `${TAG} statusBarStyle can only be one of:
              ${alertTypeValues.reduce(
                (final, current) => `${final} "${current}"\n`,
                "\n"
              )}
            `
          );

          return null;
        }

        return statusBarUpdate(data, eventId);
      }

      case ApiEventTypes.SHOW_BIG_CONFIRMATION_ALERT: {
        const requiredProperties = ["header", "body", "variable"];

        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SHOW_BIG_CONFIRMATION_ALERT
        > = args;

        if (!data?.body || !data?.header || !data?.variable) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            ${requiredProperties.reduce((final, current) => {
              if (!data || !(current in data)) {
                return `${final} "${current}"\n`;
              }

              return final;
            }, "\n")}
          `);

          return null;
        }

        return modalManager.showBigConfirmationAlert(data, eventId);
      }

      case ApiEventTypes.SHOW_BIG_LOADING_ALERT: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SHOW_BIG_LOADING_ALERT
        > = args;

        if (!data?.message) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            "message"
          `);

          return null;
        }

        if (typeof data.message !== "string") {
          Logger.warn(`${TAG} Incorrect usage. "message" must be a string.`);

          return null;
        }

        return modalManager.showBigLoadingAlert(data, eventId);
      }

      case ApiEventTypes.SHOW_FATAL_ERROR_ALERT: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SHOW_FATAL_ERROR_ALERT
        > = args;

        if (!data?.exitCallback) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            "exitCallback"
          `);

          return null;
        }

        return modalManager.showFatalError(data, eventId);
      }

      case ApiEventTypes.SHOW_DETERMINING_POSITION_MODAL: {
        modalManager.showDeterminingPositionModal();
        return null;
      }

      case ApiEventTypes.REQUEST_ROUTE_JOURNEY: {
        const requiredProperties = ["origin", "destination"];

        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.REQUEST_ROUTE_JOURNEY
        > = args;

        if (!data?.origin || !data?.destination) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            ${requiredProperties.reduce((final, current) => {
              if (!data || !(current in data)) {
                return `${final} "${current}"\n`;
              }

              return final;
            }, "\n")}
          `);

          return null;
        }

        return this.routing.requestRouteJourney(data, eventId, callback);
      }

      case ApiEventTypes.REQUEST_FILTERED_ROUTE_JOURNEY: {
        Logger.warn(
          `${TAG} "${ApiEventTypes.REQUEST_FILTERED_ROUTE_JOURNEY}" is currently deprecated.`
        );

        return null;
      }

      case ApiEventTypes.GET_ROUTE_STATUS: {
        return this.routing.getRouteStatus();
      }

      case ApiEventTypes.SET_USER_LOCATION: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_USER_LOCATION
        > = args;

        if (!data?.newUserLocation) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            "newUserLocation"
          `);

          return null;
        }

        this.location.setUserLocation(data.newUserLocation, data.options);

        return null;
      }

      case ApiEventTypes.SET_USER_HEADING: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_USER_HEADING
        > = args;

        if (!data) {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide a number to set the heading.`
          );

          return null;
        }

        this.location.setUserHeading(data);

        return null;
      }

      case ApiEventTypes.GET_DISPLAYED_FLOOR: {
        return this.location.getDisplayedFloor();
      }

      case ApiEventTypes.SET_MAP_CENTER: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_MAP_CENTER
        > = args;

        if (!data) {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide coordinates to set the map center.`
          );

          return null;
        }

        this.location.setCenter(data);

        return null;
      }

      case ApiEventTypes.GET_MAP_CENTER: {
        return this.location.getCenter() || null;
      }

      case ApiEventTypes.SET_USER_IS_WALKING: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_USER_IS_WALKING
        > = args;

        if (!data && data !== false) {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide a boolean to confirm if the user is walking.`
          );

          return null;
        }

        this.location.setUserIsWalking(data);

        return null;
      }

      case ApiEventTypes.SET_BAROMETER_VALUE: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_BAROMETER_VALUE
        > = args;

        const barometerValues = Object.values(BarometerValue);

        if (!data || !barometerValues.includes(data)) {
          Logger.warn(`${TAG} Incorrect usage. Valid options are:
          ${barometerValues.reduce(
            (final, current) => `${final} "${current}"\n`,
            "\n"
          )}
        `);

          return null;
        }

        this.location.setBarometerValue(data);

        return null;
      }

      case ApiEventTypes.CREATE_MARKER: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.CREATE_MARKER
        > = args;

        if (!data?.coordinates) {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide a "coordinates" value.`
          );

          return null;
        }

        return this.markers.create(
          data.coordinates,
          data.options,
          data.markerId
        );
      }

      case ApiEventTypes.CLEAR_ALL_MARKERS: {
        this.markers.clearAll();

        return null;
      }

      case ApiEventTypes.SET_MAP_STYLE: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_MAP_STYLE
        > = args;

        this.utility.setMapStyle(data);

        return null;
      }

      case ApiEventTypes.SET_STEP_FREE_ROUTING: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_STEP_FREE_ROUTING
        > = args;

        if ((!data && data !== false) || typeof data !== "boolean") {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide a boolean value.`
          );

          return null;
        }

        this.routing.setStepFreeRouting(data);

        return null;
      }

      case ApiEventTypes.ADD_NOTIFICATION: {
        const requiredProperties = ["type", "text", "icon"];
        const notificationTypeValues = Object.values(NotificationTypes);
        const notificationIconValues = Object.values(NotificationIcons);

        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.ADD_NOTIFICATION
        > = args;

        if (!data?.type || !data?.text || !data?.icon) {
          Logger.warn(`${TAG} Incorrect usage. Missing the following properties:
            ${requiredProperties.reduce((final, current) => {
              if (!data || !(current in data)) {
                return `${final} "${current}"\n`;
              }

              return final;
            }, "\n")}
          `);

          return null;
        }

        if (!notificationTypeValues.includes(data?.type)) {
          Logger.warn(
            `${TAG} "type" can only be one of:
              ${notificationTypeValues.reduce(
                (final, current) => `${final} "${current}"\n`,
                "\n"
              )}
            `
          );

          return null;
        }

        if (!notificationIconValues.includes(data?.icon)) {
          Logger.warn(
            `${TAG} "icon" can only be one of:
              ${notificationIconValues.reduce(
                (final, current) => `${final} "${current}"\n`,
                "\n"
              )}
            `
          );

          return null;
        }

        return handleAddNotification(data);
      }

      case ApiEventTypes.REMOVE_NOTIFICATION: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.REMOVE_NOTIFICATION
        > = args;

        if (!data) {
          Logger.warn(
            `${TAG} Incorrect usage. Please provide an ID for the notification to remove.`
          );

          return null;
        }

        handleRemoveNotification(data);

        return null;
      }

      case ApiEventTypes.ENABLE_INTERACTIVE_MARKERS: {
        enableInteractiveMarkers();
        return null;
      }

      case ApiEventTypes.DISABLE_INTERACTIVE_MARKERS: {
        disableInteractiveMarkers();
        return null;
      }

      case ApiEventTypes.SET_IGNORE_ACTIVE_FLOOR_CHANGE: {
        const data: ExtractActionPayload<
          ApiAction,
          ApiEventTypes.SET_IGNORE_ACTIVE_FLOOR_CHANGE
        > = args;

        if (!!data) {
          this.location.enableIgnoreActiveFloorUpdates();
        } else {
          this.location.disableIgnoreActiveFloorUpdates();
        }

        return null;
      }

      default: {
        Logger.warn(`${TAG} Incorrect usage. Valid events are:
          ${Object.keys(ApiEventTypes)
            .sort()
            .reduce((final, current) => `${final} "${current}"\n`, "\n")}
        `);

        return null;
      }
    }
  }
}

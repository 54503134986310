import type { RouteLocation } from "@components/Map/plugins/controls/routing-control";

export const formatRoutingPostData = (
  to: RouteLocation,
  from: RouteLocation,
  options: Record<string, unknown> | null
) => {
  return JSON.stringify({
    project: process.env.REACT_APP_PROJECT_NAME,
    to: to.coordinate
      ? {
          latitude: to.coordinate.y,
          longitude: to.coordinate.x,
          floorId: to.coordinate.floor!.id,
        }
      : to,
    from: from.coordinate
      ? {
          latitude: from.coordinate.y,
          longitude: from.coordinate.x,
          floorId: from.coordinate.floor!.id,
        }
      : from,
    options,
  });
};

import LivingMap from "@livingmap/core-mapping";

import CustomEventsAPI from "./custom-events";
import { setReady } from "./functions/ready-state-manager";

export { handleSetMapDownloadProgress } from "./functions/set-map-download-progress";
export {
  statusBarUpdate,
  enableInteractiveMarkers,
  disableInteractiveMarkers,
  handleAddNotification,
  handleRemoveNotification,
} from "./functions/ui";

export { Location } from "./location/location";
export { Markers } from "./markers/markers";
export { Utility } from "./utility/utility";
export { Routing } from "./routing/routing";
export { default as modalManager } from "./modals/modal-manager";

/**
 * enables and intializes all of our external API endpoints.
 * These endpoints are used to talk and connect with our Living Map SDKS.
 * @param  {{map:LivingMap}} {map} Living Map object
 * @internal
 */
export const enableExternalAccessAPI = ({ map }: { map: LivingMap }) => {
  window.api = {
    ...new CustomEventsAPI(map).getInstance(),
  };
  setReady("api");
};

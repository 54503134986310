interface ComponentProps {
  label: string;
  onClick?: () => void;
}

const RouteLabelPopupContent = (props: ComponentProps) => {
  return (
    <h4 onClick={props.onClick} style={{ margin: 0 }}>
      {props.label}
    </h4>
  );
};

export default function renderRouteLabelPopup(
  label: string,
  onClick?: () => void
): JSX.Element {
  return <RouteLabelPopupContent label={label} onClick={onClick} />;
}

import Spinner from "@components/Spinner/Spinner";
import styles from "./LoadingMap.module.css";
import ProjectLogo from "@components/ProjectLogo/ProjectLogo";

const LoadingMap = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <Spinner type="MoonLoader" size={50} />
        <p>Loading Map</p>
      </div>
      <ProjectLogo />
    </div>
  );
};

export default LoadingMap;

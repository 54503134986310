// Keep these types in a separate file so the documentation generator only creates a reference to "ApiEventTypes"
export { ApiEventTypes } from "./events";
export type { ApiAction, ExtractActionPayload } from "./events";

export enum AlertType {
  INFO = "info",
  WARN = "warn",
  DANGER = "danger",
}

/**
 * Interface every API sub module should adhere to
 */
export interface APIInterface {
  /**
   * should return object with keys being the functions you want to expose to the
   * outside world. Make sure to bind them with .bind(this).
   * @returns object
   */
  getInstance(): Record<string, unknown>;
}

export interface APIRouteLocation {
  coordinate?: {
    x: number;
    y: number;
    floorId: number;
  };
  featureId?: number;
  type?: string;
}

export enum ScreenIds {
  MAP = "map",
  NAVIGATION = "navigation",
  DOWNLOADING_MAP = "downloading-map",
  NO_INTERNET_CONNECTION = "no-internet-connection",
  DOWNLOAD_FAILED = "download-failed",
}

export interface DebugInfo {
  branch: string;
  version: string;
  commitHash: string;
  environment: string;
}

import type { RouteCompletionConfig } from "../types";

export default class CompletionProgressPoint {
  private name: string;
  private completed: number;
  private callback: () => void;
  private activated: boolean;

  constructor(config: RouteCompletionConfig) {
    this.name = config.name;
    this.completed = config.completed;
    this.callback = config.callback;
    this.activated = false;
  }

  public checkUserProgression(userProgression: number): void {
    if (!this.activated && userProgression >= this.completed) {
      console.debug(`Route Completion inside CALLBACK, ${this.name}`);
      this.activated = true;
      this.callback();
    }
  }
}

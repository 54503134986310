const defaultGeolocationProvider: Geolocation = navigator.geolocation || {
  clearWatch: () => {
    return;
  },
  getCurrentPosition: (_, _1, _2) => {
    return;
  },
  watchPosition: (_, _1, _2) => 0,
};

class NavigatorLocationProvider implements Geolocation {
  public clearWatch(watchId: number): void {
    return defaultGeolocationProvider.clearWatch(watchId);
  }

  public getCurrentPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback,
    options?: PositionOptions
  ): void {
    return defaultGeolocationProvider.getCurrentPosition(
      successCallback,
      errorCallback,
      options
    );
  }

  public watchPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback,
    options?: PositionOptions
  ): number {
    return defaultGeolocationProvider.watchPosition(
      successCallback,
      errorCallback,
      options
    );
  }
}

export default NavigatorLocationProvider;

import type { FeatureCollection } from "geojson";

import { Floor } from "@redux/services/types";
import { RouteLocation } from "../controls/routing-control";

export interface Coordinate {
  x: number;
  y: number;
  floor?: Floor | null;
}

export enum PLUGIN_IDS {
  INTERACTION = "INTERACTION",
  ROUTING = "ROUTING",
  SEARCH = "SEARCH",
  GEOFENCE = "GEOFENCE",
  USER_LOCATION = "USER_LOCATION",
  MARKER = "MARKER",
  LIVEREPORTING = "LIVEREPORTING",
  DEBUG = "DEBUG",
  CLUSTERED_PIN = "CLUSTERED_PIN",
  FLOOR = "FLOOR",
  CATEGORIES = "CATEGORIES",
  DYNAMIC_DATA = "DYNAMIC_DATA",
  REGION = "REGION",
}

export enum EventTypes {
  FEATURE_CLICKED = "FEATURE_CLICKED",
  FEATURE_DESELECTED = "FEATURE_DESELECTED",
  ROUTE_OVERVIEW_UPDATE = "ROUTE_OVERVIEW_UPDATE",
  ROUTE_DESTINATION_UPDATE = "ROUTE_DESTINATION_UPDATE",
  ROUTE_PROGRESS_UPDATE = "ROUTE_PROGRESS_UPDATE",
  GEOFENCE_EXIT = "GEOFENCE_EXIT",
  GEOFENCE_ENTRANCE = "GEOFENCE_ENTRANCE",
  FLOOR_CHANGED = "FLOOR_CHANGED",
  LOCATION_UPDATE_EVENT = "LOCATION_UPDATE",
  SERVICE_LOCATION_UPDATE = "SERVICE_LOCATION_UPDATE",
  ROUTE_DISPLAYED = "ROUTE_DISPLAYED",
  CONNECTIVITY_STATUS_CHANGED = "CONNECTIVITY_STATUS_CHANGED",
}

export enum SourceIds {
  LM_SOURCE_ID = "lm",
  LM_SOURCE_LAYER_INDOOR_ID = "indoor",
  LM_SOURCE_LAYER_OUTDOOR_ID = "outdoor",
  CLUSTER_SOURCE_ID = "pin",
  MMS_PIN_SOURCE_ID = "pin_mms",
  MMS_PIN_SOURCE_LAYER_ID = "pin_mms",
  SELECTED_FEATURE_SOURCE_ID = "selected_feature",
  LIVE_LOCATION_SOURCE_ID = "pin_user",
}

export enum LayerIds {
  SELECTED_FEATURE_LAYER = "search-highlight_pin",
  SEARCH_PIN_LAYER = "search-search_pin",
  SEARCH_CLUSTER_LAYER = "search-search_badge_cluster",
  LIVE_LOCATION_LAYER_ID = "search-pin_user",
  UNDERGROUND_MASK_LAYER = "venue-underground_mask",
}

export enum StateType {
  SELECTED = "selected",
  HOVER = "hover",
  HIGHLIGHT = "highlight",
}

export interface RoutingConfig {
  visualisation_type: "MULTI_FLOOR_COLOR_DIFF" | "MULTI_FLOOR_DEFAULT_HIDDEN";
  multimodal: boolean;
  snap_boundary: number;
  reroute_enabled: boolean;
  attempt_reroute_count?: number;
  set_heading_from_route: boolean;
  active_navigation_zoom?: number;
  overview_mode_base_floor?: Floor;
  speed?: number;
}

export interface RequestRouteOptions {
  via?: RouteLocation[];
  destinationName?: string;
  destinationAreaName?: string;
  destinationExpiryTime?: string;
  multimodal?: boolean;
  routeModifier?: string;
  speed?: number;
  callback?: (obj: { data: any; error: null | string }) => void;
  shouldMapPanToRoute?: boolean;
  routeLabels?: boolean;
}

export enum RouteMode {
  NONE = "none",
  ROUTE = "route",
  REROUTE = "reroute",
}

export enum RoutingOptions {
  BASE_ROUTE_COLOUR = "#01B9E6",
  LAYER_MIDDLE_ID = "routing-middle-layer",
  LAYER_START_ID = "routing-start-layer",
  LAYER_END_ID = "routing-end-layer",
  ROUTING_CURRENT_FLOOR_LAYER_ID = "routing-layer-current-floor",
  ROUTING_OTHER_FLOOR_LAYER_ID = "routing-layer-other-floor",
  ROUTE_SOURCE_ID = "routing-middle-layer-source",
  ROUTE_START_SOURCE_ID = "routing-start-layer-source",
  ROUTE_END_SOURCE_ID = "routing-end-layer-source",
}

export interface RoutingOverviewData {
  totalLength: number;
  totalTime?: number;
}

export interface RoutingDestinationData {
  destinationName: string;
  destinationExpiryTime: string;
  destinationAreaName: string;
}

export interface RoutingDataAndMetaData {
  route: any;
  overview: any;
  dynamicGeofences: any;
  fromCoordinates: Coordinate | null;
  toCoordinates: Coordinate | null;
  routeLabels: FeatureCollection | null;
}

export interface RouteCompletionConfig {
  name: string;
  completed: number;
  callback: () => void;
}

export interface RouteProgressDescriptor {
  percentage: number;
  timeLeft: number;
}

export interface RouteStatusData {
  remaining_time: number | null;
  remaining_distance: number | null;
}

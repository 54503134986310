export const convertHexWithOpacity = (hex: string, opacity: number) => {
  const numberHex = hex.replace("#", "");
  const r = parseInt(numberHex.substring(0, 2), 16);
  const g = parseInt(numberHex.substring(2, 4), 16);
  const b = parseInt(numberHex.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity})`;
  return result;
};

const hex2rgb = (hex: string) => {
  const hexValues = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (_, r, g, b) => {
      return "#" + r + r + g + g + b + b;
    }
  );

  const rgbNumberArray = hexValues
    .substring(1)
    .match(/.{2}/g)!
    .map((x) => parseInt(x, 16));

  return `rgb( ${rgbNumberArray[0]}, ${rgbNumberArray[1]}, ${rgbNumberArray[2]})`;
};

const rgb2hex = (rgb: string) => {
  const regexRgb = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  return regexRgb && regexRgb.length === 4
    ? "#" +
        ("0" + parseInt(regexRgb[1], 10).toString(16)).slice(-2) +
        ("0" + parseInt(regexRgb[2], 10).toString(16)).slice(-2) +
        ("0" + parseInt(regexRgb[3], 10).toString(16)).slice(-2)
    : "";
};

// code snippet from : https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export const reShadeHEXColor = (p: number, hexColor: string): string => {
  const rgb = hex2rgb(hexColor);
  const [r, g, b, a] = rgb.split(",");
  let P: any = p < 0;
  const t = P ? 0 : 255 * p;
  P = P ? 1 + p : 1 - p;

  const newR = Math.round(
    parseInt(r[3] === "a" ? r.slice(5) : r.slice(4), 10) * P + t
  );
  const newG = Math.round(parseInt(g, 10) * P + t);
  const newB = Math.round(parseInt(b, 10) * P + t);

  const rgbString = `rgb${a ? "a(" : "("}${newR},${newG},${newB}${
    a ? "," + a : ")"
  }`;
  return rgb2hex(rgbString);
};

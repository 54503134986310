import type { ChangeEvent } from "react";
import type LivingMap from "@livingmap/core-mapping";

import { CustomEventAPICallbackFormat } from "../custom-api-callback-format";
import Modal from "@components/Modal/Modal";
import BigConfirmationModal from "@components/Modal/Content/BigConfirmationModal";
import BigLoadingModal from "@components/Modal/Content/BigLoadingModal";
import DecisionModal from "@components/Modal/Content/DecisionModal";
import FatalErrorModal from "@components/Modal/Content/FatalErrorModal";
import GateSelectionModal from "@components/Modal/Content/GateSelectionModal";
import PassportControlModal from "@components/Modal/Content/PassportControlModal";
import DeterminingPositionModal from "@components/Modal/Content/DeterminingPositionModal";
import type { RoutingControl } from "@components/Map/plugins";
import {
  addModal,
  NotificationIcons,
  removeModal,
  setCTABanner,
} from "@redux/slices/uiSlice";
import { store } from "@redux/store";
import Mobile from "@mobile";
import { getRouteLocations } from "@utils";
import { ApiEventTypes, ExtractActionPayload, ApiAction } from "../types";
import { PLUGIN_IDS } from "@components/Map/plugins/types";
import ImageModal from "@components/Modal/Content/ImageModal";
import styles from "./modal-manager.module.css";

interface ModalStore {
  [key: string]: JSX.Element;
}

type BigConfirmationData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.SHOW_BIG_CONFIRMATION_ALERT
>;

type BigLoadingData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.SHOW_BIG_LOADING_ALERT
>;

type DecisionData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.SHOW_DECISION_MODAL
>;

type FatalErrorData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.SHOW_FATAL_ERROR_ALERT
>;

enum DecisionModalResponses {
  DECISION_MODAL_RESPONSE_OPTION_1 = "DECISION_MODAL_RESPONSE_OPTION_1",
  DECISION_MODAL_RESPONSE_OPTION_2 = "DECISION_MODAL_RESPONSE_OPTION_2",
}

export enum ModalTypes {
  BIG_CONFIRMATION_ALERT = "BIG_CONFIRMATION_ALERT",
  BIG_LOADING_ALERT = "BIG_LOADING_ALERT",
  DECISION = "DECISION",
  FATAL_ERROR = "FATAL_ERROR",
  GATE_SELECTION = "GATE_SELECTION",
  PASSPORT_CONTROL = "PASSPORT_CONTROL",
  DETERMINING_POSITION = "DETERMINING_POSITION",
  IMAGE = "IMAGE",
}

class ModalManager {
  private modals: ModalStore;

  constructor() {
    this.modals = {};
  }

  public getModal(id: string) {
    return this.modals[id];
  }

  private addModal(id: string, modal: JSX.Element) {
    this.modals[id] = modal;

    // Remove the id and re-add it to the store so the UI re-renders if updated arguments are provided to a pre-existing modal
    store.dispatch(removeModal(id));
    store.dispatch(addModal(id));
  }

  public removeModal(id: string) {
    store.dispatch(removeModal(id));
    delete this.modals[id];
  }

  /**
   * Displays a modal with the content of a supplied image
   * @param imageUrl
   * @param altText
   */
  public showImageModal(
    imageUrl: string,
    title: string,
    icon: NotificationIcons
  ) {
    this.removeModal(ModalTypes.IMAGE);

    store.dispatch(setCTABanner({ title, icon, imageUrl, isMinimized: false }));

    this.addModal(
      ModalTypes.IMAGE,
      <Modal
        key={ModalTypes.IMAGE}
        isOpen={true}
        overlayClassName={styles.imageModalOverlay}
        onOverlayClick={this.removeImageModal}
      >
        <ImageModal url={imageUrl} altText={title} />
      </Modal>
    );
  }

  private removeImageModal = () => {
    store.dispatch(setCTABanner({ isMinimized: true }));
    this.removeModal(ModalTypes.IMAGE);
  };

  /**
   * Displays a modal informing the user the system is determining their position
   */
  public showDeterminingPositionModal() {
    this.hideDeterminingPositionModal();

    this.addModal(
      ModalTypes.DETERMINING_POSITION,
      <Modal key={ModalTypes.DETERMINING_POSITION} isOpen={true}>
        <DeterminingPositionModal />
      </Modal>
    );
  }

  /**
   * Removes the modal informing the user the system is determining their position
   */
  public hideDeterminingPositionModal() {
    this.removeModal(ModalTypes.DETERMINING_POSITION);
  }

  /**
   * Displays a confirmation modal with your defined header, body and variable text
   *
   * @param data
   * @param eventId
   * @returns eventId
   */
  public showBigConfirmationAlert(data: BigConfirmationData, eventId: string) {
    this.removeModal(ModalTypes.BIG_LOADING_ALERT);

    this.addModal(
      ModalTypes.BIG_CONFIRMATION_ALERT,
      <Modal key={ModalTypes.BIG_CONFIRMATION_ALERT} isOpen={true}>
        <BigConfirmationModal
          {...data}
          onClose={() => {
            this.removeModal(ModalTypes.BIG_CONFIRMATION_ALERT);
          }}
        />
      </Modal>
    );

    return eventId;
  }

  /**
   * Displays a modal with a custom message and a loading spinner
   *
   * @param data
   * @param eventId
   * @returns eventId
   */
  public showBigLoadingAlert(data: BigLoadingData, eventId: string) {
    this.addModal(
      ModalTypes.BIG_LOADING_ALERT,
      <Modal key={ModalTypes.BIG_LOADING_ALERT} isOpen={true}>
        <BigLoadingModal {...data} />
      </Modal>
    );

    return eventId;
  }

  /**
   * Displays a modal with your defined title, option 1 and option 2 choice.
   * Once a user makes a selection, it returns either "DECISION_MODAL_RESPONSE_OPTION_1" or "DECISION_MODAL_RESPONSE_OPTION_2"
   *
   * @param eventId
   * @param title
   * @param option1Text
   * @param option2Text
   * @returns eventId
   */
  public showDecisionModal(data: DecisionData, eventId: string) {
    this.removeModal(ModalTypes.BIG_LOADING_ALERT);
    this.removeModal(ModalTypes.BIG_CONFIRMATION_ALERT);
    this.removeModal(ModalTypes.GATE_SELECTION);

    this.addModal(
      ModalTypes.DECISION,
      <Modal key={ModalTypes.DECISION} isOpen={true}>
        <DecisionModal
          {...data}
          onOption1Click={() =>
            this.handleDecisionClick(
              DecisionModalResponses.DECISION_MODAL_RESPONSE_OPTION_1
            )
          }
          onOption2Click={() =>
            this.handleDecisionClick(
              DecisionModalResponses.DECISION_MODAL_RESPONSE_OPTION_2
            )
          }
        />
      </Modal>
    );

    return eventId;
  }

  private handleDecisionClick(decision: DecisionModalResponses) {
    Mobile.sharedSendMessageEmitter(decision, {});
    this.removeModal(ModalTypes.DECISION);
  }

  /**
   * Displays a modal with an optional custom header message, an optional "retry" button and an "exit" button.
   *
   * @param data
   * @param eventId
   * @returns eventId
   */
  public showFatalError(data: FatalErrorData, eventId: string) {
    const modalId = `${ModalTypes.FATAL_ERROR} ${eventId}`;

    const response = new CustomEventAPICallbackFormat(eventId);

    const handleRetry = () => {
      data.retryCallback && data.retryCallback(response);
      this.removeModal(modalId);
    };
    const handleExit = () => {
      data.exitCallback(response);
      this.removeModal(modalId);
    };

    this.addModal(
      modalId,
      <Modal key={modalId} isOpen={true}>
        <FatalErrorModal
          headerText={data.headerText}
          onRetry={data.retryCallback && handleRetry}
          onExit={handleExit}
        />
      </Modal>
    );

    return eventId;
  }

  /**
   * Displays a modal to select your arrival/departure gates and render a route
   *
   * @param LMMap
   * @param eventId
   * @returns eventId
   */
  public showGateSelection(LMMap: LivingMap, eventId: string) {
    const routingControl = LMMap.getPluginById<RoutingControl>(
      PLUGIN_IDS.ROUTING
    );

    if (!routingControl) throw new Error("Routing not available.");

    routingControl.clear();

    let origin = "";
    let destination = "";

    const handleShowRouteClick = () => {
      if (origin && destination) {
        routingControl.requestFilteredRoute(origin, destination, {});
        this.removeModal(ModalTypes.GATE_SELECTION);
      }
    };

    const handleShowMapClick = () => {
      this.removeModal(ModalTypes.GATE_SELECTION);
    };

    const handleOriginChange = (event: ChangeEvent<HTMLSelectElement>) => {
      origin = event.target.value;
    };

    const handleDestinationChange = (event: ChangeEvent<HTMLSelectElement>) => {
      destination = event.target.value;
    };

    const routes = getRouteLocations();

    if (!routes) {
      throw new Error("No routes available.");
    }

    const arrivalOptions = routes.origins.map((origin) => ({
      id: origin,
      text: origin,
    }));
    const destinationOptions = routes.destinations.map((destination) => ({
      id: destination,
      text: destination,
    }));

    this.removeModal(ModalTypes.BIG_LOADING_ALERT);
    this.removeModal(ModalTypes.BIG_CONFIRMATION_ALERT);

    this.addModal(
      ModalTypes.GATE_SELECTION,
      <Modal key={ModalTypes.GATE_SELECTION} isOpen={true}>
        <GateSelectionModal
          arrivalOptions={arrivalOptions}
          destinationOptions={destinationOptions}
          onOriginChange={handleOriginChange}
          onDestinationChange={handleDestinationChange}
          onShowMapClick={handleShowMapClick}
          onShowRouteClick={handleShowRouteClick}
        />
      </Modal>
    );

    return eventId;
  }

  public showSecurityModal(LMMap: LivingMap) {
    this.addModal(
      ModalTypes.PASSPORT_CONTROL,
      <Modal key={ModalTypes.PASSPORT_CONTROL} isOpen={true}>
        <PassportControlModal />
      </Modal>
    );

    LMMap.blur();
  }

  public hideSecurityModal(LMMap: LivingMap) {
    this.removeModal(ModalTypes.PASSPORT_CONTROL);
    LMMap.unblur();
  }
}

export default new ModalManager();

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ConfigResponse } from "./types";

export const vectorApi = createApi({
  reducerPath: "vectorApi",
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: `https://vector.${process.env.REACT_APP_ENVIRONMENT}.livingmap.com`,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    configEndpoint: builder.query<ConfigResponse, void>({
      query: () => `/data/${process.env.REACT_APP_PROJECT_NAME}/config.json`,
    }),
  }),
});

export const { useConfigEndpointQuery } = vectorApi;

const DIFFERENCE_THRESHOLD_FOR_SDK_HEADING_DEGREES = 45;

export class DirectionHandler {
  public static doesSDKHeadingCrossDifferenceThreshold(
    routeHeading: number,
    SDKHeading: number
  ): boolean {
    return (
      Math.abs(
        DirectionHandler.signedMinimumAngleDifference(routeHeading, SDKHeading)
      ) > DIFFERENCE_THRESHOLD_FOR_SDK_HEADING_DEGREES
    );
  }

  public static signedMinimumAngleDifference(
    targetAngle: number,
    sourceAngle: number
  ): number {
    let difference = targetAngle - sourceAngle;
    difference = ((difference + 180) % 360) - 180;
    return difference;
  }
}

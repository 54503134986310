import { useEffect, useState } from "react";
import LivingMap from "@livingmap/core-mapping";
import classNames from "classnames";

import { Floor } from "@redux/services/types";
import styles from "./FloorSelector.module.css";

const FLOOR_CHANGED = "FLOOR_CHANGED";

interface Props {
  floors: Floor[];
  defaultFloor: string;
  onFloorSelect: (floor: Floor) => void;
  mapInstance: LivingMap | null;
}

const FloorSelector = ({
  floors,
  defaultFloor,
  mapInstance,
  onFloorSelect,
}: Props) => {
  const [activeFloor, setActiveFloor] = useState(
    floors.find((floor) => floor.universal_id === defaultFloor)!.universal_id
  );

  useEffect(() => {
    mapInstance?.on(FLOOR_CHANGED, (newFloor: Floor) => {
      setActiveFloor(newFloor.universal_id);
    });
  }, [mapInstance]);

  const floorListItems = floors.map((floor) => {
    const isActive = floor.universal_id === activeFloor;
    return (
      <li
        key={floor.universal_id}
        className={classNames(styles.listItem, {
          [styles.active]: isActive,
        })}
        onClick={() => onFloorSelect(floor)}
      >
        <span>{floor.short_name}</span>
      </li>
    );
  });
  return <ul className={styles.container}>{floorListItems}</ul>;
};

export default FloorSelector;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  mapDownloadProgress: number;
};

const initialState: InitialState = {
  mapDownloadProgress: 0,
};

export const offlineSlice = createSlice({
  name: "offline",
  initialState,
  reducers: {
    setMapDownloadProgress: (state, action: PayloadAction<number>) => {
      state.mapDownloadProgress = action.payload;
    },
  },
});

export const { setMapDownloadProgress } = offlineSlice.actions;

export default offlineSlice.reducer;

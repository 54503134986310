import Spinner from "@components/Spinner/Spinner";

import styles from "./BigLoadingModal.module.css";

interface Props {
  message: string;
}

const BigLoadingModal: React.FC<Props> = ({ message }) => {
  return (
    <div className={styles.container}>
      <p className={styles.message}>{message}</p>
      <Spinner type="MoonLoader" size={34} color="#003250" />
    </div>
  );
};

export default BigLoadingModal;

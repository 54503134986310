import { FloorDirection } from "@components/Map/plugins/controls/floor-control";
import { FloorDirectionOptions as Props } from "@redux/slices/uiSlice";
import styles from "./FloorDirectionAlert.module.css";

const FloorDirectionAlert: React.FC<Props> = ({
  destinationFloor,
  direction,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.direction}>
        {direction === FloorDirection.UP ? "Up to floor:" : "Down to floor:"}
      </p>
      <p className={styles.destination}>{destinationFloor}</p>
    </div>
  );
};

export default FloorDirectionAlert;

import { Icon } from "@livingmap/core-ui-v2";

import styles from "./RouteCompletedAlert.module.css";

const RouteCompletedAlert = () => {
  return (
    <div className={styles.container}>
      <Icon type="CheckIcon" width={48} height={48} />
      <p className={styles.text}>
        You have arrived at
        <br />
        your destination
      </p>
    </div>
  );
};

export default RouteCompletedAlert;

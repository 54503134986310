import { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import type LivingMap from "@livingmap/core-mapping";

import Map from "@components/Map/Map";
import modalManager from "@api/modals/modal-manager";
import { useConfigEndpointQuery } from "@redux/services/sdk";
import { useAppSelector } from "@redux/hooks";
import { getUrl, URLTypes } from "@utils";

import styles from "./App.module.css";

function App() {
  const { data, isError } = useConfigEndpointQuery();

  const modals = useAppSelector((state) => state.ui.modals);
  const [mapInstance, setMapInstance] = useState<LivingMap | undefined>();

  const handleMapReady = useCallback((map: LivingMap) => {
    setMapInstance(map);
  }, []);

  return data ? (
    <div className={styles.container}>
      {mapInstance && <Outlet context={{ map: mapInstance }} />}
      <Map
        bearing={data.areas[0].view.bearing}
        zoom={data.areas[0].view.zoom}
        maxZoom={data.areas[0].view.maxZoom}
        minZoom={data.areas[0].view.minZoom}
        center={data.areas[0].view.center}
        extent={data.areas[0].view.extent}
        floor={data.floors.find((floor) => !!floor.default)!.universal_id}
        floors={data.floors}
        mapStyle={`${getUrl(URLTypes.STYLES)}/styles.json`}
        accessToken={process.env.REACT_APP_MAPBOX_TOKEN as string}
        onMapReady={handleMapReady}
      />
      {modals.map((modal) => {
        return modalManager.getModal(modal);
      })}
    </div>
  ) : isError ? (
    <div>Error loading config</div>
  ) : null;
}

export default App;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

import { vectorApi } from "./services/sdk";
import uiReducer from "./slices/uiSlice";
import offlineReducer from "./slices/offlineSlice";
import routingReducer from "./slices/routingSlice";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const store = configureStore({
  reducer: {
    router: routerReducer,
    ui: uiReducer,
    offline: offlineReducer,
    routing: routingReducer,
    [vectorApi.reducerPath]: vectorApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([vectorApi.middleware, routerMiddleware]),
});

setupListeners(store.dispatch);

export const history = createReduxHistory(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

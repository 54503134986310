import { Icon } from "@livingmap/core-ui-v2";

import styles from "./BigConfirmationModal.module.css";

interface Props {
  header: string;
  body: string;
  variable: string;
  onClose: () => void;
}

const BigConfirmationModal: React.FC<Props> = ({
  header,
  body,
  variable,
  onClose,
}) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{header}</h2>
      <p className={styles.body}>{body}</p>
      <p className={styles.variable}>{variable}</p>
      <button className={styles.button} onClick={onClose}>
        <span>Close Message</span>
        <Icon type="CloseIcon" width={20} height={20} />
      </button>
    </div>
  );
};

export default BigConfirmationModal;

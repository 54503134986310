import { inside, point, Polygon, Feature } from "@turf/turf";

import LMUserLocation from "../routing/LMUserLocation";
import tagManagerControl from "@decorators/tag-manager-control";
import DynamicGeofenceStrategy from "./dynamic-geofence-strategy";

const EXIT_DELAY_COUNT = 10;

export default class DynamicGeofenceArea {
  private geoJSONFeature: any;
  private currentCount = 0;
  private activationCount: number;
  private triggerFloor: number;
  private geofenceStrategy: DynamicGeofenceStrategy;

  constructor(geoJSONFeature: any, dynamicCallback: DynamicGeofenceStrategy) {
    this.geoJSONFeature = geoJSONFeature;
    this.activationCount = geoJSONFeature.properties.activationCount;
    this.triggerFloor = geoJSONFeature.properties.floorId;

    this.geofenceStrategy = dynamicCallback;
    this.activate();
  }

  public activateGeofenceIfUserIsInArea(userLocation: LMUserLocation): void {
    const currentCount = this.resolveUserLocationCounter(userLocation);
    if (currentCount === this.activationCount) {
      this.checkGeofenceActivation();
    } else if (currentCount === 0) {
      this.checkGeofenceDeactivation();
    }
  }

  private activate(): void {
    this.geofenceStrategy.startDeactivationListener();
  }

  public deactivate(): void {
    this.geofenceStrategy.stopDeactivationListener();
  }

  private resolveUserLocationCounter(userLocation: LMUserLocation): number {
    if (this.isUserInArea(userLocation)) {
      if (this.currentCount < this.activationCount) this.currentCount++;
    } else if (!this.geofenceStrategy.isAreaTriggered()) {
      this.currentCount = 0;
    } else {
      if (this.currentCount > 0) this.currentCount--;
    }
    return this.currentCount;
  }

  private checkGeofenceActivation(): void {
    if (!this.geofenceStrategy.isAreaTriggered()) {
      this.currentCount = EXIT_DELAY_COUNT;
      this.geofenceStrategy.activate();
      const triggerType = this.geoJSONFeature.properties.geofenceType;
      tagManagerControl.sendEventToDataLayer(
        "Waypoint",
        "Geofence Entered",
        triggerType
      );
    }
  }

  private checkGeofenceDeactivation(): void {
    if (this.geofenceStrategy.isAreaTriggered()) {
      const triggerType = this.geoJSONFeature.properties.geofenceType;
      tagManagerControl.sendEventToDataLayer(
        "Waypoint",
        "Geofence Exited",
        triggerType
      );
      this.geofenceStrategy.deactivate();
    }
  }

  private isUserInArea(userLocation: LMUserLocation): boolean {
    if (
      userLocation.getFloorId() &&
      userLocation.getFloorId() !== this.triggerFloor
    ) {
      return false;
    }
    return this.isLocationInside(
      userLocation.getAsLngLatLike(),
      this.geoJSONFeature.geometry
    );
  }

  private isLocationInside(
    userLocationPoint: mapboxgl.LngLatLike,
    feature: Feature<Polygon>
  ): boolean {
    const locationPoint = point(userLocationPoint as number[]);
    return inside(locationPoint, feature);
  }
}

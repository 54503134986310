import { useEffect, useRef, useState } from "react";
import LivingMap from "@livingmap/core-mapping";
import { useTransition, animated } from "react-spring";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import FloorSelector from "./components/FloorSelector/FloorSelector";
import LoadingMap from "./components/LoadingMap/LoadingMap";
import { PLUGIN_IDS } from "./plugins/types";
import { Floor } from "@redux/services/types";
import { useConfigEndpointQuery } from "@redux/services/sdk";
import {
  RoutingControl,
  FloorControl,
  GeofenceControl,
  UserLocationControl,
  MarkerControl,
  InteractionControl,
  ClusteredPinControl,
  DebugControl,
} from "./plugins";
import { MapFeatures, doesMapHaveFeatureEnabled, getQuery } from "@utils";
import { enableExternalAccessAPI } from "@api/index";
import styles from "./Map.module.css";

interface Props {
  zoom: number;
  maxZoom: number;
  minZoom: number;
  center: [number, number];
  extent: [number, number, number, number];
  bearing: number;
  mapStyle: string;
  accessToken: string;
  floor: string;
  floors: Floor[];
  mapID?: string;
  onMapReady?: (map: LivingMap) => void; // ENSURE THAT YOU PASS ANY FUNCTION INTO THIS PROP AS A useCallback()
}

const Map: React.FC<Props> = ({
  bearing,
  center,
  extent,
  maxZoom,
  minZoom,
  zoom,
  mapStyle,
  accessToken,
  floor,
  floors,
  onMapReady,
}) => {
  const { data } = useConfigEndpointQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [mapInstance, setMapInstance] = useState<LivingMap | null>(null);

  const mapContainer = useRef<HTMLDivElement | null>(null);

  const floorControlInstance = useRef<FloorControl>();
  const routingControlInstance = useRef<RoutingControl>();
  const geofenceControlInstance = useRef<GeofenceControl>();
  const userLocationControlInstance = useRef<UserLocationControl>();
  const markerControlInstance = useRef<MarkerControl>();
  const interactionControlInstance = useRef<InteractionControl>();
  const clusteredPinControlInstance = useRef<ClusteredPinControl>();
  const debugControlInstance = useRef<DebugControl>();

  const transition = useTransition(isLoading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: isLoading,
    delay: 200,
    config: { duration: 200 },
  });

  const setActiveFloor = (floor: Floor) => {
    floorControlInstance.current?.setActiveFloor(floor);
  };

  useEffect(() => {
    setIsLoading(true);

    const mapConfig = {
      accessToken,
      zoom,
      maxZoom,
      minZoom,
      center,
      extent,
      bearing,
      style: mapStyle,
      hash: false,
    };

    const map = new LivingMap(mapContainer.current!, mapConfig);

    const floorToRender = floors.find((item) => item.universal_id === floor);

    floorControlInstance.current = new FloorControl(PLUGIN_IDS.FLOOR, map);
    map.addPlugin(floorControlInstance.current);

    clusteredPinControlInstance.current = new ClusteredPinControl(
      PLUGIN_IDS.CLUSTERED_PIN,
      map,
      floorControlInstance.current
    );
    map.addPlugin(clusteredPinControlInstance.current);

    interactionControlInstance.current = new InteractionControl(
      PLUGIN_IDS.INTERACTION,
      map,
      clusteredPinControlInstance.current
    );
    map.addPlugin(interactionControlInstance.current);

    markerControlInstance.current = new MarkerControl(PLUGIN_IDS.MARKER, map);
    map.addPlugin(markerControlInstance.current);

    if (doesMapHaveFeatureEnabled(MapFeatures.GEOFENCES)) {
      geofenceControlInstance.current = new GeofenceControl(
        PLUGIN_IDS.GEOFENCE,
        map
      );

      map.addPlugin(geofenceControlInstance.current);
    }

    if (doesMapHaveFeatureEnabled(MapFeatures.USER_LOCATION)) {
      userLocationControlInstance.current = new UserLocationControl(
        PLUGIN_IDS.USER_LOCATION,
        map,
        geofenceControlInstance.current
      );

      map.addPlugin(userLocationControlInstance.current);
    }

    if (
      doesMapHaveFeatureEnabled(MapFeatures.ROUTING) ||
      doesMapHaveFeatureEnabled(MapFeatures.DIRECTIONS)
    ) {
      routingControlInstance.current = new RoutingControl(
        PLUGIN_IDS.ROUTING,
        map,
        data!
      );

      map.addPlugin(routingControlInstance.current);
    }

    if (getQuery("debugmode") === "true") {
      debugControlInstance.current = new DebugControl(
        PLUGIN_IDS.DEBUG,
        map,
        geofenceControlInstance.current
      );

      map.addPlugin(debugControlInstance.current);
    }

    map.create();

    map.on("style.load", () => {
      floorControlInstance.current?.setGroundFloor(floors);

      if (floorToRender) {
        floorControlInstance.current?.setActiveFloor(floorToRender);
      }
    });

    map.on("render", function () {
      map?.getMapboxMap().resize();
    });

    map.on("load", () => {
      map.getMapboxMap().addControl(
        new mapboxgl.AttributionControl({
          compact: false,
          customAttribution: "&copy; Living Map Ltd.",
        }),
        "bottom-left"
      );

      enableExternalAccessAPI({ map });
      setMapInstance(map);
      setIsLoading(false);

      onMapReady && onMapReady(map);
    });
  }, [
    accessToken,
    bearing,
    center,
    data,
    extent,
    floor,
    floors,
    mapStyle,
    maxZoom,
    minZoom,
    onMapReady,
    zoom,
  ]);

  return (
    <div className={styles.container}>
      {transition((styles, mapIsLoading) => {
        return (
          mapIsLoading && (
            <animated.div
              style={{
                ...styles,
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 100,
              }}
            >
              <LoadingMap />
            </animated.div>
          )
        );
      })}
      <div ref={mapContainer} className={styles.map} />
      {!isLoading && (
        <div className={styles.floorSelector}>
          <FloorSelector
            floors={floors}
            defaultFloor={floor}
            mapInstance={mapInstance}
            onFloorSelect={setActiveFloor}
          />
        </div>
      )}
    </div>
  );
};

export default Map;

import EventEmitter from "eventemitter3";

/**
 * Base abstraction to provide stateful callback handling for dynamic geofences.
 */
export default abstract class DynamicGeofenceStrategy extends EventEmitter {
  protected areaTriggered = false;

  public abstract activate(): void;
  public abstract deactivate(): void;

  /**
   * Default implementation to activate any event listeners required for geofence deactivation.
   *
   * Don't override in subclasses if no deactivation based on event listeners is needed.
   *
   * If this is used, stopDeactivationListeners should also be implemented to remove any outstanding listeners when
   * the geofence is deactivated.
   */
  public startDeactivationListener(): void {
    return;
  }

  /**
   * Default implementation to remove any event listeners required for geofence deactivation.
   *
   * Don't override in subclasses if no deactivation based on event listeners is needed.
   *
   * This should be implemented whenever startDeactivationListener is implemented.
   */
  public stopDeactivationListener(): void {
    return;
  }

  public isAreaTriggered(): boolean {
    return this.areaTriggered;
  }
}

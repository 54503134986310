import { FC, ReactNode } from "react";
import styles from "./Notification.module.css";

export enum NotificationColour {
  INFO = "#E5E6E6",
  DEBUG = "#E5E6E6",
  WARNING = "#EBB428",
  ERROR = "#DC3E38",
  SUCCESS = "#00C649",
}

export interface Props {
  type: NotificationColour;
  text: string;
  icon: ReactNode;
  colour?: string;
  textColour?: string;
}

const Notification: FC<Props> = ({ type, text, icon, colour, textColour }) => {
  const getColor = () => {
    if (textColour) return textColour;
    return type === NotificationColour.ERROR ? "#FFF" : "#003250";
  };

  return (
    <li
      className={styles.container}
      style={{ backgroundColor: colour || type, color: getColor() }}
    >
      <div className={styles.iconWrapper}>{icon}</div>
      <div className={styles.textWrapper}>{text}</div>
    </li>
  );
};

export default Notification;

import Mobile from "@mobile";

const enum PrettyReadyState {
  IN_PROGRESS = "in-progress",
  COMPLETE = "complete",
}

export const READY_STATE_HTML_TAG = "data-loading-status";

const readyStates = {
  api: false,
};

// initial loading status = in progress.
document.body.setAttribute(READY_STATE_HTML_TAG, PrettyReadyState.IN_PROGRESS);

const setReady = (type: string) => {
  if (type === "api") readyStates.api = true;
  if (readyStates.api) {
    // The map can now be used from external sources
    if (Mobile.isAndroidWebview()) {
      Mobile.getGlobalAndroid()!.onMapReady();
    } else {
      Mobile.sendMessageToIOS({
        eventName: "onMapReady",
      });
    }

    // we set a HTML data tag on the body to signify that we are ready. This could be picked up by various testing + performance QA tools.
    document.body.setAttribute(READY_STATE_HTML_TAG, PrettyReadyState.COMPLETE);
  }
};

export { setReady };

import { RouteLocation } from "@components/Map/plugins/controls/routing-control";
import {
  RequestRouteOptions,
  RoutingDestinationData,
  RoutingOverviewData,
} from "@components/Map/plugins/types";
import { RootState } from "@redux/store";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { formatToMultiLineString, parseRouteGeometryData } from "@utils";

type InitialState = {
  from: RouteLocation | null;
  to: RouteLocation | null;
  route: RouteTypes;
  routingOverviewData: RoutingOverviewData | null;
  routingDestinationData: RoutingDestinationData | null;
  options: RequestRouteOptions | null;
  floorsInRoute: number[];
};

export type ParsedRouteData = Array<{
  floorId: number;
  data: GeoJSON.Feature<GeoJSON.Point>[][];
}>;

interface RouteTypes {
  original?: GeoJSON.FeatureCollection<any>;
  parsed?: ParsedRouteData;
  multiline?: any; // TODO: find out what type this needs to be;
}

const initialState: InitialState = {
  from: null,
  to: null,
  route: {},
  routingOverviewData: null,
  routingDestinationData: null,
  options: null,
  floorsInRoute: [],
};

export const routingSlice = createSlice({
  name: "routing",
  initialState,
  reducers: {
    setFromRouteLocation: (state, action: PayloadAction<RouteLocation>) => {
      state.from = action.payload;
    },
    setToRouteLocation: (state, action: PayloadAction<RouteLocation>) => {
      state.to = action.payload;
    },
    setOptions: (state, action: PayloadAction<RequestRouteOptions>) => {
      state.options = action.payload;
    },
    setRouteViaPoints: (state, action: PayloadAction<RouteLocation[]>) => {
      if (!state.options) return state;
      state.options.via = action.payload;
    },
    setRequestData: (
      state,
      action: PayloadAction<GeoJSON.FeatureCollection<any>>
    ) => {
      const { flattenedRouteCoordinates, floorsInRoute } =
        parseRouteGeometryData(action.payload);

      /**
       * we hold and maintain different route geometries for different purposes.
       *
       * original -->
       *  input geometry from the backend-routing
       *  Properties: properties are avialable for every segment.
       *  Geom Format: feature collection with individual linestrings
       *
       * parsed -->
       *  geometries ordered by floor and contain information on every route segment
       *  Properties: properties are avialable for every segment.
       *  Geom Format: feature collection with individual linestrings
       *
       * multiline -->
       *  geometries are ordered by floor and then ordered by CONNECTED geometries.
       *  Properties: properties are unavialable for any segment
       *  Geom Format: MultilineString
       */
      state.route.original = action.payload;
      state.route.parsed = flattenedRouteCoordinates;
      state.route.multiline = formatToMultiLineString(state.route.parsed);
      state.floorsInRoute = floorsInRoute;
    },
    clearRequestData: (state) => {
      state.options = null;
      state.from = null;
      state.to = null;
      state.route = {};
      state.routingOverviewData = null;
    },
    setRoutingOverviewData: (
      state,
      action: PayloadAction<RoutingOverviewData>
    ) => {
      state.routingOverviewData = action.payload;
    },
    setRoutingDestinationData: (
      state,
      action: PayloadAction<RoutingDestinationData>
    ) => {
      state.routingDestinationData = action.payload;
    },
  },
});

export const getHighestRouteSequenceNumber = createSelector(
  (state: RootState) => state.routing.route,
  (route) => {
    const routeOriginal = route.original;
    if (!routeOriginal) return null;

    const featureCount = routeOriginal.features.length;
    const lastFeature = routeOriginal.features[featureCount - 1];
    if (!lastFeature) return null;

    return lastFeature!.properties!.sequence;
  }
);

export const {
  setFromRouteLocation,
  setRouteViaPoints,
  clearRequestData,
  setOptions,
  setRequestData,
  setRoutingDestinationData,
  setRoutingOverviewData,
  setToRouteLocation,
} = routingSlice.actions;

export default routingSlice.reducer;

import { ParsedRouteData } from "@redux/slices/routingSlice";

// reformats the route geometry to make sure it is valid multiline geojson spec.
export const formatToMultiLineString = (routeData: ParsedRouteData) => {
  return routeData.map((dataForFoor) => {
    return {
      type: "Feature",
      properties: {
        floor_id: dataForFoor.floorId,
      },
      geometry: {
        type: "MultiLineString",
        coordinates: dataForFoor.data.map((connectedRouteSegment: any) => {
          return connectedRouteSegment.map((f: any) => f.geometry.coordinates);
        }),
      },
    };
  });
};

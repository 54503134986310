import { isLat, isLon } from "@livingmap/core-mapping/dist/utils";

import { Floor } from "@redux/services/types";
import { getFloorById } from "@utils";
import { Coordinate } from "../types";

interface UserLocationOptions {
  accuracy?: number;
  heading?: number;
  floorId?: number;
  floorName?: string;
  floor: Floor | null;
}

type CoordinateLike =
  | mapboxgl.LngLat
  | mapboxgl.LngLatLike
  | GeolocationPosition
  | GeoJSON.Point
  | number[];

export default class LMUserLocation {
  private latitude: number;
  private longitude: number;
  private floor: Floor | null = null;
  private accuracy: number | null = null;
  private heading: number | null = null;
  private timestamp: number;
  private isFromSnapManager = false;

  constructor(newLocation: CoordinateLike, options?: UserLocationOptions);
  constructor(newLocation: any, options: any) {
    if (!Array.isArray(newLocation) && typeof newLocation === "object") {
      if (newLocation.coords) {
        this.latitude = newLocation.coords.latitude;
        this.longitude = newLocation.coords.longitude;
      } else if (newLocation.type === "Point" && newLocation.coordinates) {
        this.longitude = newLocation.coordinates[0];
        this.latitude = newLocation.coordinates[1];
      } else {
        this.latitude = newLocation.lat;
        this.longitude = newLocation.lng;
      }
    } else {
      this.longitude = newLocation[0];
      this.latitude = newLocation[1];
    }

    if (options) {
      if (typeof options.accuracy === "number")
        this.accuracy = options.accuracy;
      if (typeof options.heading === "number") this.heading = options.heading;
      if (options.floor) this.floor = options.floor;
    } else {
      if (typeof newLocation.accuracy === "number")
        this.accuracy = newLocation.accuracy;
      if (typeof newLocation.heading === "number")
        this.heading = newLocation.heading;
      if (newLocation.floor) this.floor = newLocation.floor;
      if (newLocation.coords) {
        if (typeof newLocation.coords.accuracy === "number")
          this.accuracy = newLocation.coords.accuracy;
        if (typeof newLocation.coords.heading === "number")
          this.heading = newLocation.coords.heading;
        if (typeof newLocation.coords.altitude === "number")
          this.floor = getFloorById(newLocation.coords.altitude);
      }
    }

    this.timestamp = new Date().getTime();
  }

  public getFloor(): Floor | null {
    return this.floor;
  }

  public getFloorId(): number | null {
    if (this.floor) {
      return this.floor.id;
    } else if (this.getAsPosition().coords.altitude !== undefined) {
      return this.getAsPosition().coords.altitude;
    } else {
      return null;
    }
  }

  public setFloor(floor: Floor): void {
    this.floor = floor;
  }

  public getAccuracy(): number | null {
    return this.accuracy;
  }

  public setHeading(newHeading: number): void {
    this.heading = newHeading;
  }

  public getHeading(): number | null {
    return this.heading;
  }

  public getAsLngLatLike(): mapboxgl.LngLatLike {
    return [this.longitude, this.latitude];
  }

  public getAsCoordinate(): Coordinate {
    return {
      x: this.longitude,
      y: this.latitude,
      floor: this.floor,
    };
  }

  public getAsPosition(): GeolocationPosition {
    return {
      coords: {
        accuracy: this.accuracy || 0,
        altitude: this.floor == null ? null : this.floor.id,
        altitudeAccuracy: null,
        heading: this.heading,
        latitude: this.latitude,
        longitude: this.longitude,
        speed: null,
      },
      timestamp: this.timestamp,
    };
  }

  public setIsFromSnapManager(isFromSnapManager: boolean): void {
    this.isFromSnapManager = isFromSnapManager;
  }

  public getIsFromSnapManager(): boolean {
    return this.isFromSnapManager;
  }

  public isTheSamePositionAs(locationToCompare: LMUserLocation): boolean {
    const coordinates = this.getAsPosition().coords;
    const coordinatesToCompare = locationToCompare.getAsPosition().coords;
    if (
      coordinates.latitude !== coordinatesToCompare.latitude ||
      coordinates.longitude !== coordinatesToCompare.longitude
    ) {
      return false;
    }

    const floor = this.getFloor();
    const floorToCompare = locationToCompare.getFloor();

    if ((floor && !floorToCompare) || (!floor && floorToCompare)) {
      return false;
    }

    if (floor && floorToCompare) {
      if (
        floor.name !== floorToCompare.name ||
        floor.id !== floorToCompare.id
      ) {
        return false;
      }
    }

    return true;
  }

  public isValid(): boolean {
    return isLat(this.latitude) && isLon(this.longitude);
  }
}

import "./marker.css";

type AnchorOptions =
  | "center"
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"
  | undefined;

export interface MarkerIconOptions {
  type: string;
  scale?: number;
  color?: string;
  custom?: CustomMarkerIconOptions;
  anchor?: AnchorOptions;
}

interface CustomMarkerIconOptions {
  data: string;
  size?: number[];
  anchor?: AnchorOptions;
}

export interface MarkerLabelOptions {
  color?: string;
  size?: string;
  text: string;
}

interface GeneratedMarkerIconData {
  svgImage: string;
  anchor: AnchorOptions;
  scale: number;
  imageSize: number[];
}

const generateDefaultMarkerIcon = (
  color: string,
  scale: number,
  anchor?: AnchorOptions
) => {
  return {
    svgImage:
      `data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="24px" height="34.539px" viewBox="0 0 24 34.539" enable-background="new 0 0 24 34.539" xml:space="preserve"><path
    fill="${color}" stroke="#FFFFFF" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10"
    d="M12,1C5.936,1,1,5.936,1,12.002c0,1.842,0.557,4.385,1.67,6.545C5.043,23.113,8.017,27.775,12,34c3.984-6.225,6.957-10.887,9.33-15.453c1.113-2.16,1.67-4.703,1.67-6.545C23,5.936,18.066,1,12,1z"/>
    <circle opacity="0.45" fill="#444444" cx="12" cy="12.002" r="5.5"/></svg>`
        .split("\n")
        .join(""),
    anchor: anchor ? anchor : "center",
    scale,
    imageSize: [24, 36],
  };
};

const generateHollowMarkerIcon = (
  color: string,
  scale: number,
  anchor?: AnchorOptions
) => {
  return {
    svgImage:
      `data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="51.586px" height="68.7px" viewBox="0 0 51.586 68.7" enable-background="new 0 0 51.586 68.7" xml:space="preserve">
    <path fill="${color}" stroke="#3079BD" stroke-width="3" stroke-miterlimit="10" d="M25.793,1.5C12.377,1.5,1.5,12.375,1.5,25.793c0,3.826,0.888,7.444,
    2.464,10.664c0.682,1.394,1.493,2.713,2.418,3.941l19.411,25.807l19.411-25.807c0.925-1.229,1.736-2.548,2.418-3.941c1.575-3.22,2.464-6.838,
    2.464-10.664C50.086,12.375,39.209,1.5,25.793,1.5"/></svg>`
        .split("\n")
        .join(""),
    anchor: anchor ? anchor : "bottom",
    scale,
    imageSize: [51.586, 68.7],
  };
};

const generateWIPMarkerIcon = (
  _: string,
  scale: number,
  anchor: AnchorOptions = "bottom"
) => {
  const workInProgressImage = require("./assets/work-in-progress.svg");
  return {
    svgImage: workInProgressImage,
    anchor,
    scale,
    imageSize: [92, 110],
  };
};

const generateBlueMarkerIcon = (
  _: string,
  scale: number,
  anchor?: AnchorOptions
) => {
  return generateDefaultMarkerIcon("blue", scale, anchor);
};

const generateCustomMarkerIcon = (
  _: string,
  scale: number,
  custom: CustomMarkerIconOptions
) => {
  const customSize = custom.size || [];
  return {
    svgImage: custom.data.split("\n").join(""),
    anchor: custom.anchor || "bottom",
    scale,
    imageSize: [customSize[0] || 100, customSize[1] || 100],
  };
};

export const generateMarkerIcon = (iconOpts: MarkerIconOptions) => {
  const { type, scale, color, custom, anchor } = iconOpts;
  let output;

  switch (type) {
    case "hollow":
      output = generateHollowMarkerIcon(color!, scale!, anchor);
      break;
    case "workInProgress":
      output = generateWIPMarkerIcon(color!, scale!, anchor);
      break;
    case "blueMarker":
      output = generateBlueMarkerIcon(color!, scale!, anchor);
      break;
    case "custom":
      output =
        custom && custom.data
          ? generateCustomMarkerIcon(color!, scale!, custom)
          : generateDefaultMarkerIcon(color!, scale!, anchor);
      break;
    default:
      output = generateDefaultMarkerIcon(color!, scale!, anchor);
  }

  // Hashes in SVGs don't render in Firefox, being deprecated in Chrome
  output.svgImage = output.svgImage.replace(/#/g, "%23");
  return output;
};

export const createMarkerElement = (
  img: GeneratedMarkerIconData,
  label: MarkerLabelOptions
) => {
  const el = document.createElement("div");
  if (img.svgImage) {
    const imgEl = document.createElement("div");
    imgEl.className = "marker";

    imgEl.style.backgroundImage = `url('${img.svgImage}')`;
    imgEl.style.width = `${img.imageSize[0]}px`;
    imgEl.style.height = `${img.imageSize[1]}px`;
    imgEl.style.transform = `scale(${img.scale}, ${img.scale})`;

    el.appendChild(imgEl);
  }

  if (label.text) {
    const textEl = document.createElement("div");
    textEl.innerHTML = label.text;
    textEl.style.color = label.color || "";
    if (label.size) {
      textEl.style.fontSize = label.size;
    }

    el.appendChild(textEl);
  }

  return el;
};

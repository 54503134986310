export default class MarkerFactory {
  private uuid: string;
  private instance: mapboxgl.Marker;
  private element: HTMLElement;

  constructor(
    uuid: string,
    mapboxMarker: mapboxgl.Marker,
    element: HTMLElement
  ) {
    this.uuid = uuid;
    this.instance = mapboxMarker;
    this.element = element;
  }

  public getUUID(): string {
    return this.uuid;
  }

  public destroy(): void {
    this.instance.remove();
    this.element.remove();
  }
}

import { useCallback, useRef, MutableRefObject } from "react";
import type LivingMap from "@livingmap/core-mapping";

import type {
  UserLocationControl,
  RoutingControl,
  GeofenceControl,
  FloorControl,
} from "@components/Map/plugins";
import type { ConfigResponse } from "@redux/services/types";
import { useAppSelector } from "@redux/hooks";
import { ComponentMode } from "../Navigation";
import { GeofenceAreaOptions } from "@components/Map/plugins/geofence/geofence-area";
import modalManager from "@api/modals/modal-manager";
import tagManagerControl from "@decorators/tag-manager-control";
import { stringifyRouteLocation } from "@utils";

const NAVIGATION_ZOOM = 18; // Default zoom level

const useMapHandlers = (
  data: ConfigResponse | undefined,
  map: LivingMap,
  countdownInterval: MutableRefObject<
    ReturnType<typeof setTimeout> | undefined
  >,
  userLocationControl: UserLocationControl,
  routingControl: RoutingControl,
  geofenceControl: GeofenceControl,
  floorControl: FloorControl,
  mode: ComponentMode,
  setMode: (mode: ComponentMode) => void
) => {
  const setChevronInterval = useRef<ReturnType<typeof setTimeout>>();
  const userActiveInterval = useRef<ReturnType<typeof setTimeout>>();
  const isUserActive = useRef(false);

  const { routingDestinationData, to, routingOverviewData } = useAppSelector(
    (state) => state.routing
  );

  const followUserFunction = useCallback(
    (overrideActive = false) => {
      if (isUserActive.current && !overrideActive) return;

      const maxZoom = data?.routing.active_navigation_zoom || NAVIGATION_ZOOM;

      userLocationControl.centerOnUserLocation(
        {
          duration: 500,
          maxZoom,
          zoom: maxZoom,
        },
        true
      );
    },
    [data?.routing.active_navigation_zoom, userLocationControl]
  );

  const timeoutSetStaticChevron = useCallback(() => {
    setChevronInterval.current = setTimeout(() => {
      if (userLocationControl.getUserLocation() !== null) {
        userLocationControl.setFollowingUser(true, followUserFunction);
        userLocationControl.setUserLocationLayerVisibility(false);
      }
    }, 500);
  }, [followUserFunction, userLocationControl]);

  const handleStartRouteClick = useCallback(() => {
    if (!routingDestinationData) return console.warn("No destination data");
    setMode(ComponentMode.NAVIGATION);

    if (!to) {
      throw new Error("New route location is invalid");
    }

    // TODO: Find out what this is
    tagManagerControl.sendEventToDataLayer(
      "Waypoints",
      "Routing Journey Started",
      stringifyRouteLocation(to)
    );

    routingControl.hideRouteLabelPopups();
    if (geofenceControl && !geofenceControl.isInDynamicGeofence()) {
      routingControl.setVisualisationIgnoreFloors(false);
    }

    map!.enableMapInteractivity();

    if (userLocationControl) {
      userLocationControl.setFollowingUser(true, followUserFunction);
      followUserFunction(true);
      const oldLocation = userLocationControl.getUserLocation();
      if (oldLocation) {
        floorControl.setActiveFloor(oldLocation.getFloor());
      }
    }
  }, [
    floorControl,
    followUserFunction,
    geofenceControl,
    map,
    routingControl,
    to,
    routingDestinationData,
    userLocationControl,
    setMode,
  ]);

  const handleDetectionUserIsIdle = useCallback(() => {
    isUserActive.current = false;
    window.api.fireEvent("SET_IGNORE_ACTIVE_FLOOR_CHANGE", false);

    if (mode === ComponentMode.NAVIGATION) {
      followUserFunction();
      const oldLocation = userLocationControl.getUserLocation();
      if (oldLocation) {
        floorControl.setActiveFloor(oldLocation.getFloor());
      }
    }
  }, [floorControl, mode, userLocationControl, followUserFunction]);

  const handleDetectionUserIsActive = useCallback(() => {
    if (mode === ComponentMode.OVERVIEW) {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }

      setMode(ComponentMode.INSPECTION);
      return;
    }

    isUserActive.current = true;
    window.api.fireEvent("SET_IGNORE_ACTIVE_FLOOR_CHANGE", true);

    if (userActiveInterval.current) {
      clearInterval(userActiveInterval.current);
    }

    userLocationControl.setUserLocationLayerVisibility(true);

    const interactiveTime = 5 * 1000;
    userActiveInterval.current = setTimeout(
      handleDetectionUserIsIdle,
      interactiveTime
    );
  }, [
    mode,
    userLocationControl,
    handleDetectionUserIsIdle,
    countdownInterval,
    setMode,
  ]);

  const initialiseOverviewMode = useCallback(() => {
    routingControl.setVisualisationIgnoreFloors(true);
  }, [routingControl]);

  const refreshOverviewData = useCallback(() => {
    // at the moment only the first time we make a routing request, it will go into the "overview" mode.
    // In the future we should consider doing this every time the route changes due to external factors
    // (example: Gate change, cancelled bus )
    if (routingOverviewData === null && mode === ComponentMode.OVERVIEW) {
      initialiseOverviewMode();
    }
  }, [initialiseOverviewMode, mode, routingOverviewData]);

  /**
   * handles an "airport security geofence exit" geofence, which effectively means the map will no longer be blurred
   * and a screen modal will disappear. It will also mean new modals can appear again
   * @returns void
   */
  const triggerSecurityExit = useCallback(() => {
    modalManager.hideSecurityModal(map);
    map.unblur();
  }, [map]);

  /**
   * handles an "airport security geofence entrance" geofence, which effectively means the map will be blurred
   * and a screen modal will appear. While this is active, no further modals will appear. (unless force is used)
   * @returns void
   */
  const triggerSecurityEntrance = useCallback(() => {
    modalManager.showSecurityModal(map);
    map.blur();
  }, [map]);

  /**
   * handleGeofenceEntrance will be called when a user ENTERS a geofence geometry.
   *
   * @param {object} obj object
   * @param {object} obj.config configuration object containing all geofence metadata entries.
   */
  const handleGeofenceEntrance = useCallback(
    ({ config }: { config: GeofenceAreaOptions }) => {
      if (config.eventType === "security") {
        if (
          config.interaction === "entrance" ||
          config.interaction === "both"
        ) {
          triggerSecurityEntrance();
        } else {
          triggerSecurityExit();
        }
      }
    },
    [triggerSecurityEntrance, triggerSecurityExit]
  );

  /**
   * handleGeofenceExit will be called when a user EXITS a geofence geometry.
   *
   * @param {object} obj object
   * @param {object} obj.config configuration object containing all geofence metadata entries.
   */
  const handleGeofenceExit = useCallback(
    ({ config }: { config: GeofenceAreaOptions }) => {
      if (config.eventType === "security" && config.interaction === "both") {
        triggerSecurityExit();
      }
    },
    [triggerSecurityExit]
  );

  return {
    followUserFunction,
    timeoutSetStaticChevron,
    handleStartRouteClick,
    handleDetectionUserIsIdle,
    handleDetectionUserIsActive,
    initialiseOverviewMode,
    refreshOverviewData,
    mode,
    handleGeofenceEntrance,
    handleGeofenceExit,
  };
};

export { useMapHandlers };

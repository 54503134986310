import type { LayerDelegate } from "@livingmap/core-mapping";
import { USER_LOCATION_LAYER_ID } from "../components/Map/plugins/controls/user-location-control";
import { convertHexWithOpacity, getMapBranding, getUIConfig } from "@utils";

interface WalkingCircleConfig {
  minutes: number;
  zoomLevel: number;
  zoomBuffer: number;
}

const LINE_WIDTH_THICK = 10;
const LINE_WIDTH_THIN = 2;
const KILOMETERS_WALKED_IN_A_MINUTE = 4 / 60;
const NUMBER_OF_POINTS_ON_POLYGON = 128;
const NORTH_EAST_LABEL_START = 16;
const NORTH_EAST_LABEL_END = 4;
const SOUTH_WEST_LABEL_START = 1.68;
const SOUTH_WEST_LABEL_END = 1.32;
const RADIUS_CIRCLE_CONSTANT_1 = 111.32;
const RADIUS_CIRCLE_CONSTANT_2 = 110.574;
const DEGREES_ON_A_LINE = 180;
const DEGREES_IN_A_CIRCLE = 360;
const RIGHT_ANGLE = 90;
const BEARING_MODIFIER_PER_POINT = 0.4;
const INITIAL_BEARING_MODIFIER = 36.4;

const DEFAULT_WALING_CIRCLE_CONFIG: WalkingCircleConfig[] = [
  { minutes: 5, zoomLevel: 16.58, zoomBuffer: 1 },
  { minutes: 10, zoomLevel: 15.62, zoomBuffer: 0.5 },
  { minutes: 15, zoomLevel: 15, zoomBuffer: 0.5 },
  { minutes: 20, zoomLevel: 14.62, zoomBuffer: 0.25 },
  { minutes: 25, zoomLevel: 14.36, zoomBuffer: 0.125 },
];

export const drawWalkingForDefaultConfigCircle = (
  layerDelegate: LayerDelegate,
  location: mapboxgl.LngLat,
  bearing: number,
  floorId: number
) => {
  DEFAULT_WALING_CIRCLE_CONFIG.forEach((circle) =>
    drawWalkingCircle(layerDelegate, circle, location, bearing, floorId)
  );
};

const drawWalkingCircle = (
  layerDelegate: LayerDelegate,
  distanceInfo: WalkingCircleConfig,
  location: mapboxgl.LngLat,
  bearing: number,
  floorId: number
) => {
  const text = `${distanceInfo.minutes.toString()} minute walk`;
  const bearingModifier = rotateDistanceLabels(bearing);
  const { circle, labelCurveNE, labelCurveSW } = createGeoJSONCircle(
    location,
    distanceInfo.minutes * KILOMETERS_WALKED_IN_A_MINUTE,
    bearingModifier,
    floorId
  );
  const theme = getMapBranding()!;

  layerDelegate.addSource("circle" + distanceInfo.minutes, circle);
  layerDelegate.addSource("labelNE" + distanceInfo.minutes, labelCurveNE);
  layerDelegate.addSource("labelSW" + distanceInfo.minutes, labelCurveSW);

  const uiConfig = getUIConfig();

  const walkingCircleColour =
    uiConfig && convertHexWithOpacity(uiConfig.popupIconColor, 0.5);

  layerDelegate.addLayer(
    {
      id: distanceInfo.minutes + "_circle_layer",
      type: "line",
      source: "circle" + distanceInfo.minutes,
      layout: {},
      paint: {
        "line-color": walkingCircleColour!,
        "line-width": {
          stops: [
            [distanceInfo.zoomLevel - distanceInfo.zoomBuffer, LINE_WIDTH_THIN],
            [distanceInfo.zoomLevel, LINE_WIDTH_THICK],
            [distanceInfo.zoomLevel + distanceInfo.zoomBuffer, LINE_WIDTH_THIN],
          ],
        },
      },
    },
    USER_LOCATION_LAYER_ID
  );

  layerDelegate.addLayer({
    id: distanceInfo.minutes + "_label_layerNE",
    layout: {
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-anchor": "center",
      "symbol-placement": "line",
      "text-field": text,
      "text-size": 16,
      "text-font": [theme.font!.regular],
    },
    paint: {
      "text-color": "rgba(0,0,0,0.8)",
      "text-halo-color": "rgba(255,255,255,0.8)",
      "text-halo-width": 2,
    },
    source: "labelNE" + distanceInfo.minutes,
    type: "symbol",
  });

  layerDelegate.addLayer({
    id: distanceInfo.minutes + "_label_layerSW",
    layout: {
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-anchor": "center",
      "symbol-placement": "line",
      "text-field": text,
      "text-size": 16,
      "text-font": [theme.font!.regular],
    },
    paint: {
      "text-color": "rgba(0,0,0,0.8)",
      "text-halo-color": "rgba(255,255,255,0.8)",
      "text-halo-width": 2,
    },
    source: "labelSW" + distanceInfo.minutes,
    type: "symbol",
  });
};

function rotateDistanceLabels(bearing: number): number {
  if (bearing < 0) bearing = DEGREES_IN_A_CIRCLE + bearing - DEGREES_ON_A_LINE;
  let bearingModifier = INITIAL_BEARING_MODIFIER;
  if (bearing > RIGHT_ANGLE) {
    while (bearing > RIGHT_ANGLE) {
      bearingModifier -= BEARING_MODIFIER_PER_POINT;
      bearing -= 1;
    }
  } else if (bearing < RIGHT_ANGLE) {
    bearingModifier = -bearingModifier;
    while (bearing < RIGHT_ANGLE) {
      bearingModifier += BEARING_MODIFIER_PER_POINT;
      bearing += 1;
    }
  }
  return bearingModifier;
}

function createGeoJSONCircle(
  center: mapboxgl.LngLat,
  radiusInKm: number,
  bearingModifier: number,
  floorId: number
): any {
  const labelPointsNE = [
    Math.floor(NUMBER_OF_POINTS_ON_POLYGON / NORTH_EAST_LABEL_START) +
      bearingModifier,
    Math.floor(NUMBER_OF_POINTS_ON_POLYGON / NORTH_EAST_LABEL_END) +
      bearingModifier,
  ];
  const labelPointsSW = [
    Math.floor(NUMBER_OF_POINTS_ON_POLYGON / SOUTH_WEST_LABEL_START) +
      bearingModifier,
    Math.floor(NUMBER_OF_POINTS_ON_POLYGON / SOUTH_WEST_LABEL_END) +
      bearingModifier,
  ];

  const circleCoordinates = [];
  const labelCurveNECoordinates = [];
  const labelCurveSWCoordinates = [];
  const distanceX =
    radiusInKm /
    (RADIUS_CIRCLE_CONSTANT_1 *
      Math.cos((center.lat * Math.PI) / DEGREES_ON_A_LINE));
  const distanceY = radiusInKm / RADIUS_CIRCLE_CONSTANT_2;

  let theta;
  let x;
  let y;
  for (let i = 0; i < NUMBER_OF_POINTS_ON_POLYGON; i++) {
    theta = (i / NUMBER_OF_POINTS_ON_POLYGON) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    if (i > labelPointsNE[0] && i < labelPointsNE[1]) {
      labelCurveNECoordinates.push([center.lng + x, center.lat + y]);
    } else if (i > labelPointsSW[0] && i < labelPointsSW[1]) {
      labelCurveSWCoordinates.push([center.lng + x, center.lat + y]);
    }
    circleCoordinates.push([center.lng + x, center.lat + y]);
  }
  circleCoordinates.push(circleCoordinates[0]);

  return {
    circle: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [circleCoordinates],
            },
            properties: {
              floor_id: floorId,
            },
          },
        ],
      },
    },
    labelCurveNE: {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: labelCurveNECoordinates,
        },
        properties: {
          floor_id: floorId,
        },
      },
    },
    labelCurveSW: {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: labelCurveSWCoordinates,
        },
        properties: {
          floor_id: floorId,
        },
      },
    },
  };
}

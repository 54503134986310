import { Icon } from "@livingmap/core-ui-v2";

import styles from "./PassportControlModal.module.css";

const PassportControlModal = () => {
  return (
    <div className={styles.container}>
      <Icon type="PassportControlIcon" width={63} height={65} />
      <h2 className={styles.header}>Security area</h2>
      <p className={styles.body}>
        You may need your passport and boarding card ready
      </p>
    </div>
  );
};

export default PassportControlModal;

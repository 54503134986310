import { ParsedRouteData } from "@redux/slices/routingSlice";

/**
 * parse route serves as the core data set on which we format and manipulate further.
 * it group all geometries first by floor, and then if they are connected.
 *
 * @example of the data structure:
 * [
 *  {
 *    floorId: 4, data: [
 *      [f1, f2, f3, f4],   // <-- 1 part of the linstring on floor 4
 *     [f22, f23, f24, 25] // <-- another part of the route, on the same floor but unconnected to the first section.
 *    ]
 *  },
 *  ...
 * ]
 *
 *
 * @returns parsedRouteData
 */
export const parseRouteGeometryData = (
  routeData: GeoJSON.FeatureCollection<GeoJSON.LineString>
): {
  flattenedRouteCoordinates: ParsedRouteData;
  floorsInRoute: number[];
} => {
  if (!routeData.features) throw new Error("routeData has no features.");

  const flattenedRouteCoordinates: ParsedRouteData = [];
  let pendingTransaction: GeoJSON.Feature<GeoJSON.Point>[] = [];
  let currentFloorTransactionId: number | null = null;
  let floorsInRoute: number[] = [];

  const flushPendingTransactions = () => {
    if (pendingTransaction.length === 0) return;

    const floorData = flattenedRouteCoordinates.find(
      (g) => g.floorId === currentFloorTransactionId
    );

    if (!floorData) {
      flattenedRouteCoordinates.push({
        floorId: currentFloorTransactionId!,
        data: [pendingTransaction],
      });
    } else {
      floorData.data.push(pendingTransaction);
    }
    pendingTransaction = [];
  };

  routeData.features.forEach((feature, idx) => {
    if (!feature) return;
    if (!feature.properties || !feature.geometry) return;

    const { floorId, sequence, costTime } = feature.properties;
    if (floorId && !floorsInRoute.includes(floorId)) {
      floorsInRoute.push(floorId);
    }

    // we detect a new floor from the previous segment. now we empty the transaction list
    // and start filling it again with new floor data.
    if (currentFloorTransactionId !== floorId) flushPendingTransactions();

    currentFloorTransactionId = floorId;

    feature.properties = { ...feature.properties, costTime, sequence };

    const coordinateMap: GeoJSON.Feature<GeoJSON.Point>[] =
      feature.geometry.coordinates.map((coordinates: any) => {
        return { ...feature, geometry: { coordinates, type: "Point" } };
      });

    pendingTransaction.push(...coordinateMap);
    if (idx === routeData.features.length - 1) flushPendingTransactions();
  });

  return { flattenedRouteCoordinates, floorsInRoute };
};

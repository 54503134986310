import type LivingMap from "@livingmap/core-mapping";
import Mobile from "@mobile";

export const entranceOnlyGeofenceCallback =
  (geofenceName: string, geofenceId: number, eventType: string) =>
  (hasGoneIn: boolean, _: LivingMap) => {
    if (!hasGoneIn) return;
    Mobile.sharedSendMessageEmitter("BROADCAST_SHOW_POPUP", {
      geofenceId,
      geofenceName,
      eventType,
    });
  };

export const exitOnlyGeofenceCallback =
  (geofenceName: string, geofenceId: number, eventType: string) =>
  (hasGoneIn: boolean, _: LivingMap) => {
    if (!hasGoneIn) return;
    Mobile.sharedSendMessageEmitter("BROADCAST_HIDE_POPUP", {
      geofenceId,
      geofenceName,
      eventType,
    });
  };

export const entranceAndExitGeofenceCallback =
  (geofenceName: string, geofenceId: number, eventType: string) =>
  (hasGoneIn: boolean, _: LivingMap) => {
    if (hasGoneIn) {
      Mobile.sharedSendMessageEmitter("BROADCAST_SHOW_POPUP", {
        geofenceId,
        geofenceName,
        eventType,
      });
    } else {
      Mobile.sharedSendMessageEmitter("BROADCAST_HIDE_POPUP", {
        geofenceId,
        geofenceName,
        eventType,
      });
    }
  };

import type LivingMap from "@livingmap/core-mapping";

import DynamicGeofenceArea from "./dynamic-geofence-area";
import DynamicGeofenceError from "./dynamic-geofence-error";
import DestinationCompleteStrategy from "./destination-complete-strategy";
import DynamicGeofenceStrategy from "./dynamic-geofence-strategy";
import FloorChangeStrategy from "./floor-change-strategy";

const DESTINATION = "destination";
const FLOORCHANGE = "floor_change";

export default class DynamicGeofenceFactory {
  private LMMap: LivingMap;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
  }

  public create(geoJSONFeature: any): DynamicGeofenceArea {
    const dynamicStrategy = this.resolveGeofenceStrategy(geoJSONFeature);
    geoJSONFeature.properties.floor_id = geoJSONFeature.properties.floorId;
    return new DynamicGeofenceArea(geoJSONFeature, dynamicStrategy);
  }

  private resolveGeofenceStrategy(
    geoJSONFeature: any
  ): DynamicGeofenceStrategy {
    const geofenceType = geoJSONFeature.properties.geofenceType;

    switch (geofenceType) {
      case FLOORCHANGE:
        return new FloorChangeStrategy(geoJSONFeature, this.LMMap);
      case DESTINATION:
        return new DestinationCompleteStrategy();
      default:
        throw new DynamicGeofenceError(
          `GeofenceType does not exist > ${geofenceType}`
        );
    }
  }
}

import LivingMap from "@livingmap/core-mapping";

import DynamicGeofenceStrategy from "./dynamic-geofence-strategy";
import { FloorDirection } from "../controls/floor-control";
import type { RoutingControl, GeofenceControl } from "../index";
import { Logger, getFloorById } from "@utils";
import { Floor } from "@redux/services/types";
import { store } from "@redux/store";
import { setDialogNotifications } from "@redux/slices/uiSlice";
import { PLUGIN_IDS, EventTypes } from "../types";

export default class FloorChangeStrategy extends DynamicGeofenceStrategy {
  private readonly LMMap: LivingMap;

  private readonly triggerFloor: number;
  private readonly destinationFloor: number;

  private floorName: string | undefined;
  private geofenceControl: GeofenceControl;

  constructor(geoJSONFeature: any, LMMap: LivingMap) {
    super();
    this.triggerFloor = geoJSONFeature.properties.floorId;
    this.destinationFloor = geoJSONFeature.properties.destinationFloorId;
    this.LMMap = LMMap;

    this.geofenceControl = LMMap.getPluginById<GeofenceControl>(
      PLUGIN_IDS.GEOFENCE
    );
  }

  public activate(): void {
    this.handleDirection(true);
  }

  public deactivate(): void {
    this.handleDirection(false);
  }

  private handleDirection(direction: boolean): void {
    const floorDirection =
      this.triggerFloor < this.destinationFloor
        ? FloorDirection.UP
        : FloorDirection.DOWN;

    const floorConfig = getFloorById(this.destinationFloor);

    if (floorConfig) {
      this.floorName = floorConfig.short_name;
    }

    this.triggerFloorChange(
      direction,
      this.floorName!,
      floorDirection,
      this.LMMap
    );
    this.areaTriggered = direction;
  }

  private deactivationCallback = (newFloor: Floor) => {
    if (this.areaTriggered && newFloor.id === this.destinationFloor) {
      this.deactivate();
      this.triggerFloorChangeExit(this.floorName!);
    }
  };

  public startDeactivationListener(): void {
    this.LMMap.on(EventTypes.FLOOR_CHANGED, this.deactivationCallback);
  }

  public stopDeactivationListener(): void {
    this.LMMap.removeListener(
      EventTypes.FLOOR_CHANGED,
      this.deactivationCallback
    );
  }

  private triggerFloorChange(
    direction: boolean,
    destinationFloorId: string,
    floorDirection: FloorDirection,
    LMMap: LivingMap
  ): void {
    const routingControl = LMMap.getPluginById<RoutingControl>(
      PLUGIN_IDS.ROUTING
    );
    if (!direction) {
      Logger.log("exiting floor change geofence");
      this.geofenceControl.setInDynamicGeofence(false);

      store.dispatch(setDialogNotifications({ floorDirectionAlert: null }));

      if (routingControl !== null) {
        routingControl.setVisualisationIgnoreFloors(false);
      }
      return;
    }

    Logger.log(`floor change to floor ${destinationFloorId}`);
    this.geofenceControl.setInDynamicGeofence(true);

    store.dispatch(
      setDialogNotifications({
        floorDirectionAlert: {
          destinationFloor: destinationFloorId,
          direction: floorDirection,
        },
      })
    );

    if (routingControl !== null) {
      routingControl.setVisualisationIgnoreFloors(true);
    }
  }

  private triggerFloorChangeExit(destinationFloorId: string): void {
    Logger.log(`now on floor ${destinationFloorId}`);

    store.dispatch(
      setDialogNotifications({
        exitFloorChangeAlert: { destinationFloor: destinationFloorId },
      })
    );

    window.setTimeout(() => {
      store.dispatch(setDialogNotifications({ exitFloorChangeAlert: null }));
    }, 5000);
  }
}

/* tslint:disable no-unused-variable  */
export class CustomEventAPICallbackFormat {
  private id: string;
  private status!: "Success" | "Failure";
  private payload: any;

  constructor(id: string) {
    this.id = id;
  }

  public setResponse(status: "Success" | "Failure", payload?: any): void {
    this.status = status;
    this.payload = payload;
  }
}

import type LivingMap from "@livingmap/core-mapping";
import { getFullStylesUrl } from "../../utils";

export enum AccessibilityStyles {
  AUTISM = "autism",
  DYSLEXIA = "dyslexia",
  HIGH_CONTRAST = "high-contrast",
  HIGH_CONTRAST_1_25 = "high-contrast-1.25x",
  HIGH_CONTRAST_1_5 = "high-contrast-1.5x",
  STYLES = "styles",
}

export class Utility {
  private readonly LMMap: LivingMap;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
  }

  /**
   * Sets the style of the map to one of the {@link AccessibilityStyles}
   *
   * @param styleType
   */
  public setMapStyle(styleType: AccessibilityStyles) {
    const availableStyles = Object.values(AccessibilityStyles);

    if (!availableStyles.includes(styleType)) {
      throw new Error(`Map style type is not supported. Supported types are:
        ${availableStyles.reduce(
          (final, current) => `${final} "${current}"\n`,
          "\n"
        )}
      `);
    } else {
      const newStyleURL = getFullStylesUrl(styleType);
      this.LMMap.switchStylesheet(newStyleURL);
    }
  }
}

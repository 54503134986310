import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";

import styles from "./Modal.module.css";

interface Props {
  isOpen: boolean;
  maxWidth?: string;
  overlayClassName?: string; // For when the overlay style needs to be changed i.e. the passport control modal needs a blurred background
  onOverlayClick?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  isOpen,
  maxWidth,
  overlayClassName,
  onOverlayClick,
  children,
}) => {
  return (
    <>
      <Transition appear show={isOpen} as={React.Fragment}>
        {/* Purposely don't trigger anything onClose, as we don't want a click on the overlay to close the modal */}
        <Dialog as="div" className={styles.dialog} onClose={() => {}}>
          <Transition.Child
            as={React.Fragment}
            enter={styles.enter}
            enterFrom={styles.enterFrom1}
            enterTo={styles.enterTo1}
            leave={styles.leave}
            leaveFrom={styles.leaveFrom1}
            leaveTo={styles.leaveTo1}
          >
            <Dialog.Overlay
              className={classNames(styles.overlay, overlayClassName)}
              onClick={onOverlayClick}
            />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter={styles.enter}
            enterFrom={styles.enterFrom2}
            enterTo={styles.enterTo2}
            leave={styles.leave}
            leaveFrom={styles.leaveFrom2}
            leaveTo={styles.leaveTo2}
          >
            <Dialog.Panel style={{ maxWidth }} className={styles.wrapper}>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;

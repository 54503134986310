import { RouteLocation } from "@components/Map/plugins/controls/routing-control";
import { setRouteViaPoints } from "@redux/slices/routingSlice";
import { store } from "@redux/store";

export const removeViaPoint = (viaPointToRemove: RouteLocation): void => {
  const state = store.getState();
  const { options } = state.routing;

  if (!options || !options.via) return;

  const filteredViaPoints = options.via.filter((viaPoint) => {
    if (viaPoint.coordinate && viaPointToRemove.coordinate) {
      const xyIsDifferent =
        viaPoint.coordinate!.x !== viaPointToRemove.coordinate!.x ||
        viaPoint.coordinate!.y !== viaPointToRemove.coordinate!.y;

      let floorIsDifferent =
        (viaPoint.coordinate!.floor && !viaPointToRemove.coordinate!.floor) ||
        (!viaPoint.coordinate!.floor && viaPointToRemove.coordinate!.floor);

      if (viaPoint.coordinate.floor && viaPointToRemove.coordinate.floor) {
        floorIsDifferent =
          viaPoint.coordinate.floor!.id !==
          viaPointToRemove.coordinate.floor!.id;
      }

      return xyIsDifferent || floorIsDifferent;
    }

    if (viaPoint.type && viaPointToRemove.type) {
      return viaPoint.type !== viaPointToRemove.type;
    }

    if (viaPoint.featureId && viaPointToRemove.featureId) {
      return viaPoint.featureId !== viaPointToRemove.featureId;
    }

    return false;
  });

  store.dispatch(setRouteViaPoints(filteredViaPoints));
};

import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
} from "@livingmap/core-mapping";
import type { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import type { MapMouseEvent } from "mapbox-gl";

import type GeofenceControl from "./geofence-control";
import { Logger } from "@utils";

export default class DebugControl extends LivingMapPlugin {
  private layerDelegate: LayerDelegate;
  private geofenceControl: GeofenceControl | undefined;

  constructor(id: string, LMMap: LivingMap, geofenceControl?: GeofenceControl) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.layerDelegate = this.LMMap.getLayerDelegate();
    this.geofenceControl = geofenceControl;
  }

  activate() {
    this.LMMap.getMapboxMap().on("click", this.logMapCoordinate);
    this.addMapboxDebugVisuals();
    this.createGeofenceLayer();

    const staticGeofences = this.geofenceControl?.getStaticGeofenceGeometries();

    if (staticGeofences) {
      this.addGeofenceGeometries(staticGeofences);
    }
  }

  deactivate() {
    this.LMMap.getMapboxMap().off("click", this.logMapCoordinate);
  }

  refresh() {
    return;
  }

  /**
   * Function will add some basic mapbox debug visuals on top of the map. It will:
   * - Visualise collionBoxes on Map elements
   * - Visualise Tile boundaries
   * @private
   */
  private addMapboxDebugVisuals() {
    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.showCollisionBoxes = true;
    mapInstance.showTileBoundaries = true;
  }

  /**
   * Given a click on the map, this function will log the clicked coordinates to the browser console
   * @private
   * @param e
   */
  private logMapCoordinate(e: MapMouseEvent) {
    Logger.log("Point: ", e.point);
    Logger.log("LngLat: ", e.lngLat.toArray());
  }

  /**
   * Initial setup for the geofence visualisation layer.
   * On debug mode we allow the map to visualise the normally hidden geometry layer.
   * @private
   */
  private createGeofenceLayer() {
    const mapInstance = this.LMMap.getMapboxMap();
    if (mapInstance.getSource("geofence-areas") !== undefined) return;

    this.layerDelegate.addSource("geofence-areas", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.layerDelegate.addLayer({
      id: "geofence-area-layer",
      type: "fill",
      source: "geofence-areas",
      layout: {},
      paint: {
        "fill-color": [
          "match",
          ["get", "geofence_type"],
          "dynamic",
          "#fbb03b",
          "static",
          "#088",
          /* other */ "pink",
        ],
        "fill-opacity": 0.8,
      },
    });
  }

  /**
   * Given the intial setup has been done {@link createGeofenceLayer},
   * This function will add data the styled layer. Call this whenever a new (dynamic) geofence needs to appear on the map.
   * @param data
   */
  private addGeofenceGeometries(
    data: FeatureCollection<Geometry, GeoJsonProperties>
  ) {
    const proxy = this.layerDelegate.getSourceProxy("geofence-areas");
    const existingdata = proxy!.getData();
    proxy?.setData({
      type: "FeatureCollection",
      features: [...existingdata.features, ...data.features],
    });
  }
}

import { Icon } from "@livingmap/core-ui-v2";
import { animated, useSpring } from "react-spring";

import { NotificationIcons } from "@redux/slices/uiSlice";
import { getIcon } from "@components/Notifications/Notifications";
import styles from "./CTABanner.module.css";
import { useRef } from "react";

interface Props {
  icon: NotificationIcons | null;
  title: string | null;
  onClick: () => void;
  minimized: boolean;
}

const CTABanner: React.FC<Props> = ({ icon, title, onClick, minimized }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerHeight =
    (containerRef.current && containerRef.current.offsetHeight - 1) || 0;
  const animatedStyles = useSpring({
    height: !minimized ? 0 : containerHeight,
  });

  return (
    <animated.div style={animatedStyles}>
      <div className={styles.container} ref={containerRef}>
        {icon && (
          <div className={styles.iconWrapper}>
            <Icon type={getIcon(icon)} width={24} height={24} />
          </div>
        )}
        <div className={styles.contentWrapper}>
          <span>{title}</span>
          <button className={styles.button} onClick={onClick}>
            Open
          </button>
        </div>
      </div>
    </animated.div>
  );
};

export default CTABanner;

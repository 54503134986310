import LivingMap, { LivingMapPlugin } from "@livingmap/core-mapping";
import type DynamicGeofenceArea from "../geofence/dynamic-geofence-area";
import DynamicGeofenceFactory from "../geofence/dynamic-geofence-factory";
import GeofenceArea from "../geofence/geofence-area";
import type LMUserLocation from "../routing/LMUserLocation";
import type DebugControl from "./debug-control";
import {
  defaultRetryOnStrategy,
  AutoRetryFetchInstance,
  getUrl,
  URLTypes,
  entranceAndExitGeofenceCallback,
  entranceOnlyGeofenceCallback,
  exitOnlyGeofenceCallback,
} from "@utils";
import { PLUGIN_IDS } from "../types";
import { setDialogNotifications } from "@redux/slices/uiSlice";
import { store } from "@redux/store";

class GeofencePlugin extends LivingMapPlugin {
  public activated = false;

  private geofenceList: GeofenceArea[];
  private dynamicGeofenceList: DynamicGeofenceArea[];
  private dynamicGeofenceFactory: DynamicGeofenceFactory;
  private inDynamicGeofence = false;
  private staticGeofenceData: any;

  constructor(id: string, LMMap: LivingMap) {
    super(id, LMMap);
    this.geofenceList = [];
    this.dynamicGeofenceList = [];
    this.dynamicGeofenceFactory = new DynamicGeofenceFactory(LMMap);
  }

  activate(): void {
    AutoRetryFetchInstance.fetch(getUrl(URLTypes.GEOFENCE), "geofence_fetch", {
      retryOn: defaultRetryOnStrategy,
    })
      .then((response) => response.json())
      .then((data) => {
        const features = data.features.map((f: any) => {
          f.properties = { ...f.properties, geofence_type: "static" };
          return f;
        });

        this.staticGeofenceData = features;

        data.features = features;
        this.createGeofences(data);
      });
  }

  private getInteractionCallback(
    interaction: string,
    name: string,
    id: number,
    eventType: string
  ): any {
    if (interaction === "entrance") {
      return entranceOnlyGeofenceCallback(name, id, eventType);
    } else if (interaction === "exit") {
      return exitOnlyGeofenceCallback(name, id, eventType);
    }
    return entranceAndExitGeofenceCallback(name, id, eventType);
  }

  public addGeofenceArea(geoJSONFeature: any): void {
    const interaction = geoJSONFeature.properties.interaction;
    const name = geoJSONFeature.properties.name;
    const areaId = geoJSONFeature.id;
    const eventType = geoJSONFeature.properties.event_type;
    const areaConfig = {
      id: geoJSONFeature.id,
      name: geoJSONFeature.properties.name,
      activationCount: geoJSONFeature.properties.activation_count,
      callback: this.getInteractionCallback(
        interaction,
        name,
        areaId,
        eventType
      ),
      eventType,
      interaction,
    };
    const geofenceArea = new GeofenceArea(
      geoJSONFeature,
      this.LMMap,
      areaConfig
    );
    this.geofenceList.push(geofenceArea);
  }

  public addDynamicGeofenceAreas(
    geofenceAreaJson: any,
    replaceExisting: boolean
  ): void {
    if (replaceExisting) {
      store.dispatch(
        setDialogNotifications({
          floorDirectionAlert: null,
          exitFloorChangeAlert: null,
          routeCompletedAlert: false,
        })
      );

      this.dynamicGeofenceList.forEach((dynamicGeofence) =>
        dynamicGeofence.deactivate()
      );
      this.dynamicGeofenceList = [];
    }
    const features = geofenceAreaJson.features as Array<
      GeoJSON.Feature<GeoJSON.GeometryObject>
    >;
    features.forEach((geofenceArea) => {
      const dynamicGeofenceArea =
        this.dynamicGeofenceFactory.create(geofenceArea);
      this.dynamicGeofenceList.push(dynamicGeofenceArea);
    });
    const debug = this.LMMap.getPluginById<DebugControl>(
      PLUGIN_IDS.DEBUG
    ) as any;
    if (debug) debug.addGeofenceGeometries(geofenceAreaJson);
  }

  public isInDynamicGeofence(): boolean {
    return this.inDynamicGeofence;
  }

  public setInDynamicGeofence(value: boolean): void {
    this.inDynamicGeofence = value;
  }

  private createGeofences(featureCollection: any): void {
    // adding the initial geofence areas
    if (featureCollection && featureCollection.features) {
      featureCollection.features.forEach((feature: any) => {
        this.addGeofenceArea(feature);
      });
    }

    const debug = this.LMMap.getPluginById<DebugControl>(
      PLUGIN_IDS.DEBUG
    ) as any;
    if (debug) debug.addGeofenceGeometries(featureCollection);
  }

  public handleUserLocationForGeofences(currentUserLocation: LMUserLocation) {
    this.geofenceList.forEach((geofenceArea) => {
      geofenceArea.activateGeofenceIfUserIsInArea(currentUserLocation);
    });
  }

  public handleUserLocationForDynamicGeofence(
    snappedUserLocation: LMUserLocation
  ) {
    this.dynamicGeofenceList.forEach((dynamicGeofenceArea) => {
      dynamicGeofenceArea.activateGeofenceIfUserIsInArea(snappedUserLocation);
    });
  }

  public getStaticGeofenceGeometries() {
    return this.staticGeofenceData;
  }
}

export default GeofencePlugin;

import FloorDirectionAlert from "./Content/FloorDirectionAlert";
import ExitFloorChangeAlert from "./Content/ExitFloorChangeAlert";
import FloorChangingAlert from "./Content/FloorChangingAlert";
import RouteCompletedAlert from "./Content/RouteCompletedAlert";
import { DialogNotificationOptions as Props } from "@redux/slices/uiSlice";
import styles from "./DialogNotifications.module.css";

const DialogNotifications: React.FC<Props> = ({
  floorDirectionAlert,
  exitFloorChangeAlert,
  floorChangingAlert,
  routeCompletedAlert,
}) => {
  return (
    <div className={styles.container}>
      {floorDirectionAlert && <FloorDirectionAlert {...floorDirectionAlert} />}
      {exitFloorChangeAlert && (
        <ExitFloorChangeAlert {...exitFloorChangeAlert} />
      )}
      {routeCompletedAlert && <RouteCompletedAlert />}
      {floorChangingAlert && <FloorChangingAlert />}
    </div>
  );
};

export default DialogNotifications;

import type LivingMap from "@livingmap/core-mapping";

import type { RoutingControl } from "@components/Map/plugins";
import type { RouteLocation } from "@components/Map/plugins/controls/routing-control";
import { PLUGIN_IDS, RouteMode } from "@components/Map/plugins/types";
import { getFloorById } from "@utils";
import { CustomEventAPICallbackFormat } from "../custom-api-callback-format";
import {
  ApiAction,
  ApiEventTypes,
  APIRouteLocation,
  ExtractActionPayload,
} from "../types";
import { store } from "@redux/store";
import {
  setRoutingDestinationData,
  setRoutingOverviewData,
} from "@redux/slices/routingSlice";
import SnapManager from "@components/Map/plugins/routing/snap-manager";

type RequestRouteData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.REQUEST_ROUTE_JOURNEY
>;

type RequestFilteredRouteData = ExtractActionPayload<
  ApiAction,
  ApiEventTypes.REQUEST_FILTERED_ROUTE_JOURNEY
>;

export class Routing {
  private readonly routingControl: RoutingControl;
  private readonly LMMap: LivingMap;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
    this.routingControl = this.LMMap.getPluginById<RoutingControl>(
      PLUGIN_IDS.ROUTING
    );
  }

  /**
   * Requests and renders a route based on various options that can be passed in
   *
   * @param data
   * @param eventId
   * @param apiCallback
   * @returns eventId
   */
  public requestRouteJourney(
    data: RequestRouteData,
    eventId: string,
    apiCallback?: (res: CustomEventAPICallbackFormat) => void
  ) {
    const state = store.getState();
    const { routing } = state;

    const options = data.options || ({} as RequestRouteData["options"]);

    function callback(routing: { data: any; error: string | null }): void {
      const callbackResponse = new CustomEventAPICallbackFormat(eventId);

      if (typeof routing.error === "string") {
        callbackResponse.setResponse("Failure", routing);
      } else if (typeof routing.data === "object") {
        callbackResponse.setResponse("Success", routing.data.routeMetadata[0]);
      }

      if (apiCallback) apiCallback!(callbackResponse);
      return;
    }

    if (!this.routingControl) throw new Error("Routing not available.");

    const origin = this.convertApiRouteLocation(data.origin);
    const destination = this.convertApiRouteLocation(data.destination);

    const hasPreviousRoutingData = Object.keys(routing.route).length > 0;

    // checks if the routing control is currently either requesting a route or already has one active on the map
    const isInRoutingMode =
      this.routingControl.getRoutingMode() === RouteMode.ROUTE;
    const shouldMapPanToRoute = !hasPreviousRoutingData && !isInRoutingMode;

    this.routingControl.requestRoute(origin, destination, {
      via: options!.via,
      callback,
      destinationAreaName: options!.destinationAreaName,
      destinationName: options!.destinationName,
      shouldMapPanToRoute,
      routeLabels: options!.routeLabels,
    });

    return eventId;
  }

  /**
   * (Offline) Requests and renders a route based on various options that can be passed in
   *
   * @param data
   * @param eventId
   * @param apiCallback
   * @returns eventId
   */
  public requestFilteredRouteJourney(
    data: RequestFilteredRouteData,
    eventId: string,
    apiCallback?: (res: CustomEventAPICallbackFormat) => void
  ) {
    const state = store.getState();
    const { routing } = state;

    const options = data.options || ({} as RequestFilteredRouteData["options"]);

    function callback(routing: { data: any; error: string | null }): void {
      const callbackResponse = new CustomEventAPICallbackFormat(eventId);

      if (typeof routing.error === "string") {
        callbackResponse.setResponse("Failure", routing);
      } else if (typeof routing.data === "object") {
        callbackResponse.setResponse("Success", routing.data.routeMetadata[0]);
      }

      if (apiCallback) apiCallback(callbackResponse);
      return;
    }

    if (!this.routingControl) throw new Error("Routing not available.");

    const hasPreviousRoutingData = Object.keys(routing.route).length > 0;

    // checks if the routing control is currently either requesting a route or already has one active on the map
    const isInRoutingMode =
      this.routingControl.getRoutingMode() === RouteMode.ROUTE;
    const shouldMapPanToRoute = !hasPreviousRoutingData && !isInRoutingMode;

    this.routingControl.requestFilteredRoute(data.origin, data.destination, {
      via: options!.via,
      callback,
      destinationAreaName: options!.destinationAreaName,
      destinationName: options!.destinationName,
      shouldMapPanToRoute,
    });

    return eventId;
  }

  /**
   * Clears the current route
   *
   * @param eventId
   * @returns eventId
   */
  public clearRoute(eventId: string) {
    if (!this.routingControl) throw new Error("Routing not available.");

    this.routingControl.clear();

    setRoutingOverviewData({
      totalLength: 0,
      totalTime: 0,
    });

    setRoutingDestinationData({
      destinationAreaName: "",
      destinationExpiryTime: "",
      destinationName: "",
    });

    return eventId;
  }

  /**
   * Get the remaining time (km) and distance (min) for the active route
   */
  public getRouteStatus() {
    const state = store.getState();
    const { routing } = state;

    const overviewData = routing.routingOverviewData;
    const latestProgressDescriptor =
      SnapManager.getInstance().getLatestProgressDescriptor();

    const proportionUncompletedRoute = latestProgressDescriptor
      ? 1 - latestProgressDescriptor.percentage / 100
      : 0;

    return {
      remaining_distance:
        (overviewData?.totalLength &&
          overviewData.totalLength * proportionUncompletedRoute) ||
        0,
      remaining_time: latestProgressDescriptor?.timeLeft || 0,
    };
  }

  /**
   * Toggle whether a route should include steps or not
   *
   * @param isStepFree
   */
  public setStepFreeRouting(isStepFree: boolean) {
    this.routingControl.setStepFreeRouting(isStepFree);
  }

  private convertApiRouteLocation(apiRouteLocation: APIRouteLocation) {
    const output = { ...apiRouteLocation } as any;

    if (output.coordinate) {
      output.coordinate.floor = getFloorById(
        apiRouteLocation.coordinate!.floorId
      );
    }

    return output as RouteLocation;
  }
}

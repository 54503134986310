import DynamicGeofenceStrategy from "./dynamic-geofence-strategy";
import { Logger } from "@utils";
import Mobile from "@mobile";
import { setDialogNotifications } from "@redux/slices/uiSlice";
import { store } from "@redux/store";

export default class DestinationCompleteStrategy extends DynamicGeofenceStrategy {
  public activate(): void {
    this.triggerDestinationComplete();
    this.areaTriggered = true;
  }

  public deactivate(): void {
    return;
  }

  private triggerDestinationComplete(): void {
    store.dispatch(setDialogNotifications({ routeCompletedAlert: true }));

    window.setTimeout(() => {
      store.dispatch(setDialogNotifications({ routeCompletedAlert: false }));
      this.emitDestinationReachedEvent();
    }, 5000);
  }

  private emitDestinationReachedEvent() {
    Logger.log("Emitting destination reached to mobile");
    if (Mobile.isAndroidWebview()) {
      Mobile.getGlobalAndroid()!.destinationReached();
    } else if (Mobile.isIOSLivingMapWebView()) {
      Mobile.sendMessageToIOS({
        eventName: "destinationReached",
      });
    }
  }
}

import Spinner from "@components/Spinner/Spinner";
import styles from "./DeterminingPositionModal.module.css";

const DeterminingPositionModal: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Determining user location</h2>
      <Spinner type="ClipLoader" size={40} />
    </div>
  );
};

export default DeterminingPositionModal;
